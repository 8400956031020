import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import Airtable from "airtable";
import axios from "axios";
import { Notify, Dialog } from "vant";
import pptxgen from "pptxgenjs";
import router from "./router";
import * as msal from "@azure/msal-browser";
import Cookies from "js-cookie";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import app from "../package.json";
import moment from "moment";
import CryptoJS, { EvpKDF } from "crypto-js";
import { arrayMoveMutable } from "array-move";
import { filter } from "lodash";
import md5 from "crypto-js/md5";
let airtable_a = localStorage.getItem("a") || "wrong";
let airtable_p = localStorage.getItem("b") || "wrong";
let base = new Airtable({ apiKey:"patn4sDJaeq85aXie.d2b205090ef7ba3bd7c639b01c5944b1cbf3fc5bf12f913baa35288725c49df7"}).base("appes8o9Z4BEjtDHA");
import slugify from "slugify";
const html2pptxgenjs = require("./assets/html2pptxgenjs.cjs");
import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
Vue.use(Vuex);

const getMetaImg = async (url) => {
	const img = new Image();
	img.src = url;
	await img.decode();
	return img;
};
const vuexLocal = new VuexPersistence({
	key: "laruche" + app.version,
	storage: window.localStorage,
	modules: ["connected", "user", "users"],
});

const groupBy = function (xs, key) {
	return xs.reduce(function (rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};

const encodeXml = (string) => {
	return string.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&apos;");
};
const isInViewport = (elem) => {
	var bounding = elem.getBoundingClientRect();
	return (
		bounding.top >= 0 &&
		bounding.left >= 0 &&
		bounding.top <= (window.innerHeight || document.documentElement.clientHeight) &&
		bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
};

// Initialisation de la connexion AZURE AD
const msalConfig = {
	auth: {
		clientId: process.env.VUE_APP_MSAL_ID,
		authority: process.env.VUE_APP_MSAL_AUTHORITY,
		clientSecret: process.env.VUE_APP_MSAL_SECRET,
	},
};
const msalInstance = new msal.PublicClientApplication(msalConfig);

// Converti une URL Youtube en embed YouTube
const getYouTubeId = (url) => {
	const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
	const match = url.match(regExp);
	return match && match[2].length === 11 ? match[2] : null;
};
export default new Vuex.Store({
	state: {
		active_filtre_box_ajout: "",
		notices: {
			prestations: false,
			filieres: false,
			procedures: false,
			digitalisation: false,
			formation: false,
			toolbox: false,
			materiel: false,
			qualite: false,
			groupes: false,
			globales: false,
			squelettes: false,
			modeles: false,
			geographie: false,
			classement: false,
		},
		retrieve_modele_encours: false,
		airtable_a: "wrong",
		airtable_p: "wrong",
		presse_papier: {},
		quillfocus: false,
		lastlogin: null,
		properties: {
			zoom: 1,
		},
		show_composant_context: {
			state: false,
			x: 0,
			y: 0,
		},
		show_slide_context: {
			state: false,
			x: 0,
			y: 0,
		},
		show_overlay_gallery: false,
		users: [],
		historique: [],
		generation_en_cours: false,
		show_overlay_share: false,
		show_overlay_create: false,
		show_overlay_slide_create: false,
		show_overlay_save: false,
		connected: false,
		prestations: [],
		filieres: [],
		procedures: [],
		toolbox: [],
		materiel: [],
		digitalisation: [],
		formation: [],
		qualite: [],
		geographie: [],
		squelettes: [],
		groupe_mauffrey: [],
		user: {},
		slide_selected: false,
		current_diapositive: null,
		current_composant: {
			index: null,
			type: "",
		},
		presentations: [],
		globales: [],
		zones: [],
		presentations_archives: [],
		presentation_ready: false,
		current_presentation: {},
		modeles: [],
		app_loading: true,
		slideModelePreview: "",
		show_overlay_choose_slide: false,
		show_overlay_choose_image: false,
		positionForNewSlide: 0,
		loginFailMessage: "",
		current_tab: "presentation",
		medias_galerie: [],
		trying_to_connect: false,
		session_id: "",
		appLoadingText: "L'application travaille un peu pour vous soulager. <br />Patientez quelques instants...",
		tampon: {},
		current_squelette: null,
		squelettes_ready: false,
		savemodeleeditor: false,
		success: false,
	},
	mutations: {
		updateFiltreBoxAjout(state, payload) {
			state.active_filtre_box_ajout = payload;
		},
		updateCodeCourt(state, payload) {
			let gindex = state.globales.findIndex((el) => el.id === payload.id);
			if (gindex >= 0) {
				state.globales[gindex].fields.code_court = payload.event.target.innerText;
				base("globales").update(
					[
						{
							id: payload.id,
							fields: {
								code_court: payload.event.target.innerText,
							},
						},
					],
					function (err, records) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
			}
		},
		updateDefaultValue(state, payload) {
			let gindex = state.globales.findIndex((el) => el.id === payload.id);
			if (gindex >= 0) {
				state.globales[gindex].fields.valeur_retournee = payload.event.target.innerText;
				base("globales").update(
					[
						{
							id: payload.id,
							fields: {
								valeur_retournee: payload.event.target.innerText,
							},
						},
					],
					function (err, records) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
			}
		},
		updateCodeDescription(state, payload) {
			let gindex = state.globales.findIndex((el) => el.id === payload.id);
			if (gindex >= 0) {
				state.globales[gindex].fields.description = payload.event.target.innerText;
				base("globales").update(
					[
						{
							id: payload.id,
							fields: {
								description: payload.event.target.innerText,
							},
						},
					],
					function (err, records) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
			}
		},
		updateConfiguration(state, payload) {
			let zone_index = state.zones.findIndex((el) => el.id === payload.zone);
			if (zone_index >= 0) {
				if (payload.event.target.innerText == "") {
					if (state.zones[zone_index].fields.configuration[payload.globale]) {
						delete state.zones[zone_index].fields.configuration[payload.globale];
					}
				} else {
					state.zones[zone_index].fields.configuration[payload.globale] = payload.event.target.innerText;
				}
				base("zones").update(
					[
						{
							id: payload.zone,
							fields: {
								configuration: JSON.stringify(state.zones[zone_index].fields.configuration),
							},
						},
					],
					function (err, records) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
			}
		},
		updateZoneImage(state, payload) {
			let found = state.zones.findIndex((el) => el.id === payload.zone);
			if (found >= 0) {
				if (!state.zones[found].fields.configuration[payload.globale]) {
					state.zones[found].fields.configuration[payload.globale] = {};
				}
				state.zones[found].fields.configuration[payload.globale].image = payload.url;
				state.zones[found].fields.configuration[payload.globale].width = payload.width;
				state.zones[found].fields.configuration[payload.globale].height = payload.height;
			}
			base("zones").update(
				[
					{
						id: payload.zone,
						fields: {
							configuration: JSON.stringify(state.zones[found].fields.configuration),
						},
					},
				],
				function (err, records) {
					if (err) {
						console.log(err);
						return;
					}
				}
			);
		},
		deleteCurrentImageGlobale(state, payload) {
			let found = state.zones.findIndex((el) => el.id === payload.zone);
			if (found >= 0) {
				state.zones[found].fields.configuration[payload.globale].image = null;
				state.zones[found].fields.configuration[payload.globale].width = null;
				state.zones[found].fields.configuration[payload.globale].height = null;
			}
			base("zones").update(
				[
					{
						id: payload.zone,
						fields: {
							configuration: JSON.stringify(state.zones[found].fields.configuration),
						},
					},
				],
				function (err, records) {
					if (err) {
						console.log(err);
						return;
					}
				}
			);
		},
		updateGlobaleImage(state, payload) {
			let found = state.globales.findIndex((el) => el.id === payload.id);
			if (found >= 0) {
				state.globales[found].fields.image = payload.url;
				state.globales[found].fields.width = payload.width;
				state.globales[found].fields.height = payload.height;
			}
			base("globales").update(
				[
					{
						id: payload.id,
						fields: {
							image: payload.url,
							width: payload.width,
							height: payload.height,
						},
					},
				],
				function (err, records) {
					if (err) {
						console.log(err);
						return;
					}
				}
			);
		},
		deleteZone(state, payload) {
			Dialog.confirm({
				title: "Suppression d'une filiale",
				message: "Voulez-vous vraiment supprimer cette filiale ?",
				confirmButtonText: "Oui",
				cancelButtonText: "Annuler",
			})
				.then(() => {
					let found = state.zones.findIndex((el) => el.id === payload);
					state.zones.splice(found, 1);
					base("zones").destroy([payload], (err, deletedRecords) => {
						if (err) {
							Notify({
								type: "error",
								message: "La suppression de la filiale a échoué.",
								color: "#e30613",
								background: "#fff3f3",
								duration: 4000,
							});
							return;
						}
						Notify({
							type: "success",
							message: "La filiale a bien été supprimée.",
							color: "#38cb89",
							background: "#e5f7ef",
							duration: 4000,
						});
					});
				})
				.catch(() => {});
		},
		deleteGlobale(state, payload) {
			Dialog.confirm({
				title: "Suppression d'une donnée globale",
				message: "Voulez-vous vraiment supprimer cette donnée globale ?",
				confirmButtonText: "Oui",
				cancelButtonText: "Annuler",
			})
				.then(() => {
					let found = state.globales.findIndex((el) => el.id === payload);
					state.globales.splice(found, 1);
					base("globales").destroy([payload], (err, deletedRecords) => {
						if (err) {
							Notify({
								type: "error",
								message: "La suppression de la donnée globale a échoué.",
								color: "#e30613",
								background: "#fff3f3",
								duration: 4000,
							});
							return;
						}
						Notify({
							type: "success",
							message: "La donnée globale a bien été supprimée.",
							color: "#38cb89",
							background: "#e5f7ef",
							duration: 4000,
						});
					});
				})
				.catch(() => {});
		},
		resetCurrentComposant(state) {
			state.current_composant = {
				index: null,
				type: "",
			};
		},
		setSquelette(state, payload) {
			state.current_squelette = payload;
		},
		removeFromComposition(state, payload) {
			let index = state.current_squelette.previews_elements.findIndex((el) => el.id === payload);
			state.current_squelette.previews_elements.splice(index, 1);
		},
		reorganized_slides(state, payload) {
			arrayMoveMutable(state.current_presentation.fields.slides, payload.oldIndex, payload.newIndex);
			state.current_presentation.fields.slides.forEach((el, index) => {
				el.order = index;
			});
		},
		reorganized_composition(state, payload) {
			arrayMoveMutable(state.current_squelette.preview_elements, payload.oldIndex, payload.newIndex);
		},
		moveSlide(state, event) {
			//console.log(state.current_diapositive);
			let new_position = prompt("Indiquez vers quel emplacement vous souhaitez déplacer la slide :");
			if (parseInt(new_position) >= 1 && parseInt(new_position) <= state.current_presentation.fields.slides.length) {
				arrayMoveMutable(state.current_presentation.fields.slides, state.current_diapositive, parseInt(new_position) - 1);
				state.current_presentation.fields.slides.forEach((el, index) => {
					el.order = index;
				});
			}
		},
		setZoom(state, payload) {
			state.properties.zoom += payload;
		},
		addZone(state, payload) {
			state.zones.push(payload);
		},
		zoomplus(state) {
			state.properties.zoom += 0.1;
		},
		zoommoins(state) {
			state.properties.zoom -= 0.1;
		},
		focusQuill(state) {
			state.quillfocus = true;
		},
		blurQuill(state) {
			state.quillfocus = false;
		},
		copyStyle(state) {
			if (state.presse_papier.type) {
				if (state.presse_papier.type === "text") {
					state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].color = state.presse_papier.color;
					state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].fontSize = state.presse_papier.fontSize;
					state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].lineSpacing =
						state.presse_papier.lineSpacing;
					state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].margin = state.presse_papier.margin;
					state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].rotate = state.presse_papier.rotate;
					state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].valign = state.presse_papier.valign;
				} else if (state.presse_papier.type === "shape") {
					state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].line = state.presse_papier.line;
					state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].fill = state.presse_papier.fill;
					state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].rectRadius =
						state.presse_papier.rectRadius;
					state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].rotate = state.presse_papier.rotate;
					state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].shadow = state.presse_papier.shadow;
				} else if (state.presse_papier.type === "image") {
					state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].sizing = state.presse_papier.sizing;
					state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].rounding = state.presse_papier.rounding;
				}
			} else {
				Notify({
					type: "error",
					message: "Copiez d'abord un composant avant de dupliquer son style",
					color: "#e30613",
					background: "#fff3f3",
					duration: 4000,
				});
			}
		},
		updatePresentationLogo(state, payload) {
			state.current_presentation.fields.logo_client = [{ url: payload.lien }];
			base("presentations").update([
				{
					id: state.current_presentation.id,
					fields: {
						logo_client: state.current_presentation.fields.logo_client,
					},
				},
			]);
		},
		addPressPapier(state, payload) {
			state.presse_papier = {};
			state.presse_papier = payload;
		},
		showAppLoading(state, payload) {
			state.app_loading = false;
			state.appLoadingText = payload;
		},
		hideAppLoading(state) {
			state.app_loading = false;
			state.appLoadingText = "L'application travaille un peu pour vous soulager. <br />Patientez quelques instants...";
		},
		updateSlideTextColor(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].color = payload;
			});
		},
		updateSlideFillColor(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].path = null;
				state.current_presentation.fields.slides[state.current_diapositive].fill = payload;
			});
		},
		updateBarreColor(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].barre_color = payload;
			});
		},
		updateShapeColor(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].fill.color = payload;
			});
		},
		updateShapeBorderColor(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].line.color = payload;
			});
		},
		updateTableColor(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].fill.color = payload;
			});
		},
		updateTable(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].content = payload;
			});
		},
		updateComposantBorderColor(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].borderColor = payload;
			});
		},
		updateComposantColor(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].color = payload;
			});
		},
		updateElementLargeur(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].w = payload;
			});
		},
		updateElementHauteur(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].h = payload;
			});
		},
		updateComposantBgColor(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].fill = payload;
			});
		},
		deleteModele(state, payload) {
			Dialog.confirm({
				title: "Suppression d'un modèle",
				message: "Voulez-vous vraiment supprimer ce modèle ?",
				confirmButtonText: "Oui",
				cancelButtonText: "Annuler",
			})
				.then(() => {
					let found = state.modeles.findIndex((el) => el.id === payload);
					if (found) {
						state.modeles.splice(found, 1);
						base("modeles").destroy([payload], (err, deletedRecords) => {
							if (err) {
								Notify({
									type: "error",
									message: "Le modèle n'a pas pu être supprimé",
									color: "#e30613",
									background: "#fff3f3",
									duration: 4000,
								});
								return;
							}
							Notify({
								type: "success",
								message: "Le modèle a bien été supprimé",
								color: "#38cb89",
								background: "#e5f7ef",
								duration: 4000,
							});
							window.location.reload();
						});
					}
				})
				.catch(() => {});
		},

		deleteSquelette(state, payload) {
			Dialog.confirm({
				title: "Suppression d'un squelette",
				message: "Voulez-vous vraiment supprimer ce squelette ?",
				confirmButtonText: "Oui",
				cancelButtonText: "Annuler",
			})
				.then(() => {
					let found = state.modeles.findIndex((el) => el.id === payload);
					if (found) {
						state.modeles.splice(found, 1);
						base("squelettes").destroy([payload], (err, deletedRecords) => {
							if (err) {
								Notify({
									type: "error",
									message: "Le squelette n'a pas pu être supprimé",
									color: "#e30613",
									background: "#fff3f3",
									duration: 4000,
								});
								return;
							}
							Notify({
								type: "success",
								message: "Le squelette a bien été supprimé",
								color: "#38cb89",
								background: "#e5f7ef",
								duration: 4000,
							});
							window.location.reload();
						});
					}
				})
				.catch(() => {});
		},
		changeModeleName(state, payload) {
			let found = state.modeles.find((el) => el.id === payload);
			if (found) {
				base("modeles").update(
					[
						{
							id: payload,
							fields: {
								nom: found.fields.nom,
							},
						},
					],
					(err, records) => {
						if (err) {
							Notify({
								type: "error",
								message: err,
								color: "#e30613",
								background: "#fff3f3",
								duration: 4000,
							});
							return;
						}
						Notify({
							type: "success",
							message: "Félicitations! Votre modèle a bien été modifié",
							color: "#38cb89",
							background: "#e5f7ef",
							duration: 4000,
						});
					}
				);
			}
		},
		addGlobale(state, payload) {
			console.log("couco", payload);
			payload.fields.valeurs = JSON.parse(payload.fields.valeurs);
			state.globales.push(payload);
		},
		deletePrestation(state, payload) {
			let found = state.prestations.findIndex((el) => el.id === payload);
			state.prestations.splice(found, 1);
			base("prestations").destroy([payload], (err, deletedRecords) => {
				if (err) {
					Notify({
						type: "error",
						message: "La suppression de la prestation a échouée.",
						color: "#e30613",
						background: "#fff3f3",
						duration: 4000,
					});
					return;
				}
				Notify({
					type: "success",
					message: "La prestation a bien été supprimée",
					color: "#38cb89",
					background: "#e5f7ef",
					duration: 4000,
				});
			});
		},
		deleteFiliere(state, payload) {
			let found = state.filieres.findIndex((el) => el.id === payload);
			state.filieres.splice(found, 1);
			base("filieres").destroy([payload], (err, deletedRecords) => {
				if (err) {
					Notify({
						type: "error",
						message: "La suppression de la filière a échoué.",
						color: "#e30613",
						background: "#fff3f3",
						duration: 4000,
					});
					return;
				}
				Notify({
					type: "success",
					message: "La filière a bien été supprimée.",
					color: "#38cb89",
					background: "#e5f7ef",
					duration: 4000,
				});
			});
		},

		changePrestationName(state, payload) {
			let found = state.prestations.find((el) => el.id === payload);
			base("prestations").update(
				[
					{
						id: payload,
						fields: {
							nom: found.fields.nom,
						},
					},
				],
				(err, records) => {
					if (err) {
						Notify({
							type: "error",
							message: "La modification de la prestation a échouée.",
							color: "#e30613",
							background: "#fff3f3",
							duration: 4000,
						});
						return;
					}
					Notify({
						type: "success",
						message: "Le nom de la prestation a bien été modifié",
						color: "#38cb89",
						background: "#e5f7ef",
						duration: 4000,
					});
				}
			);
		},
		changeCatName(state, payload) {
			let found = state[payload.type].find((el) => el.id === payload.id);
			base(payload.type).update(
				[
					{
						id: payload.id,
						fields: {
							nom: found.fields.nom,
						},
					},
				],
				(err, records) => {
					if (err) {
						Notify({
							type: "error",
							message: "La modification de la prestation a échouée.",
							color: "#e30613",
							background: "#fff3f3",
							duration: 4000,
						});
						return;
					}
					Notify({
						type: "success",
						message: "Le nom de la prestation a bien été modifié",
						color: "#38cb89",
						background: "#e5f7ef",
						duration: 4000,
					});
				}
			);
		},
		deleteCat(state, payload) {
			let found = state[payload.type].findIndex((el) => el.id === payload.id);
			state[payload.type].splice(found, 1);
			base(payload.type).destroy([payload.id], (err, deletedRecords) => {
				if (err) {
					Notify({
						type: "error",
						message: "La suppression de la filière a échoué.",
						color: "#e30613",
						background: "#fff3f3",
						duration: 4000,
					});
					return;
				}
				Notify({
					type: "success",
					message: "La filière a bien été supprimée.",
					color: "#38cb89",
					background: "#e5f7ef",
					duration: 4000,
				});
			});
		},
		addNewCat(state, payload) {
			base(payload.type).create(
				[
					{
						fields: {
							nom: payload.nom,
						},
					},
				],
				(err, records) => {
					if (err) {
						Notify({
							type: "error",
							message: "La filière n'a pas pu être ajoutée.",
							color: "#e30613",
							background: "#fff3f3",
							duration: 4000,
						});
						return;
					}
					records.forEach(function (record) {
						state[payload.type].push(record);
					});
				}
			);
		},
		changeFiliereName(state, payload) {
			let found = state.filieres.find((el) => el.id === payload);
			base("filieres").update(
				[
					{
						id: payload,
						fields: {
							nom: found.fields.nom,
						},
					},
				],
				(err, records) => {
					if (err) {
						Notify({
							type: "error",
							message: "La modification de la filière a échoué.",
							color: "#e30613",
							background: "#fff3f3",
							duration: 4000,
						});
						return;
					}
					Notify({
						type: "success",
						message: "Le nom de la filière a bien été modifié.",
						color: "#38cb89",
						background: "#e5f7ef",
						duration: 4000,
					});
				}
			);
		},
		addNewPrestation(state, payload) {
			base("prestations").create(
				[
					{
						fields: {
							nom: payload.nom,
						},
					},
				],
				(err, records) => {
					if (err) {
						Notify({
							type: "error",
							message: "La prestation n'a pas pu être ajoutée",
							color: "#e30613",
							background: "#fff3f3",
							duration: 4000,
						});
						return;
					}
					records.forEach(function (record) {
						state.prestations.push(record);
					});
				}
			);
		},
		addNewFiliere(state, payload) {
			base("filieres").create(
				[
					{
						fields: {
							nom: payload.nom,
						},
					},
				],
				(err, records) => {
					if (err) {
						Notify({
							type: "error",
							message: "La filière n'a pas pu être ajoutée.",
							color: "#e30613",
							background: "#fff3f3",
							duration: 4000,
						});
						return;
					}
					records.forEach(function (record) {
						state.filieres.push(record);
					});
				}
			);
		},
		changeRole(state, payload) {
			const index = state.users.findIndex((user) => user.id === payload.id);
			if (state.users[index].fields.role != payload.role) {
				state.users[index].fields.role = payload.role;
				base("utilisateurs").update(
					[
						{
							id: payload.id,
							fields: {
								role: payload.role,
							},
						},
					],
					(err, records) => {
						if (err) {
							Notify({
								type: "error",
								message: "Le statut de l'utilisateur n'a pas pu être mis à jour.",
								color: "#e30613",
								background: "#fff3f3",
								duration: 4000,
							});
							return;
						}
						Notify({
							type: "success",
							message: "Félicitations! Le statut de l'utilisateur a bien été mis à jour",
							color: "#38cb89",
							background: "#e5f7ef",
							duration: 4000,
						});
					}
				);
			}
		},
		setPositionForNewSlide(state, payload) {
			state.positionForNewSlide = payload;
		},
		showChooseSlide(state, payload) {
			let slide_selected = document.querySelector(".slide.selected");
			if (payload) {
				state.positionForNewSlide = payload;
			} else if (slide_selected) {
				state.positionForNewSlide = parseInt(slide_selected.style.order) + 1;
			} else {
				state.positionForNewSlide = state.current_presentation.fields.slides.length;
			}
			state.show_overlay_choose_slide = !state.show_overlay_choose_slide;
		},
		showChooseImage(state, payload) {
			state.show_overlay_choose_image = !state.show_overlay_choose_image;
		},
		addPresentation(state, payload) {
			state.presentations.push(payload);
		},
		addPresentationArchives(state, payload) {
			state.presentations_archives.push(payload);
		},
		addModele(state, payload) {
			state.modeles.push(payload);
		},
		addSquelette(state, payload) {
			state.squelettes.push(payload);
		},
		addPrestation(state, payload) {
			state.prestations.push(payload);
		},
		addProcedure(state, payload) {
			state.procedures.push(payload);
		},
		addToolbox(state, payload) {
			state.toolbox.push(payload);
		},
		addMateriel(state, payload) {
			state.materiel.push(payload);
		},
		addDigitalisation(state, payload) {
			state.digitalisation.push(payload);
		},
		addFormation(state, payload) {
			state.formation.push(payload);
		},
		addQualite(state, payload) {
			state.qualite.push(payload);
		},
		addGeographie(state, payload) {
			state.geographie.push(payload);
		},
		addGroupe(state, payload) {
			state.groupe_mauffrey.push(payload);
		},
		addFiliere(state, payload) {
			state.filieres.push(payload);
		},
		showCreate(state) {
			state.show_overlay_create = !state.show_overlay_create;
		},
		showSlideCreate(state) {
			state.show_overlay_slide_create = !state.show_overlay_slide_create;
		},
		showSaveModele(state) {
			state.show_overlay_save = !state.show_overlay_save;
		},
		select_slide(state, payload) {
			state.slide_selected = payload;
		},
		connect(state) {
			state.connected = true;
		},
		logout(state) {
			state.connected = false;
			state.user = {};
			state.users = [];
			router.push({ name: "Connexion" });
			localStorage.removeItem("vuex");
			window.sessionStorage.clear();
		},
		updateContent(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[payload.slide].composants[payload.composant].content = payload.newtext;
			});
		},
		updateSize(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[payload.slide].composants[payload.composant].x = Math.round(payload.sizes.pLeft);
				state.current_presentation.fields.slides[payload.slide].composants[payload.composant].y = Math.round(payload.sizes.pTop);
				state.current_presentation.fields.slides[payload.slide].composants[payload.composant].w = Math.round(payload.sizes.width);
				state.current_presentation.fields.slides[payload.slide].composants[payload.composant].h = Math.round(payload.sizes.height);
			});
		},
		updateCrop(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[payload.slide].composants[payload.composant].w = payload.value;
			});
		},
		updateSizeImage(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[payload.slide].composants[payload.composant].x = Math.round(payload.sizes.pLeft);
				state.current_presentation.fields.slides[payload.slide].composants[payload.composant].y = Math.round(payload.sizes.pTop);
				state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.w = Math.round(payload.sizes.width);
				state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.h = Math.round(payload.sizes.height);
			});
		},
		updatePosition(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				let selected_items = [...document.querySelectorAll(".pptx-element.selected")];
				if (selected_items.length > 1) {
					selected_items.sort((a, b) => (parseInt(a.dataset.composant) < parseInt(b.dataset.composant) ? 1 : -1));
					selected_items.forEach((el) => {
						state.current_presentation.fields.slides[el.dataset.slide].composants[el.dataset.composant].x += Math.round(payload.sizes.differencex);
						state.current_presentation.fields.slides[el.dataset.slide].composants[el.dataset.composant].y += Math.round(payload.sizes.differencey);
					});
				} else {
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].x = Math.round(payload.sizes.x);
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].y = Math.round(payload.sizes.y);
				}
			});
		},
		increment(state) {
			state.count++;
		},
		addSlide(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides.push(payload);
				state.current_presentation.fields.slides.sort((a, b) => {
					return a.order - b.order;
				});
			});
		},
		selectComposant(state, payload) {
			let composant_deja_selectionnees = document.querySelectorAll(".pptx-element.selected");
			if (composant_deja_selectionnees && composant_deja_selectionnees.length > 1) {
				composant_deja_selectionnees.forEach((el) => {
					el.classList.remove("selected");
				});
			}
			state.current_diapositive = payload.slide;
			state.current_composant.index = payload.composant;
			state.current_composant.type = payload.type;
			state.current_tab = "composant";
		},
		addComposant(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				let taille = state.current_presentation.fields.slides[state.current_diapositive].composants.length;
				state.current_presentation.fields.slides[state.current_diapositive].composants.push(payload);
				this.commit("selectComposant", {
					composant: taille,
					slide: state.current_diapositive,
					type: payload.type,
				})
			});
		},
		pasteComposant(state, payload) {
			if (state.presse_papier.type) {
				this.dispatch("addToHistorique").then(() => {
					let taille = state.current_presentation.fields.slides[state.current_diapositive].composants.length;
					state.current_presentation.fields.slides[state.current_diapositive].composants.push(state.presse_papier);
				});
			}
		},
		updateElementAlignement(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].align = payload;
			});
		},
		updateElementVerticalAlignement(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].valign = payload;
			});
		},
		updateElementWeight(state) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].bold =
					!state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].bold;
			});
		},
		updateElementStyle(state) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].italic =
					!state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].italic;
			});
		},
		updateElementCase(state) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].isUpperCase =
					!state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].isUpperCase;
			});
		},
		updateElementListe(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].puces = payload;
			});
		},
		updateElementImage(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].is_dynamic = false;
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].dynamic_code = "";
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].data = null;
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].path = payload.lien;
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].w = Math.round(payload.w);
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].h = Math.round(payload.h);
				state.show_overlay_choose_image = false;
			});
		},
		changeSlideOrder(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				arrayMoveMutable(state.current_presentation.fields.slides, payload.slide, payload.position);
				state.current_presentation.fields.slides.forEach((el, index) => {
					el.order = index;
				});
			});
		},
		removeElement(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				if (payload || payload === 0) {
					state.current_presentation.fields.slides[state.current_diapositive].composants.splice(payload, 1);
					state.current_composant.index = null;
					state.current_composant.type = "";
				} else {
					if (state.current_diapositive != null && state.current_composant.index != null) {
						state.current_presentation.fields.slides[state.current_diapositive].composants.splice(state.current_composant.index, 1);
						state.current_composant.index = null;
						state.current_composant.type = "";
					} else {
						Dialog.alert({
							title: "Ooooops !",
							message: "Vous devez déjà sélectionner un composant avant de le supprimer",
							confirmButtonText: "OK",
						});
					}
				}
			});
		},
		removeElementSlide(state) {
			this.dispatch("addToHistorique").then(() => {
				if (state.current_diapositive != null) {
					state.current_presentation.fields.slides.splice(state.current_diapositive, 1);
					for (var i = state.current_diapositive; i < state.current_presentation.fields.slides.length; i++) {
						state.current_presentation.fields.slides[i].order -= 1;
					}
					state.current_diapositive = null;
					state.current_composant.index = null;
					state.current_composant.type = "";
				} else {
					Dialog.alert({
						title: "Ooooops !",
						message: "Vous devez déjà sélectionner une diapositive avant de la supprimer",
						confirmButtonText: "OK",
					});
				}
			});
		},
		setCurrentPresentation(state, payload) {
			state.current_presentation = {};
			state.current_presentation = payload;
			setTimeout(() => {
				state.presentation_ready = true;
			}, 3000);
		},
		updateStyle(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].bold = payload.bold;
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].fontSize = payload.fontSize;
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].color = payload.color;
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].lineSpacing = payload.lineSpacing;
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].italic = payload.italic;
			});
		},
		upComposant(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				if (payload) {
					arrayMoveMutable(state.current_presentation.fields.slides[state.current_diapositive].composants, payload, payload + 1);
					state.current_composant.index = payload + 1;
				} else {
					arrayMoveMutable(
						state.current_presentation.fields.slides[state.current_diapositive].composants,
						state.current_composant.index,
						state.current_composant.index + 1
					);
					state.show_composant_context.state = false;

					setTimeout(() => {
						state.current_composant.index = state.current_composant.index + 1;
					}, 10);
				}
			});
		},
		upComposantForce(state, payload) {
			if (state.current_presentation && state.current_diapositive >= 0) {
				this.dispatch("addToHistorique").then(() => {
					arrayMoveMutable(
						state.current_presentation.fields.slides[state.current_diapositive].composants,
						state.current_composant.index,
						state.current_presentation.fields.slides[state.current_diapositive].composants.length - 1
					);
					setTimeout(() => {
						state.current_composant.index = state.current_presentation.fields.slides[state.current_diapositive].composants.length - 1;
					}, 10);

					state.show_composant_context.state = false;
				});
			}
		},
		downComposant(state, payload) {
			this.dispatch("addToHistorique").then(() => {
				if (payload) {
					arrayMoveMutable(state.current_presentation.fields.slides[state.current_diapositive].composants, payload, payload - 1);
					state.current_composant.index = payload - 1;
				} else {
					arrayMoveMutable(
						state.current_presentation.fields.slides[state.current_diapositive].composants,
						state.current_composant.index,
						state.current_composant.index - 1
					);
					state.show_composant_context.state = false;
					setTimeout(() => {
						state.current_composant.index = state.current_composant.index - 1;
					}, 10);
				}
			});
		},
		downComposantForce(state, payload) {
			if (state.current_presentation && state.current_diapositive >= 0) {
				this.dispatch("addToHistorique").then(() => {
					arrayMoveMutable(state.current_presentation.fields.slides[state.current_diapositive].composants, state.current_composant.index, 0);
					state.show_composant_context.state = false;
					setTimeout(() => {
						state.current_composant.index = 0;
					}, 10);
				});
			}
		},
		showLoadingApp(state) {
			state.app_loading = !state.app_loading;
		},
		clearSelectedItem(state, payload) {
			if (payload.target !== payload.currentTarget) return;
			state.current_composant.index = null;
			state.current_composant.type = "";
			state.current_tab = "diapositive";
			state.current_diapositive = null;
			
			//Je déselectionne les autres selected
			let selected_items = document.querySelectorAll(".pptx-element.selected");
			if (selected_items) {
				selected_items.forEach((el) => {
					el.classList.remove("selected");
				});
			}
		},
		setUser(state, payload) {
			state.user = payload;
			state.connected = true;
			base("utilisateurs").update([
				{
					id: payload.id,
					fields: {
						derniere_connexion: new moment().format("YYYY-MM-DD"),
					},
				},
			]);
		},
		addUser(state, payload) {
			state.users.push(payload);
		},
		createGlobale(state, payload) {
			base("globales").create(
				[
					{
						fields: payload,
					},
				],
				(err, records) => {
					if (err) {
						Notify({
							type: "error",
							message: "Votre donnée globale n'a pas été sauvegardée.",
							color: "#e30613",
							background: "#fff3f3",
							duration: 4000,
						});
						return;
					}
					records.forEach((record) => {
						if (record.fields.type === "image" && record.fields.image_json) {
							fetch(record.fields.image_json[0].url)
								.then((res) => res.json())
								.then((json) => {
									record.fields.image_json_code = json.preview;
									state.globales.push(record);
								});
						} else {
							state.globales.push(record);
						}
					});
				}
			);
		},
		updateGlobale(state, payload) {
			Dialog.confirm({
				title: "Confirmation requise",
				message: "Confirmez-vous la modification de la donnée globale ?",
				cancelButtonText: "Annuler",
				confirmButtonText: "Oui",
			}).then(() => {
				let found = state.globales.find((el) => el.id === payload.id);
				if (found) {
					base("globales").update(
						[
							{
								id: payload.id,
								fields: found.fields,
							},
						],
						(err, records) => {
							if (err) {
								Notify({
									type: "error",
									message: err,
									color: "#e30613",
									background: "#fff3f3",
									duration: 4000,
								});
								return;
							}
							Notify({
								type: "success",
								message: "Félicitations! Votre donnée globale a bien été modifiée",
								color: "#38cb89",
								background: "#e5f7ef",
								duration: 4000,
							});
						}
					);
				}
			});
		},
		change_current_tab(state, payload) {
			state.current_tab = payload;
		},
		moveUpByOnePixel(state, payload) {
			let selected_items = [...document.querySelectorAll(".pptx-element.selected")];
			if (selected_items) {
				selected_items.sort((a, b) => (parseInt(a.dataset.composant) < parseInt(b.dataset.composant) ? 1 : -1));
				selected_items.forEach((el) => {
					state.current_presentation.fields.slides[el.dataset.slide].composants[el.dataset.composant].y -= 1;
				});
			} else if (this.currentComposant.index != null) {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].y -= 1;
			}
		},
		moveDownByOnePixel(state, payload) {
			let selected_items = [...document.querySelectorAll(".pptx-element.selected")];
			if (selected_items) {
				selected_items.sort((a, b) => (parseInt(a.dataset.composant) < parseInt(b.dataset.composant) ? 1 : -1));
				selected_items.forEach((el) => {
					state.current_presentation.fields.slides[el.dataset.slide].composants[el.dataset.composant].y += 1;
				});
			} else if (this.currentComposant.index != null) {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].y += 1;
			}
		},
		moveLeftByOnePixel(state, payload) {
			let selected_items = [...document.querySelectorAll(".pptx-element.selected")];
			if (selected_items) {
				selected_items.sort((a, b) => (parseInt(a.dataset.composant) < parseInt(b.dataset.composant) ? 1 : -1));
				selected_items.forEach((el) => {
					state.current_presentation.fields.slides[el.dataset.slide].composants[el.dataset.composant].x -= 1;
				});
			} else if (this.currentComposant.index != null) {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].x -= 1;
			}
		},
		moveRightByOnePixel(state, payload) {
			let selected_items = [...document.querySelectorAll(".pptx-element.selected")];
			if (selected_items) {
				selected_items.sort((a, b) => (parseInt(a.dataset.composant) < parseInt(b.dataset.composant) ? 1 : -1));
				selected_items.forEach((el) => {
					state.current_presentation.fields.slides[el.dataset.slide].composants[el.dataset.composant].x += 1;
				});
			} else if (this.currentComposant.index != null) {
				state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].x += 1;
			}
		},
		refreshPresentation(state) {
			state.presentation_ready = false;
			state.current_diapositive = null;
			state.current_composant.index = null;
			state.current_composant.type = "";
			state.current_tab = "presentation";
			state.current_presentation = {};
		},
		showGallery(state) {
			state.show_overlay_gallery = !state.show_overlay_gallery;
		},
		changeImageDynamic(state, payload) {
			let current_image = state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index];
			let found = state.globales.find((el) => el.fields.code_court === payload.dynamic_code);
			if (found) {
				let image = found.fields.image;
				if (state.current_presentation.fields.filiale) {
					if (state.current_presentation.fields.filiale[0]) {
						let zone = state.zones.find((el) => el.id === state.current_presentation.fields.filiale[0]);
						if (zone && found.fields.valeurs) {
							let valeurs = JSON.parse(found.fields.valeurs);
							let jeton_de_valeur = valeurs.filter((item) => item.zone === zone.fields.nom_zone);
							if (jeton_de_valeur) {
								image = jeton_de_valeur[0].valeur;
							}
						}
					}
				}
				current_image.path = image;
				current_image.sizing.type = "cover";
				current_image.dynamic_code = payload.dynamic_code;
				current_image.is_dynamic = payload.is_dynamic;
				getMetaImg(image).then((res) => {
					console.log(res.naturalHeight, res.naturalWidth);
					current_image.h = res.naturalHeight;
					current_image.w = res.naturalWidth;
				});
			}
		},

		refreshAllDynamicImages(state) {
			setTimeout(() => {
				if (state.current_presentation.id) {
					state.current_presentation.fields.slides.forEach((el) => {
						if (el.composants) {
							let composants_images = el.composants.find((item) => item.type === "image" && item.is_dynamic === true);
							if (composants_images) {
								if (typeof composants_images === "object") {
									composants_images = [composants_images];
								}
								composants_images.forEach((item) => {
									let found = state.globales.find((el) => el.fields.code_court === item.dynamic_code);
									if (found) {
										let image = found.fields.image;
										if (state.current_presentation.fields.filiale) {
											if (state.current_presentation.fields.filiale[0]) {
												let zone = state.zones.find((el) => el.id === state.current_presentation.fields.filiale[0]);
												if (zone && found.fields.valeurs) {
													let valeurs = JSON.parse(found.fields.valeurs);
													let jeton_de_valeur = valeurs.filter((item) => item.zone === zone.fields.nom_zone);
													if (jeton_de_valeur && jeton_de_valeur.length > 0) {
														image = jeton_de_valeur[0].valeur;
													}
												}
											}
										}
										item.path = image;
										getMetaImg(item.path).then((res) => {
											console.log(res.naturalHeight, res.naturalWidth);
											item.h = res.naturalHeight;
											item.w = res.naturalWidth;
										});
									}
								});
								Notify({
									type: "success",
									message: "Hoho ! Toutes les images dynamiques viennent d'être mise à jour. Faites une petite sauvegarde quand vous pouvez.",
									color: "#38cb89",
									background: "#e5f7ef",
									duration: 4000,
								});
							}
						}
					});
				}
			}, 8000);
		},
		addNewDataToGraph(state, payload) {
			state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].data[0].labels.push(payload.label);
			state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].data[0].values.push(payload.value);
		},
		deleteDataFromGraph(state, payload) {
			state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].data[0].labels.splice(payload.index, 1);
			state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index].data[0].values.splice(payload.index, 1);
		},
		restoreHistorique(state) {
			if (state.historique.length >= 1) {
				// Jé récupère le dernier élément
				let last_action = JSON.parse(state.historique[state.historique.length - 1]);
				// Je modifie le state avec cet élément
				state.current_presentation = last_action;
				// Je supprime la donnée du tableau
				state.historique.pop();
			}
		},
		updateUser(state, payload) {
			state.user = payload;
		},
		changeMiniature(state, payload) {
			//state.current_presentation.fields.slides[payload.index].preview_image = payload.data;
		},
	},
	actions: {
		deleteMedia({ dispatch, state }, payload) {
			Dialog.confirm({
				title: "Suppression d'une image",
				message: "Voulez-vous vraiment supprimer cette image ?",
				confirmButtonText: "Oui",
				cancelButtonText: "Annuler",
			}).then(() => {
				return new Promise((resolve, reject) => {
					dispatch("getSessionId").then(() => {
						axios({
							url: `${process.env.VUE_APP_MEDIABASE_UPLOAD_API}delete_post`,
							method: "POST",
							params: {
								SESSION_ID: state.session_id,
								post_id: payload,
							},
						})
							.then((res) => {
								let imageIndex = state.medias_galerie.findIndex((el) => el.id === payload);
								state.medias_galerie.splice(imageIndex, 1);
								resolve();
							})
							.catch((err) => {
								reject(err);
							});
					});
				});
			});
		},
		exportPPTModele({ state, commit, dispatch, getters }, payload) {
			state.generation_en_cours = true;
			let modele = state.modeles.find((el) => el.id === payload);
			let presentation = modele.fields;
			let pptx = new pptxgen();

			// Je récupère les globales
			let textes = state.globales.filter((el) => el.fields.type === "texte");
			let zones = state.zones;
			let filiale_choisie = presentation.filiale;
			let globales = [];
			if (filiale_choisie) {
				let zone = state.zones.find((el) => el.id === presentation.filiale[0]);
				let final = [];
				textes.forEach((el) => {
					if (el.fields.valeurs) {
						let valeurs = JSON.parse(el.fields.valeurs);
						let jeton_de_valeur = valeurs.filter((item) => item.zone === zone.fields.nom_zone);
						if (jeton_de_valeur) {
							if (jeton_de_valeur[0].valeur != "" || jeton_de_valeur[0].valeur != "X" || jeton_de_valeur[0].valeur != "➜") {
								el.fields.valeur_retournee = jeton_de_valeur[0].valeur;
							}
						}
					}
					globales.push(el);
				});
			} else {
				globales = textes;
			}

			pptx.layout = "LAYOUT_16x9";
			pptx.author = "Groupe Mauffrey";
			pptx.company = "Groupe Mauffrey";
			pptx.defineSlideMaster({
				title: "MASTER_SLIDE_WHITE",
				background: { fill: "FFFFFF" },
				margin: 0,
				objects: [
					{
						rect: {
							x: 0,
							y: 0,
							w: 0.625,
							h: 5.625,
							fill: {
								color: "e30613",
							},
						},
					},
					{
						text: {
							text: presentation.nom.toUpperCase() || "",
							options: {
								x: -2.5,
								y: 2.5,
								w: 5.625,
								h: 0.625,
								color: "FFFFFF",
								align: "center",
								valign: "middle",
								bold: false,
								fontFace: "Arial",
								fontSize: 8,
								italic: true,
								rotate: 270,
								isTextBox: true,
								margin: 20,
							},
						},
					},
					{
						text: {
							text: presentation.nom_client ? presentation.nom_client.toUpperCase() : "",
							options: {
								x: -2.5,
								y: 2.5,
								w: 5.625,
								h: 0.625,
								color: "FFFFFF",
								align: "right",
								valign: "middle",
								bold: true,
								fontSize: 8,
								fontFace: "Arial",
								italic: true,
								rotate: 270,
								isTextBox: true,
								margin: 20,
							},
						},
					},
				],
				slideNumber: { x: 0, y: 5.2, w: 0.625, color: "FFFFFF", align: "center", fontSize: 10 },
			});
			pptx.defineSlideMaster({
				title: "MASTER_SLIDE_CLEAN",
				background: { fill: "FFFFFF" },
				margin: 0,
				objects: [
					{
						rect: {
							x: 0,
							y: 0,
							w: 0.625,
							h: 5.625,
							fill: {
								color: "FFFFFF",
							},
						},
					},
					{
						text: {
							text: presentation.nom.toUpperCase() || "",
							options: {
								x: -2.5,
								y: 2.5,
								w: 5.625,
								h: 0.625,
								color: "000000",
								align: "center",
								valign: "middle",
								bold: false,
								fontFace: "Arial",
								fontSize: 8,
								italic: true,
								rotate: 270,
								isTextBox: true,
								margin: 20,
							},
						},
					},
					{
						text: {
							text: presentation.nom_client ? presentation.nom_client.toUpperCase() : "",
							options: {
								x: -2.5,
								y: 2.5,
								w: 5.625,
								h: 0.625,
								color: "000000",
								align: "right",
								valign: "middle",
								bold: true,
								fontSize: 8,
								fontFace: "Arial",
								italic: true,
								rotate: 270,
								isTextBox: true,
								margin: 20,
							},
						},
					},
				],
				slideNumber: { x: 0, y: 5.2, w: 0.625, color: "FFFFFF", align: "center", fontSize: 10 },
			});
			pptx.defineSlideMaster({
				title: "MASTER_SLIDE_BLACK",
				background: { fill: "FFFFFF" },
				margin: 0,
				objects: [
					{
						rect: {
							x: 0,
							y: 0,
							w: 0.625,
							h: 5.625,
							fill: {
								color: "000000",
							},
						},
					},
					{
						text: {
							text: presentation.nom.toUpperCase(),
							options: {
								x: -2.5,
								y: 2.5,
								w: 5.625,
								h: 0.625,
								color: "FFFFFF",
								align: "center",
								valign: "middle",
								bold: false,
								fontFace: "Arial",
								fontSize: 8,
								italic: true,
								rotate: 270,
								isTextBox: true,
								margin: 20,
							},
						},
					},
					{
						text: {
							text: presentation.nom_client ? presentation.nom_client.toUpperCase() : "",
							options: {
								x: -2.5,
								y: 2.5,
								w: 5.625,
								h: 0.625,
								color: "FFFFFF",
								align: "right",
								valign: "middle",
								bold: true,
								fontSize: 8,
								fontFace: "Arial",
								italic: true,
								rotate: 270,
								isTextBox: true,
								margin: 20,
							},
						},
					},
				],
				slideNumber: { x: 0, y: 5.2, w: 0.625, color: "FFFFFF", align: "center", fontSize: 10 },
			});
			if (presentation.fichier_de_config) {
				fetch(presentation.fichier_de_config[0].url)
					.then((res) => res.json())
					.then((json) => {
						if (json[0]) {
							presentation.slides = json;
						} else {
							presentation.slides = [json];
						}

						presentation.slides.forEach((slide) => {
							let genSlide;
							let masterName = "MASTER_SLIDE_WHITE";
							console.log(slide.barre_color, "color");
							if (!slide.barre_color) {
								slide.barre_color = "e30613";
							}
							let barre_color = slide.barre_color.replace("#", "");
							if (barre_color === "000000") {
								masterName = "MASTER_SLIDE_BLACK";
							}
							if (barre_color === "FFFFFF") {
								masterName = "MASTER_SLIDE_CLEAN";
							}
							genSlide = pptx.addSlide({ masterName: masterName });
							genSlide.color = slide.color;
							genSlide.background = { fill: slide.fill.replace("#", "") };
							if (slide.composants) {
								slide.composants.forEach((el) => {
									if (el.type === "text") {
										let content_parse = el.content;
										let is_sommaire_component = false;
										let puces = [];

										// Je vérifie si c'est le nom du client
										if (content_parse.includes("[nom_client]")) {
											content_parse = content_parse.replace(/\[nom_client\]/g, presentation.nom_client);
										}
										// Je vérifie les shorcodes
										globales.forEach((globale) => {
											if (content_parse.includes(`[${globale.fields.code_court}]`)) {
												let regex = new RegExp(`\\[${globale.fields.code_court}\\]`, 'g');
												content_parse = content_parse.replace(regex, globale.fields.valeur_retournee);
											}
										});

										let options = {
											x: el.x / 96,
											y: el.y / 96,
											w: el.w / 96,
											h: el.h / 96,
											lineSpacing: el.fontSize * el.lineSpacing,
											color: el.color.replace("#", ""),
											align: "left",
											valign: el.valign,
											inset: 0,
											autofit: false,
											original_linespacing: el.lineSpacing,
											italic: el.italic,
											rotate: el.rotate,
											lang: "fr-FR",
											isTextBox: el.isTextBox,
											margin: el.margin / 2,
											fontSize: el.fontSize,
											fontFace: "Arial",
											underline: {
												style: "none",
											},
										};
										if (el.fill) {
											options.fill = {
												color: el.fill.replace("#", ""),
											};
										}
										if (el.hyperlink != undefined && el.hyperlink.url != "") {
											options.hyperlink = {
												url: encodeXml(el.hyperlink.url),
											};
										}

										if (el.isUpperCase) {
											content_parse = content_parse.toUpperCase();
										}
										if (is_sommaire_component) {
											genSlide.addText(puces, {
												x: el.x / 96,
												y: el.y / 96,
												w: el.w / 96,
												h: el.h / 96,
												valign: el.valign,
												margin: el.margin,
												lang: "fr-FR",
												lineSpacing: el.fontSize * el.lineSpacing,
												autofit: true,
												breakLine: false,
												fontFace: "Arial",
											});
										} else {
											// let other_options = {};
											options.css = `h1 {font-size: 18pt;padding-bottom: 5px;}
										h2 {font-size: 13pt;padding-bottom: 5px;}
										h3 {font-size: 11pt;padding-bottom: 5px;}
										em {font-style: italic;}
										strong {font-weight: bold;}
										u {text-decoration: underline;}
										blockquote {color: #e30613;font-size: 8;font-style: italic;font-weight: bold;text-align: center;}
										.ql-align-center {text-align: center;}
										.ql-align-right {text-align: right;}
										a {color: inherit;}`;
											content_parse = content_parse.replaceAll("&#xFEFF", "");
											content_parse = content_parse.replaceAll('<span class="ql-cursor"></span>', "");
											content_parse = content_parse.replaceAll("<p><br></p>", "<br>");
											content_parse = content_parse.replaceAll('<p class="ql-align-center"><br></p>', "<br>");
											content_parse = content_parse.replaceAll('<p class="ql-align-right"><br></p>', "<br>");
											const items = html2pptxgenjs.htmlToPptxText(content_parse, options);
											genSlide.addText(items, {
												x: options.x,
												y: options.y,
												w: options.w,
												h: options.h,
												lineSpacing: options.lineSpacing,
												valign: options.valign,
												fontSize: options.fontSize,
												fontFace: options.fontFace,
												isTextBox: options.isTextBox,
												autofit: false,
												lang: "fr-FR",
												color: options.original_color,
											});
										}
									} else if (el.type === "liste") {
										let options = {
											x: el.x / 96,
											y: el.y / 96,
											w: el.w / 96,
											h: el.h / 96,
											lineSpacing: el.fontSize * el.lineSpacing,
											valign: el.valign,
											margin: el.margin / 2,
											fontFace: "Arial",
										};
										if (el.fill) {
											options.fill = {
												color: el.fill.replace("#", ""),
											};
										}
										let puces = [];
										el.puces.forEach((item) => {
											puces.push({
												text: "> ",
												options: {
													color: "e30613",
													bold: true,
													bullet: false,
													breakLine: false,
													fontSize: el.fontSize,
													indentLevel: 0,
												},
											});
											puces.push({
												text: `${item.text} \n`,
												options: {
													//align: el.align,
													color: el.color.replace("#", ""),
													breakline: true,
													bold: el.bold,
													italic: el.italic,
													fontSize: el.fontSize,
													indentLevel: 1,
												},
											});
										});
										genSlide.addText(puces, options);
									} else if (el.type === "shape") {
										let forme;
										let options = {};
										if (!el.line.color) {
											el.line.color = "FFFFFF";
										}
										if (!el.line.width) {
											el.line.width = 0;
										}
										if (el.line.width === 0) {
											options = {
												x: el.x / 96,
												y: el.y / 96,
												w: el.w / 96,
												h: el.h / 96,
												rectRadius: el.rectRadius / 96,
												rotate: el.rotate,
												fill: {
													color: el.fill.color.replace("#", ""),
													transparency: el.fill.transparency,
												},
											};
										} else {
											options = {
												x: el.x / 96,
												y: el.y / 96,
												w: el.w / 96,
												h: el.h / 96,
												rectRadius: el.rectRadius / 96,
												rotate: el.rotate,
												line: {
													color: el.line.color.replace("#", ""),
													dashType: el.line.dashType,
													width: parseInt(el.line.width),
												},
												fill: {
													color: el.fill.color.replace("#", ""),
													transparency: el.fill.transparency,
												},
											};
										}
										if (options.rectRadius === 0) {
											forme = pptx.shapes.RECTANGLE;
										} else {
											forme = pptx.shapes.ROUNDED_RECTANGLE;
										}
										if (el.shadow && el.shadow.type != "none") {
											options.shadow = {
												angle: el.shadow.angle,
												blur: el.shadow.blur,
												color: el.shadow.color,
												offset: el.shadow.offset,
												opacity: el.shadow.opacity,
												type: el.shadow.type,
											};
											options.shadow.color = options.shadow.color.replace("#", "");
										}
										genSlide.addShape(forme, options);
									} else if (el.type === "image") {
										let options = {};

										if (el.sizing.type === "crop") {
											// On previent les gros bugs
											options = {
												x: el.x / 96,
												y: el.y / 96,
												w: (el.w * (el.sizing.zoom / 100)) / 96,
												h: (el.h * (el.sizing.zoom / 100)) / 96,
												path: el.path,
												rounding: el.rounding,
												transparency: el.transparency || 0,
												sizing: {
													type: el.sizing.type,
													w: el.sizing.w / 96,
													h: el.sizing.h / 96,
													x: (el.sizing.decalage_x / 96) * -1,
													y: (el.sizing.decalage_y / 96) * -1,
												},
											};
										} else {
											options = {
												x: el.x / 96,
												y: el.y / 96,
												w: el.w / 96,
												h: el.h / 96,
												path: el.path,
												rounding: el.rounding,
												transparency: el.transparency || 0,
												sizing: {
													type: el.sizing.type,
													w: el.sizing.w / 96,
													h: el.sizing.h / 96,
												},
											};
										}
										if (el.hyperlink != undefined && el.hyperlink.url != "") {
											options.hyperlink = { url: encodeXml(el.hyperlink.url) };
										}
										genSlide.addImage(options);
									} else if (el.type === "tableau") {
										let rownumber = el.rows;
										let colnumber = el.cols;
										let temp_bold = false;
										let temp_fill = "FBFCFC";
										let options = {
											x: el.x / 96,
											y: el.y / 96,
											w: el.w / 96,
											h: el.h / 96,
											valign: el.valign,
											fontSize: el.fontSize,
											align: el.align,
											fill: "FBFCFC",
											bold: el.bold,
											italic: el.italic,
											margin: 6,
											border: { pt: 4, color: el.borderColor?.replace("#", "") || 'FFFFFF'},
										};
										
										let duplicate_table = JSON.parse(JSON.stringify(el.content));
										duplicate_table.forEach((row, rindex) => {
											row.forEach((cell, cindex) => {
												if (
													(rindex === 0 && el.first_row_heading) ||
													(rindex === el.rows - 1 && el.last_row_heading) ||
													(cindex === 0 && el.first_colonne_heading) ||
													(cindex === el.cols - 1 && el.last_colonne_heading)
												) {
													temp_bold = true;
												} else {
													temp_bold = false;
												}
												if (cell.text === "" || cell.text === undefined || cell.text === null) {
													temp_fill = "FFFFFF";
												} else {
													
													globales.forEach((globale) => {
														let regex = new RegExp(`\\[${globale.fields.code_court}\\]`, 'g');
														cell.text = cell.text.replace(regex, globale.fields.valeur_retournee);
													});
													temp_fill = "FBFCFC";
												}
												cell.options = {
													fill: { color: temp_fill },
													bold: temp_bold,
												};
											});
										});
										genSlide.addTable(duplicate_table, options);
									} else if (el.type === "graph") {
										let options = {
											x: el.x / 96,
											y: el.y / 96,
											w: el.w / 96,
											h: el.h / 96,
											chartColors: el.chartColors,
											showValue: false,
											showPercent: true,
											dataBorder: { pt: "1", color: "FFFFFF" },
											showLegend: el.showLegend,
											dataLabelColor: "FFFFFF",
											dataLabelFontSize: 12,
											dataLabelFontFace: "Arial",
											legendFontFace: "Arial",
											holeSize: 50,
										};
										if (el.type_chart === "PIE") {
											genSlide.addChart(pptx.charts.DOUGHNUT, el.data, options);
										} else if (el.type_chart === "AREA") {
											genSlide.addChart(pptx.charts.AREA, el.data, options);
										} else if (el.type_chart === "BAR") {
											genSlide.addChart(pptx.charts.BAR, el.data, options);
										} else if (el.type_chart === "LINE") {
											genSlide.addChart(pptx.charts.LINE, el.data, options);
										}
									} else if (el.type === "online") {
										let options = {
											x: el.x / 96,
											y: el.y / 96,
											w: el.w / 96,
											h: el.h / 96,
											link: el.link,
											type: el.type,
										};
										genSlide.addMedia(options);
									}
								});
							}
						});
						pptx.writeFile({ fileName: `${presentation.nom}.pptx`, compression: true }).then((filename) => {
							state.generation_en_cours = false;
							Notify({
								message: `${filename} a correctement été exportée et enregistrée sur votre ordinateur.`,
								background: "#f4f8ff",
								color: "#377dff",
								duration: 4000,
							});
						});
					});
			}
		},
		exportPPTFromModeleEditor({ state, commit, dispatch, getters }) {
			state.generation_en_cours = true;

			let pptx = new pptxgen();
			let presentation = getters.currentPresentation;

			// Je récupère les globales
			let textes = state.globales.filter((el) => el.fields.type === "texte");
			let zones = state.zones;
			let filiale_choisie = presentation.filiale;
			let globales = [];
			if (filiale_choisie) {
				let zone = state.zones.find((el) => el.id === presentation.filiale[0]);
				let final = [];
				textes.forEach((el) => {
					if (el.fields.valeurs) {
						let valeurs = JSON.parse(el.fields.valeurs);
						let jeton_de_valeur = valeurs.filter((item) => item.zone === zone.fields.nom_zone);
						if (jeton_de_valeur) {
							if (jeton_de_valeur[0].valeur != "" || jeton_de_valeur[0].valeur != "X" || jeton_de_valeur[0].valeur != "➜") {
								el.fields.valeur_retournee = jeton_de_valeur[0].valeur;
							}
						}
					}
					globales.push(el);
				});
			} else {
				globales = textes;
			}

			// Mise en page
			pptx.layout = "LAYOUT_16x9";
			pptx.author = "Matthieu";
			pptx.company = "Groupe Mauffrey";
			pptx.defineSlideMaster({
				title: "MASTER_SLIDE_WHITE",
				background: { fill: "FFFFFF" },
				margin: 0,
				objects: [
					{
						rect: {
							x: 0,
							y: 0,
							w: 0.625,
							h: 5.625,
							fill: {
								color: "e30613",
							},
						},
					},
					{
						text: {
							text: presentation.nom.toUpperCase() || "",
							options: {
								x: -2.5,
								y: 2.5,
								w: 5.625,
								h: 0.625,
								color: "FFFFFF",
								align: "center",
								valign: "middle",
								bold: false,
								fontFace: "Arial",
								fontSize: 8,
								italic: true,
								rotate: 270,
								isTextBox: true,
								margin: 20,
							},
						},
					},
					{
						text: {
							text: presentation.nom_client ? presentation.nom_client.toUpperCase() : "",
							options: {
								x: -2.5,
								y: 2.5,
								w: 5.625,
								h: 0.625,
								color: "FFFFFF",
								align: "right",
								valign: "middle",
								bold: true,
								fontSize: 8,
								fontFace: "Arial",
								italic: true,
								rotate: 270,
								isTextBox: true,
								margin: 20,
							},
						},
					},
				],
				slideNumber: { x: 0, y: 5.2, w: 0.625, color: "FFFFFF", align: "center", fontSize: 10 },
			});
			pptx.defineSlideMaster({
				title: "MASTER_SLIDE_CLEAN",
				background: { fill: "FFFFFF" },
				margin: 0,
				objects: [
					{
						rect: {
							x: 0,
							y: 0,
							w: 0.625,
							h: 5.625,
							fill: {
								color: "FFFFFF",
							},
						},
					},
					{
						text: {
							text: presentation.nom || "",
							options: {
								x: -2.5,
								y: 2.5,
								w: 5.625,
								h: 0.625,
								color: "000000",
								align: "center",
								valign: "middle",
								bold: false,
								fontFace: "Arial",
								fontWeight: "bold",
								fontSize: 8,
								italic: true,
								rotate: 270,
								isTextBox: true,
								margin: 20,
							},
						},
					},
					{
						text: {
							text: presentation.nom_client ? presentation.nom_client.toUpperCase() : "",
							options: {
								x: -2.5,
								y: 2.5,
								w: 5.625,
								h: 0.625,
								color: "000000",
								align: "right",
								valign: "middle",
								bold: true,
								fontSize: 8,
								fontFace: "Arial",
								italic: true,
								rotate: 270,
								isTextBox: true,
								margin: 20,
							},
						},
					},
				],
				slideNumber: { x: 0, y: 5.2, w: 0.625, color: "000000", align: "center", fontSize: 10 },
			});
			pptx.defineSlideMaster({
				title: "MASTER_SLIDE_BLACK",
				background: { fill: "FFFFFF" },
				margin: 0,
				objects: [
					{
						rect: {
							x: 0,
							y: 0,
							w: 0.625,
							h: 5.625,
							fill: {
								color: "000000",
							},
						},
					},
					{
						text: {
							text: presentation.nom.toUpperCase(),
							options: {
								x: -2.5,
								y: 2.5,
								w: 5.625,
								h: 0.625,
								color: "FFFFFF",
								align: "center",
								valign: "middle",
								bold: false,
								fontFace: "Arial",
								fontSize: 8,
								italic: true,
								rotate: 270,
								isTextBox: true,
								margin: 20,
							},
						},
					},
					{
						text: {
							text: presentation.nom_client ? presentation.nom_client.toUpperCase() : "",
							options: {
								x: -2.5,
								y: 2.5,
								w: 5.625,
								h: 0.625,
								color: "FFFFFF",
								align: "right",
								valign: "middle",
								bold: true,
								fontSize: 8,
								fontFace: "Arial",
								italic: true,
								rotate: 270,
								isTextBox: true,
								margin: 20,
							},
						},
					},
				],
				slideNumber: { x: 0, y: 5.2, w: 0.625, color: "FFFFFF", align: "center", fontSize: 10 },
			});
			presentation.slides.forEach((slide) => {
				let genSlide;
				let masterName = "MASTER_SLIDE_WHITE";
				if (!slide.barre_color) {
					slide.barre_color = "e30613";
				}

				let barre_color = slide.barre_color.replace("#", "");
				if (barre_color === "000000") {
					masterName = "MASTER_SLIDE_BLACK";
				}
				if (barre_color === "FFFFFF") {
					masterName = "MASTER_SLIDE_CLEAN";
				}
				genSlide = pptx.addSlide({ masterName: masterName });
				genSlide.color = slide.color;
				genSlide.background = { fill: slide.fill.replace("#", "") };
				if (slide.composants) {
					slide.composants.forEach((el) => {
						if (el.type === "text") {
							let content_parse = el.content;
							let is_sommaire_component = false;
							let puces = [];

							// Je vérifie si c'est le nom du client
							if (content_parse.includes("[nom_client]")) {
								content_parse = content_parse.replace(/\[nom_client\]/g, presentation.nom_client);
							}
							// Je vérifie les shorcodes
							globales.forEach((globale) => {
								if (content_parse.includes(`[${globale.fields.code_court}]`)) {
									let regex = new RegExp(`\\[${globale.fields.code_court}\\]`, 'g');
									content_parse = content_parse.replace(regex, globale.fields.valeur_retournee);
								}
							});
							let options = {
								x: el.x / 96,
								y: el.y / 96,
								w: el.w / 96,
								h: el.h / 96,
								lineSpacing: el.fontSize * el.lineSpacing,
								color: el.color.replace("#", ""),
								align: "left",
								valign: el.valign,
								inset: 0,
								autofit: false,
								original_linespacing: el.lineSpacing,
								italic: el.italic,
								rotate: el.rotate,
								isTextBox: el.isTextBox,
								margin: el.margin / 2,
								fontSize: el.fontSize,
								fontFace: "Arial",
								lang: "fr-FR",
								underline: {
									style: "none",
								},
							};
							if (el.fill) {
								options.fill = {
									color: el.fill.replace("#", ""),
								};
							}
							if (el.hyperlink != undefined && el.hyperlink.url != "") {
								options.hyperlink = {
									url: encodeXml(el.hyperlink.url),
								};
							}

							if (el.isUpperCase) {
								content_parse = content_parse.toUpperCase();
							}
							if (is_sommaire_component) {
								genSlide.addText(puces, {
									x: el.x / 96,
									y: el.y / 96,
									w: el.w / 96,
									h: el.h / 96,
									valign: el.valign,
									margin: el.margin,
									lineSpacing: el.fontSize * el.lineSpacing,
									autofit: true,
									lang: "fr-FR",
									breakLine: false,
									fontFace: "Arial",
								});
							} else {
								// let other_options = {};
								options.css = `h1 {font-size: 18pt;padding-bottom: 5px;}
										h2 {font-size: 13pt;padding-bottom: 5px;}
										h3 {font-size: 11pt;padding-bottom: 5px;}
										em {font-style: italic;}
										strong {font-weight: bold;}
										u {text-decoration: underline;}
										blockquote {color: #e30613;font-size: 8;font-style: italic;font-weight: bold;text-align: center;}
										.ql-align-center {text-align: center;}
										.ql-align-right {text-align: right;}
										a {color: inherit;}`;
								content_parse = content_parse.replaceAll("&#xFEFF", "");
								content_parse = content_parse.replaceAll('<span class="ql-cursor"></span>', "");
								content_parse = content_parse.replaceAll("<p><br></p>", "<br>");
								content_parse = content_parse.replaceAll('<p class="ql-align-center"><br></p>', "<br>");
								content_parse = content_parse.replaceAll('<p class="ql-align-right"><br></p>', "<br>");
								const items = html2pptxgenjs.htmlToPptxText(content_parse, options);
								genSlide.addText(items, {
									x: options.x,
									y: options.y,
									w: options.w,
									h: options.h,
									lineSpacing: options.lineSpacing,
									valign: options.valign,
									fontSize: options.fontSize,
									fontFace: options.fontFace,
									isTextBox: options.isTextBox,
									autofit: false,
									lang: "fr-FR",
									color: options.original_color,
								});
							}
						} else if (el.type === "liste") {
							let options = {
								x: el.x / 96,
								y: el.y / 96,
								w: el.w / 96,
								h: el.h / 96,
								lineSpacing: el.fontSize * el.lineSpacing,
								valign: el.valign,
								margin: el.margin / 2,
								fontFace: "Arial",
							};
							if (el.fill) {
								options.fill = {
									color: el.fill.replace("#", ""),
								};
							}
							let puces = [];
							el.puces.forEach((item) => {
								puces.push({
									text: "> ",
									options: {
										color: "e30613",
										bold: true,
										bullet: false,
										breakLine: false,
										fontSize: el.fontSize,
										indentLevel: 0,
									},
								});
								puces.push({
									text: `${item.text} \n`,
									options: {
										//align: el.align,
										color: el.color.replace("#", ""),
										breakline: true,
										bold: el.bold,
										italic: el.italic,
										fontSize: el.fontSize,
										indentLevel: 1,
									},
								});
							});
							genSlide.addText(puces, options);
						} else if (el.type === "shape") {
							let forme;
							let options = {};
							if (!el.line.color) {
								el.line.color = "FFFFFF";
							}
							if (!el.line.width) {
								el.line.width = 0;
							}
							if (el.line.width === 0) {
								options = {
									x: el.x / 96,
									y: el.y / 96,
									w: el.w / 96,
									h: el.h / 96,
									rectRadius: el.rectRadius / 96,
									rotate: el.rotate,
									fill: {
										color: el.fill.color.replace("#", ""),
										transparency: el.fill.transparency,
									},
								};
							} else {
								options = {
									x: el.x / 96,
									y: el.y / 96,
									w: el.w / 96,
									h: el.h / 96,
									rectRadius: el.rectRadius / 96,
									rotate: el.rotate,
									line: {
										color: el.line.color.replace("#", ""),
										dashType: el.line.dashType,
										width: parseInt(el.line.width),
									},
									fill: {
										color: el.fill.color.replace("#", ""),
										transparency: el.fill.transparency,
									},
								};
							}
							if (options.rectRadius === 0) {
								forme = pptx.shapes.RECTANGLE;
							} else {
								forme = pptx.shapes.ROUNDED_RECTANGLE;
							}
							if (el.shadow && el.shadow.type != "none") {
								options.shadow = {
									angle: el.shadow.angle,
									blur: el.shadow.blur,
									color: el.shadow.color,
									offset: el.shadow.offset,
									opacity: el.shadow.opacity,
									type: el.shadow.type,
								};
								options.shadow.color = options.shadow.color.replace("#", "");
							}
							genSlide.addShape(forme, options);
						} else if (el.type === "image") {
							let options = {};

							if (el.sizing.type === "crop") {
								// On previent les gros bugs
								options = {
									x: el.x / 96,
									y: el.y / 96,
									w: (el.w * (el.sizing.zoom / 100)) / 96,
									h: (el.h * (el.sizing.zoom / 100)) / 96,
									path: el.path,
									rounding: el.rounding,
									transparency: el.transparency || 0,
									sizing: {
										type: el.sizing.type,
										w: el.sizing.w / 96,
										h: el.sizing.h / 96,
										x: (el.sizing.decalage_x / 96) * -1,
										y: (el.sizing.decalage_y / 96) * -1,
									},
								};
							} else {
								options = {
									x: el.x / 96,
									y: el.y / 96,
									w: el.w / 96,
									h: el.h / 96,
									path: el.path,
									rounding: el.rounding,
									transparency: el.transparency || 0,
									sizing: {
										type: el.sizing.type,
										w: el.sizing.w / 96,
										h: el.sizing.h / 96,
									},
								};
							}
							if (el.hyperlink != undefined && el.hyperlink.url != "") {
								options.hyperlink = { url: encodeXml(el.hyperlink.url) };
							}
							genSlide.addImage(options);
						} else if (el.type === "tableau") {
							let rownumber = el.rows;
							let colnumber = el.cols;
							let temp_bold = false;
							let temp_fill = "FBFCFC";
							let options = {
								x: el.x / 96,
								y: el.y / 96,
								w: el.w / 96,
								h: el.h / 96,
								valign: el.valign,
								fontSize: el.fontSize,
								align: el.align,
								fill: "FBFCFC",
								bold: el.bold,
								italic: el.italic,
								margin: 6,
								border: { pt: 4, color: el.borderColor?.replace("#", "") || 'FFFFFF'},
							};
							let duplicate_table = JSON.parse(JSON.stringify(el.content));
							duplicate_table.forEach((row, rindex) => {
								row.forEach((cell, cindex) => {
									if (
										(rindex === 0 && el.first_row_heading) ||
										(rindex === el.rows - 1 && el.last_row_heading) ||
										(cindex === 0 && el.first_colonne_heading) ||
										(cindex === el.cols - 1 && el.last_colonne_heading)
									) {
										temp_bold = true;
									} else {
										temp_bold = false;
									}
									if (cell.text === "" || cell.text === undefined || cell.text === null) {
										temp_fill = "FFFFFF";
									} else {
										globales.forEach((globale) => {
											if (cell.text.includes(`[${globale.fields.code_court}]`)) {
												cell.text = cell.text.replace(`[${globale.fields.code_court}]`, globale.fields.valeur_retournee);
											}
										});
										temp_fill = "FBFCFC";
									}
									cell.options = {
										fill: { color: temp_fill },
										bold: temp_bold,
									};
								});
							});
							genSlide.addTable(duplicate_table, options);
						} else if (el.type === "graph") {
							let options = {
								x: el.x / 96,
								y: el.y / 96,
								w: el.w / 96,
								h: el.h / 96,
								chartColors: el.chartColors,
								showValue: false,
								showPercent: true,
								dataBorder: { pt: "1", color: "FFFFFF" },
								showLegend: el.showLegend,
								dataLabelColor: "FFFFFF",
								dataLabelFontSize: 12,
								dataLabelFontFace: "Arial",
								legendFontFace: "Arial",
								holeSize: 50,
							};
							if (el.type_chart === "PIE") {
								genSlide.addChart(pptx.charts.DOUGHNUT, el.data, options);
							} else if (el.type_chart === "AREA") {
								genSlide.addChart(pptx.charts.AREA, el.data, options);
							} else if (el.type_chart === "BAR") {
								genSlide.addChart(pptx.charts.BAR, el.data, options);
							} else if (el.type_chart === "LINE") {
								genSlide.addChart(pptx.charts.LINE, el.data, options);
							}
						} else if (el.type === "online") {
							let options = {
								x: el.x / 96,
								y: el.y / 96,
								w: el.w / 96,
								h: el.h / 96,
								link: el.link,
								type: el.type,
							};
							genSlide.addMedia(options);
						}
					});
				}
			});
			pptx.writeFile({ fileName: `${presentation.nom}.pptx`, compression: true }).then((filename) => {
				state.generation_en_cours = false;
				Notify({
					message: `${filename} a correctement été exportée et enregistrée sur votre ordinateur.`,
					background: "#f4f8ff",
					color: "#377dff",
					duration: 4000,
				});
			});
		},
		generateSlideMiniature({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				let slides = [...document.querySelectorAll("#editor #rendu .slides .slide")];
				let items = [...document.querySelectorAll("#navigator .ordering_list .item")];
				let slide = slides[payload];
				slide.classList.add("previewmode");
				html2canvas(slide, {
					width: 960,
					height: 540,
					scrollX: 0,
					scale: 0.1,
					allowTaint: false,
					useCORS: true,
					logging: false,
					scrollY: -window.scrollY,
				}).then((canvas) => {
					commit("changeMiniature", {
						index: payload,
						data: canvas.toDataURL("image/jpeg"),
					});
					slide.classList.remove("previewmode");
				});
			});
		},
		selectSlide({ state, dispatch }, payload) {
			return new Promise((resolve, reject) => {
				let current_diapositive = state.current_diapositive;
				if (current_diapositive) {
					setTimeout(() => {
						//dispatch("generateSlideMiniature", current_diapositive);
					}, 1000);
				}
				state.current_diapositive = payload;
				state.current_composant.index = null;
				state.current_composant.type = "";
				state.current_tab = "diapositive";
				state.properties.zoom = 1.0;
				setTimeout(() => {
					let slide_selected = document.querySelector("#editor .slides .slide.selected");
					if (payload) {
						state.positionForNewSlide = payload;
					} else if (slide_selected) {
						state.positionForNewSlide = parseInt(slide_selected.style.order) + 1;
					} else {
						state.positionForNewSlide = state.current_presentation.fields.slides.length;
					}
					window.scrollTo({
						top: slide_selected.offsetTop - 100,
						behavior: "smooth",
					});
					setTimeout(() => {
						let navigator = document.querySelector("#navigator");
						if (navigator) {
							let preview_selected = navigator.querySelector(".item.selected");
							if (!isInViewport(preview_selected)) {
								navigator.scrollTop = preview_selected.offsetTop - 100;
							}
						}
						resolve();
					}, 10);
				}, 10);
			});
		},
		addToHistorique({ state }) {
			return new Promise((resolve, reject) => {
				if (state.current_presentation && state.historique.length <= 5) {
					let last_state = JSON.stringify(state.current_presentation);
					state.historique.push(last_state);
					resolve();
				} else if (state.historique.length > 5) {
					state.historique.shift();
					let last_state = JSON.stringify(state.current_presentation);
					state.historique.push(last_state);
					resolve();
				} else {
					reject("Une erreur est survenue. Impossible de mémoriser votre action.");
				}
			});
		},
		getGlobales({ state, commit }) {
			state.globales = [];
			state.notices.globales = false;
			return new Promise((resolve, reject) => {
				base("globales")
					.select({
						// Selecting the first 3 records in modeles:
						pageSize: 10,
						view: "globales",
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((record) => {
								if (record.fields.type === "image" && record.fields.image_json) {
									fetch(record.fields.image_json[0].url)
										.then((res) => res.json())
										.then((json) => {
											record.fields.image_json_code = json.preview;
											record.fields.image_width = json.width;
											record.fields.image_height = json.height;
											commit("addGlobale", record);
										});
								} else {
									commit("addGlobale", record);
								}
							});
							fetchNextPage();
						},
						function done(err) {
							state.notices.globales = false;
							if (err) {
								reject(err);
								return;
							}
							resolve();
						}
					);
			});
		},
		getModeles({ state, commit }) {
			if (state.retrieve_modele_encours) return;
			state.modeles = [];
			state.retrieve_modele_encours = true;
			state.notices.modeles = true;
			return new Promise((resolve, reject) => {
				base("modeles")
					.select({
						// Selecting the first 3 records in modeles:
						pageSize: 10,
						view: "modeles",
						sort: [{ field: "nom", direction: "asc" }],
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((record) => {
								if (record.fields.preview) {
									fetch(record.fields.preview[0].url)
										.then((res) => res.json())
										.then((json) => {
											record.fields.preview_url = json.preview;
											commit("addModele", record);
										})
										.catch((err) => {
											record.fields.preview_url = "";
											commit("addModele", record);
										});
								} else {
									commit("addModele", record);
								}
							});

							fetchNextPage();
						},
						function done(err) {
							state.notices.modeles = false;
							if (err) {
								reject(err);
								return;
							}
							state.retrieve_modele_encours = false;
							resolve();
						}
					);
			});
		},
		getSquelettes({ state, commit }) {
			state.squelettes = [];
			state.squelettes_ready = false;
			state.notices.squelettes = true;
			return new Promise((resolve, reject) => {
				base("squelettes")
					.select({
						// Selecting the first 3 records in modeles:
						pageSize: 20,
						view: "squelettes",
						sort: [{ field: "nom", direction: "asc" }],
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((record) => {
								if (record.fields.preview) {
									fetch(record.fields.preview[0].url)
										.then((res) => res.json())
										.then((json) => {
											record.fields.preview_url = json.preview;
											commit("addSquelette", record);
										})
										.catch((err) => {
											record.fields.preview_url = "";
											commit("addSquelette", record);
										});
								} else {
									commit("addSquelette", record);
								}
							});
							fetchNextPage();
						},
						function done(err) {
							state.notices.squelettes = false;
							if (err) {
								reject(err);
								return;
							}
							state.squelettes_ready = true;
							resolve();
						}
					);
			});
		},
		getPresentations({ state, commit, dispatch }) {
			state.presentations = [];
			return new Promise((resolve, reject) => {
				if (state.user.id) {
					// Je recharge les présentations
					base("utilisateurs").find(state.user.id, function (err, record) {
						if (err) {
							console.error(err);
							return;
						}
						commit("updateUser", record);
						if (!state.user.fields.presentations) {
							state.user.fields.presentations = [];
						}
						if (!state.user.fields["presentations 2"]) {
							state.user.fields["presentations 2"] = [];
						}
						let all_presentations = [...state.user.fields.presentations, ...state.user.fields["presentations 2"]];

						if (all_presentations.length >= 1) {
							all_presentations.forEach((prez) => {
								base("presentations").find(prez, (err, record) => {
									if (err) {
										console.error(err);
										return;
									}
									commit("addPresentation", record);
								});
							});
							resolve();
						} else {
							resolve();
						}
					});
				} else {
					reject();
					router.push({ name: "Connexion" });
				}
			});
		},
		getPresentationsPartages({ state, commit }) {
			state.presentations_partagees = [];
			return new Promise((resolve, reject) => {
				// action
			});
		},
		getPresentationsArchives({ state, commit }) {
			state.presentations_archives = [];
			return new Promise((resolve, reject) => {
				base("presentations")
					.select({
						view: "presentations_archivees",
						pageSize: 20,
						fields: ["nom", "auteur", "statut", "auteur_email", "partagee_avec", "modifiee_le"],
						filterByFormula: `({auteur_email} = '${state.user.fields.email}')`,
						sort: [{ field: "modifiee_le", direction: "desc" }],
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((record) => {
								commit("addPresentationArchives", record);
							});
							fetchNextPage();
						},
						function done(err) {
							if (err) {
								reject(err);
							}
							resolve();
						}
					);
			});
		},
		getPrestations({ state, commit }) {
			state.prestations = [];
			state.notices.prestations = true;
			return new Promise((resolve, reject) => {
				base("prestations")
					.select({
						pageSize: 20,
						view: "prestations",
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((record) => {
								commit("addPrestation", record);
							});
							fetchNextPage();
						},
						function done(err) {
							state.notices.prestations = false;
							if (err) {
								reject(err);
								return;
							}
							resolve();
						}
					);
			});
		},
		getProcedures({ state, commit }) {
			state.procedures = [];
			state.notices.procedures = true;
			return new Promise((resolve, reject) => {
				base("procedures")
					.select({
						pageSize: 20,
						view: "procedures",
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((record) => {
								commit("addProcedure", record);
							});
							fetchNextPage();
						},
						function done(err) {
							state.notices.procedures = false;
							if (err) {
								reject(err);
								return;
							}
							resolve();
						}
					);
			});
		},
		getToolbox({ state, commit }) {
			state.toolbox = [];
			state.notices.toolbox = true;
			return new Promise((resolve, reject) => {
				base("toolbox")
					.select({
						pageSize: 20,
						view: "toolbox",
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((record) => {
								commit("addToolbox", record);
							});
							fetchNextPage();
						},
						function done(err) {
							state.notices.toolbox = false;
							if (err) {
								reject(err);
								return;
							}
							resolve();
						}
					);
			});
		},
		getMateriel({ state, commit }) {
			state.materiel = [];
			state.notices.materiel = true;
			return new Promise((resolve, reject) => {
				base("materiel")
					.select({
						pageSize: 20,
						view: "materiel",
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((record) => {
								commit("addMateriel", record);
							});
							fetchNextPage();
						},
						function done(err) {
							state.notices.materiel = false;
							if (err) {
								reject(err);
								return;
							}
							resolve();
						}
					);
			});
		},
		getDigitalisation({ state, commit }) {
			state.digitalisation = [];
			state.notices.digitalisation = true;
			return new Promise((resolve, reject) => {
				base("digitalisation")
					.select({
						pageSize: 20,
						view: "digitalisation",
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((record) => {
								commit("addDigitalisation", record);
							});
							fetchNextPage();
						},
						function done(err) {
							state.notices.digitalisation = false;
							if (err) {
								reject(err);
								return;
							}
							resolve();
						}
					);
			});
		},
		getFormation({ state, commit }) {
			state.formation = [];
			state.notices.formation = true;
			return new Promise((resolve, reject) => {
				base("formation")
					.select({
						pageSize: 20,
						view: "formation",
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((record) => {
								commit("addFormation", record);
							});
							fetchNextPage();
						},
						function done(err) {
							state.notices.formation = false;
							if (err) {
								reject(err);
								return;
							}
							resolve();
						}
					);
			});
		},
		getQualite({ state, commit }) {
			state.qualite = [];
			state.notices.qualite = true;
			return new Promise((resolve, reject) => {
				base("qualite")
					.select({
						pageSize: 20,
						view: "qualite",
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((record) => {
								commit("addQualite", record);
							});
							fetchNextPage();
						},
						function done(err) {
							state.notices.qualite = false;
							if (err) {
								reject(err);
								return;
							}
							resolve();
						}
					);
			});
		},
		getGeographie({ state, commit }) {
			state.geographie = [];
			state.notices.geographie = true;
			return new Promise((resolve, reject) => {
				base("geographie")
					.select({
						pageSize: 20,
						view: "geographie",
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((record) => {
								commit("addGeographie", record);
							});
							fetchNextPage();
						},
						function done(err) {
							state.notices.geographie = false;
							if (err) {
								reject(err);
								return;
							}
							resolve();
						}
					);
			});
		},
		getGroupe({ state, commit }) {
			state.groupe_mauffrey = [];
			state.notices.groupe = true;
			return new Promise((resolve, reject) => {
				base("groupe_mauffrey")
					.select({
						pageSize: 20,
						view: "groupe",
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((record) => {
								commit("addGroupe", record);
							});
							fetchNextPage();
						},
						function done(err) {
							state.notices.groupe = false;
							if (err) {
								reject(err);
								return;
							}
							resolve();
						}
					);
			});
		},
		getFilieres({ state, commit }) {
			state.filieres = [];
			state.notices.filieres = true;
			return new Promise((resolve, reject) => {
				base("filieres")
					.select({
						pageSize: 20,
						view: "filieres",
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach((record) => {
								commit("addFiliere", record);
							});
							fetchNextPage();
						},
						function done(err) {
							state.notices.filieres = false;
							if (err) {
								reject(err);
								return;
							}
							resolve();
						}
					);
			});
		},
		getUsers({ state, commit }) {
			state.users = [];
			base("utilisateurs")
				.select({
					view: "utilisateurs",
					fields: ["email", "nom", "derniere_connexion", "role", "presentations"],
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach(function (record) {
							commit("addUser", record);
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							return;
						}
					}
				);
		},
		connectUser({ dispatch, state, commit }) {
			state.users = [];
			state.trying_to_connect = true;
			return new Promise((resolve, reject) => {
				msalInstance
					.loginPopup()
					.then((res) => {
						state.success = true;
						state.trying_to_connect = false;
						dispatch("getSessionId").then(() => {
							base("utilisateurs")
								.select({
									view: "utilisateurs",
									fields: ["email", "nom", "role", "presentations", "presentations 2"],
								})
								.eachPage(
									function page(records, fetchNextPage) {
										records.forEach(function (record) {
											commit("addUser", record);
										});
										fetchNextPage();
									},
									function done(err) {
										if (err) {
											return;
										}
										const found = state.users.find((el) => el.fields.email === res.account.username);

										if (found) {
											commit("setUser", found);
											resolve();
										} else {
											base("utilisateurs").create(
												[
													{
														fields: {
															email: res.account.username,
															nom: res.account.name,
															presentations: [],
															modeles: [],
															role: "collaborateur",
															derniere_connexion: new moment().format("YYYY-MM-DD"),
														},
													},
												],
												function (err, records) {
													if (err) {
														reject(err);
														return;
													}
													commit("setUser", records[0]);
													resolve();
												}
											);
										}
									}
								);
						});
					})
					.catch((err) => {
						state.loginFailMessage = err;
						state.trying_to_connect = false;
						reject(err);
					});
			});
		},
		updateContent({ commit }, payload) {
			return new Promise((resolve) => {
				commit("updateContent", payload);
				resolve();
			});
		},
		updateElementSize({ commit }, payload) {
			return new Promise((resolve) => {
				commit("updateSize", payload);
				resolve();
			});
		},
		updateElementSizeImage({ commit }, payload) {
			return new Promise((resolve) => {
				commit("updateSizeImage", payload);
				resolve();
			});
		},
		updateElementSizeCropImage({ state, commit, dispatch }, payload) {
			return new Promise((resolve) => {
				dispatch("addToHistorique").then(() => {
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].w = Math.round(payload.sizes.width);
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].h = Math.round(payload.sizes.height);
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.decalage_x = Math.round(payload.sizes.x);
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.decalage_y = Math.round(payload.sizes.y);
					resolve();
				});
			});
		},
		updateElementPosition({ commit }, payload) {
			return new Promise((resolve) => {
				commit("updatePosition", payload);
				resolve();
			});
		},
		updateImageCrop({ commit }, payload) {
			return new Promise((resolve) => {
				commit("updateCrop", payload);
				resolve();
			});
		},
		updateImageDecalageX({ state }, payload) {
			state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.decalage_x = payload.event.target.value;
		},
		updateImageDecalageY({ state }, payload) {
			state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.decalage_y = payload.event.target.value;
		},
		updateImageSizingType({ state, dispatch }, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.type = payload.type;
				if (payload.type === "crop") {
					if (!state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.zoom) {
						state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.zoom = 100;
					}
					if (!state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.decalage_x) {
						state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.decalage_x = 0;
					}
					if (!state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.decalage_y) {
						state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.decalage_y = 0;
					}
				}
			});
		},
		updateBoxShadow({ state, dispatch }, payload) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[payload.slide].composants[payload.composant].shadow.type = payload.type;
				if (payload.type === "outer") {
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].shadow.angle = 60;
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].shadow.blur = 30;
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].shadow.offset = 0;
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].shadow.opacity = 0.5;
				} else {
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].shadow.angle = 0;
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].shadow.blur = 0;
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].shadow.offset = 0;
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].shadow.opacity = 0;
				}
			});
		},
		addSlideSquelette({ state, commit }, slides) {
			console.log(slides);

			if (!slides) return;

			// Je décale toutes les slides qui viennent après
			for (let i = slides[0].position; i < state.current_presentation.fields.slides.length; i++) {
				state.current_presentation.fields.slides[i].order += slides.length;
			}

			// Je prends toutes mes slides et je les ajoutes
			slides.forEach((slide, index) => {
				let new_slide = JSON.parse(JSON.stringify(slide.modele));
				new_slide.order = slide.position;
				commit("addSlide", new_slide);
			});
		},
		addSlide({ state, commit }, payload) {
			console.log("Position souhaitée", payload.position);
			let new_order = state.current_diapositive + 1;
			if (payload.position) {
				new_order = payload.position + 1;
			}
			let modele = payload.modele;
			let new_slide = {};
			if (modele === "vierge") {
				for (let i = new_order; i < state.current_presentation.fields.slides.length; i++) {
					state.current_presentation.fields.slides[i].order += 1;
				}
				new_slide = {
					fill: "#FFFFFF",
					color: "#000000",
					path: null,
					name: `Diapositive #${new_order}`,
					order: new_order,
					barre_color: "#e30613",
					composants: [
						{
							type: "text",
							x: 110,
							y: 40,
							w: 491,
							h: 58,
							color: "#000000",
							align: "left",
							fontSize: 18,
							bold: true,
							valign: "top",
							content: "Titre",
							rotate: 0,
							isTextBox: true,
							isUpperCase: false,
							margin: 0,
							useForSommaire: false,
							hyperlink: { url: "" },
							fill: "transparent",
							lineSpacing: 1.3,
							italic: false,
						},
						{
							type: "text",
							x: 111,
							y: 100,
							w: 492,
							h: 283,
							color: "#444444",
							align: "left",
							fontSize: 9,
							bold: false,
							lineSpacing: 1.4,
							italic: false,
							valign: "top",
							content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt sapien eu molestie interdum. Aliquam sit amet vehicula nulla. Nunc ac imperdiet diam, sit amet iaculis lectus. Aenean auctor nisi augue, sit amet fringilla augue tincidunt id. Integer blandit vitae nisi a ullamcorper. Aliquam ac massa eros. Morbi ligula sapien, mollis vitae accumsan quis, sagittis nec ex. Aenean vel malesuada odio. Aliquam mattis elit vitae massa accumsan luctus. Cras vestibulum interdum dui id placerat.",
							rotate: 0,
							margin: 0,
							isTextBox: true,
							isUpperCase: false,
							fill: "",
							hyperlink: { url: "" },
							useForSommaire: false,
						},
						{
							type: "image",
							x: 851,
							y: 480,
							w: 100,
							h: 41.69,
							path: `${process.env.VUE_APP_MEDIABASE_UPLOAD_URL}logo-m-noir.png`,
							sizing: { type: "cover", w: 85, h: 36 },
						},
					],
				};
				return new Promise((resolve) => {
					commit("addSlide", new_slide);
					resolve();
				});
			} else {
				if (modele[0] === undefined) {
					for (let i = new_order; i < state.current_presentation.fields.slides.length; i++) {
						state.current_presentation.fields.slides[i].order += 1;
					}
					new_slide = modele;
					new_slide.order = new_order;
					// Le probleme est ici : les positions de slides sont décalées au fur et à mesure. Il faudrait que je retienne le tout.
					console.log("Position finale", payload.position);
					return new Promise((resolve) => {
						commit("addSlide", new_slide);
						resolve();
					});
				} else {
					for (let i = new_order; i < state.current_presentation.fields.slides.length; i++) {
						state.current_presentation.fields.slides[i].order += modele.length;
					}
					return new Promise((resolve) => {
						modele.forEach((el) => {
							new_slide = el;
							new_slide.order = new_order;
							commit("addSlide", new_slide);
							new_order++;
						});
						resolve();
					});
				}
			}
		},
		addText({ commit }) {
			return new Promise((resolve) => {
				let composant = {
					type: "text",
					x: 110,
					y: 40,
					w: 290,
					h: 40,
					color: "#000000",
					align: "left",
					fontSize: 11,
					indentLevel: 0,
					bold: false,
					lineSpacing: 1.4,
					italic: false,
					valign: "top",
					content: `Ma nouvelle zone de texte`,
					rotate: 0,
					inset: 0,
					margin: 0,
					lang: "fr-FR",
					isTextBox: true,
					fill: "",
					hyperlink: {
						url: "",
					},
					useForSommaire: false,
				};
				commit("addComposant", composant);
				resolve();
			});
		},
		addListe({ commit }) {
			return new Promise((resolve) => {
				this.dispatch("addToHistorique").then(() => {
					let composant = {
						type: "liste",
						x: 110,
						y: 40,
						w: 300,
						h: 100,
						color: "#000000",
						align: "left",
						fontSize: 12,
						bold: false,
						lineSpacing: 1.2,
						italic: false,
						valign: "top",
						rotate: 0,
						isTextBox: true,
						margin: 0,
						fill: "",
						puces: [
							{
								text: "Ma première ligne",
							},
							{
								text: "Ma second ligne",
							},
						],
					};
					commit("addComposant", composant);
					resolve();
				});
			});
		},
		addShape({ commit }) {
			return new Promise((resolve) => {
				this.dispatch("addToHistorique").then(() => {
					let composant = {
						type: "shape",
						x: 110,
						y: 40,
						w: 200,
						h: 100,
						line: {
							width: 0,
							dashType: "solid",
							color: "#000000",
						},
						fill: {
							color: "#e30613",
							transparency: 0,
						},
						rectRadius: 0,
						rotate: 0,
						shadow: {
							type: "none",
							angle: 0,
							blur: 0,
							color: "#000000",
							offset: 0,
							opacity: 0,
						},
						click_a_travers: false,
					};
					commit("addComposant", composant);
					resolve();
				});
			});
		},
		addTable({ commit }) {
			return new Promise((resolve) => {
				this.dispatch("addToHistorique").then(() => {
					let composant = {
						type: "tableau",
						x: 110,
						y: 40,
						w: 800,
						h: 400,
						fill: {
							color: "#fbfcfc",
						},
						color: "#000000",
						align: "center",
						valign: "middle",
						lineSpacing: 1.4,
						fontSize: 12,
						margin: 0,
						rows: 3,
						cols: 3,
						borderColor: "#FFFFFF",
						first_colonne_heading: false,
						first_row_heading: false,
						last_row_heading: false,
						last_colonne_heading: false,
						content: [
							[{ text: "Cellule A1" }, { text: "Cellule B1" }, { text: "Cellule C1" }],
							[{ text: "Cellule A2" }, { text: "Cellule B2" }, { text: "Cellule C2" }],
							[{ text: "Cellule A3" }, { text: "Cellule B3" }, { text: "Cellule C3" }],
						],
					};
					commit("addComposant", composant);
					resolve();
				});
			});
		},
		addImage({ commit }) {
			return new Promise((resolve) => {
				this.dispatch("addToHistorique").then(() => {
					let composant = {
						type: "image",
						x: 110,
						y: 100,
						w: 300,
						h: 160,
						path: `${process.env.VUE_APP_MEDIABASE_UPLOAD_URL}image-vierge-1.png`,
						is_dynamic: false,
						dynamic_code: "",
						rounding: false,
						transparency: 0,
						hyperlink: {
							url: "",
						},
						sizing: {
							type: "contain",
							w: 300,
							h: 160,
							x: 0,
							y: 0,
							zoom: 100,
							decalage_x: 0,
							decalage_y: 0,
						},
					};
					commit("addComposant", composant);
					resolve();
				});
			});
		},
		addGraph({ commit }) {
			return new Promise((resolve) => {
				this.dispatch("addToHistorique").then(() => {
					let composant = {
						type: "graph",
						type_chart: "PIE",
						x: 300,
						y: 70,
						w: 400,
						h: 400,
						showLegend: true,
						chartColors: ["b71c1c", "c62828", "d32f2f", "7f0000", "e53935", "ef9a9a", "e57373", "ef5350", "f44336", "ffcdd2", "ffebee"],
						data: [
							{
								name: "Répartition",
								labels: ["Donnée 1", "Donnée 2", "Donnée 3", "Donnée 4", "Donnée 5"],
								values: [1500, 4600, 5156, 3167, 8510],
							},
						],
					};
					commit("addComposant", composant);
					resolve();
				});
			});
		},
		addVideo({ commit }) {
			return new Promise((resolve) => {
				this.dispatch("addToHistorique").then(() => {
					let composant = {
						media_type: "video",
						x: 300,
						y: 70,
						w: 400,
						h: 210,
						type: "online",
						link: "https://www.youtube.com/watch?v=1rRluQy2RBY",
					};
					commit("addComposant", composant);
					resolve();
				});
			});
		},
		uploadImage({ commit, dispatch, state }, payload) {
			return new Promise((resolve, reject) => {
				dispatch("getSessionId").then(() => {
					const data = new FormData();
					data.append("service", "upload_doc");
					data.append("SESSION_ID", state.session_id);
					data.append("file", payload.file);
					axios.post(`${process.env.VUE_APP_MEDIABASE_UPLOAD_API}custom_service/`, data)
						.then((res2) => {
							resolve(res2.data.result);
						})
						.catch((err) => {
							reject(err);
						});
				});
			});
		},
		uploadJson({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				const data = new FormData();
				data.append("file", payload);
				axios({
					method: "POST",
					url: `${process.env.VUE_APP_MAUFFREY_API_URL}json.php`,
					data: data,
				})
					.then((res) => {
						resolve(res);
					})
					.catch((error) => {
						reject(error.message);
					});
			});
		},
		getFullPresentation({ commit, state }, payload) {
			commit("showLoadingApp");
			return new Promise((resolve, reject) => {
				commit("refreshPresentation");
				base("presentations").find(payload, (err, record) => {
					commit("showLoadingApp");
					if (err) {
						reject(err);
						return;
					}
					if (record.fields.fichier_config) {
						fetch(record.fields.fichier_config[0].url)
							.then((res) => res.json())
							.then((json) => {
								record.fields.slides = json;
								commit("setCurrentPresentation", record);
								resolve(record);
							})
							.catch(() => {
								record.fields.slides = [];
								commit("setCurrentPresentation", record);
								resolve();
							});
					} else {
						record.fields.slides = [
							{
								fill: "#FFFFFF",
								color: "#000000",
								path: null,
								name: `Première diapositive`,
								order: 0,
								barre_color: "#e30613",
								composants: [
									{
										type: "text",
										x: 110,
										y: 40,
										w: 491,
										h: 58,
										color: "#000000",
										align: "left",
										fontSize: 18,
										bold: true,
										valign: "top",
										content: "Titre",
										rotate: 0,
										isTextBox: true,
										isUpperCase: false,
										margin: 0,
										useForSommaire: false,
										hyperlink: { url: "" },
										fill: "transparent",
										lineSpacing: 1.3,
										italic: false,
									},
									{
										type: "text",
										x: 111,
										y: 100,
										w: 492,
										h: 283,
										color: "#444444",
										align: "left",
										fontSize: 9,
										bold: false,
										lineSpacing: 1.4,
										italic: false,
										valign: "top",
										content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt sapien eu molestie interdum. Aliquam sit amet vehicula nulla. Nunc ac imperdiet diam, sit amet iaculis lectus. Aenean auctor nisi augue, sit amet fringilla augue tincidunt id. Integer blandit vitae nisi a ullamcorper. Aliquam ac massa eros. Morbi ligula sapien, mollis vitae accumsan quis, sagittis nec ex. Aenean vel malesuada odio. Aliquam mattis elit vitae massa accumsan luctus. Cras vestibulum interdum dui id placerat.",
										rotate: 0,
										margin: 0,
										isTextBox: true,
										isUpperCase: false,
										fill: "",
										hyperlink: { url: "" },
										useForSommaire: false,
									},
									{
										type: "image",
										x: 851,
										y: 480,
										w: 100,
										h: 41.69,
										path: `${process.env.VUE_APP_MEDIABASE_UPLOAD_URL}logo-m-noir.png`,
										sizing: { type: "cover", w: 85, h: 36 },
									},
								],
							},
						];
						commit("setCurrentPresentation", record);
						resolve(record);
					}
				});
			});
		},
		getFullModele({ commit, state }, payload) {
			commit("showLoadingApp");
			return new Promise((resolve, reject) => {
				commit("refreshPresentation");
				base("modeles").find(payload, (err, record) => {
					commit("showLoadingApp");
					if (err) {
						reject(err);
						return;
					}
					if (!record.fields.procedures) {
						record.fields.procedures = [];
					}
					if (!record.fields.toolbox) {
						record.fields.toolbox = [];
					}
					if (!record.fields.materiel) {
						record.fields.materiel = [];
					}
					if (!record.fields.digitalisation) {
						record.fields.digitalisation = [];
					}
					if (!record.fields.formation) {
						record.fields.formation = [];
					}
					if (!record.fields.qualite) {
						record.fields.qualite = [];
					}
					if (!record.fields.geographie) {
						record.fields.geographie = [];
					}
					if (!record.fields.groupe) {
						record.fields.groupe = [];
					}
					if (!record.fields.filieres) {
						record.fields.filieres = [];
					}
					if (!record.fields.prestations) {
						record.fields.prestations = [];
					}
					if (record.fields.fichier_de_config) {
						fetch(record.fields.fichier_de_config[0].url)
							.then((res) => res.json())
							.then((json) => {
								if (json[0]) {
									record.fields.slides = json;
								} else {
									record.fields.slides = [json];
								}
								commit("setCurrentPresentation", record);
								resolve();
							})
							.catch(() => {
								record.fields.slides = [];
								commit("setCurrentPresentation", record);
								resolve();
							});
					} else {
						record.fields.slides = [
							{
								fill: "#FFFFFF",
								color: "#000000",
								path: null,
								name: record.fields.nom,
								order: 0,
								barre_color: "#e30613",
								composants: [
									{
										type: "text",
										x: 110,
										y: 40,
										w: 491,
										h: 58,
										color: "#000000",
										align: "left",
										fontSize: 18,
										bold: true,
										valign: "top",
										content: "Titre",
										rotate: 0,
										isTextBox: true,
										isUpperCase: false,
										margin: 0,
										useForSommaire: false,
										hyperlink: { url: "" },
										fill: "#FFFFFF",
										lineSpacing: 1.3,
										italic: false,
									},
									{
										type: "text",
										x: 111,
										y: 100,
										w: 492,
										h: 283,
										color: "#444444",
										align: "left",
										fontSize: 9,
										bold: false,
										lineSpacing: 1.4,
										italic: false,
										valign: "top",
										content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt sapien eu molestie interdum. Aliquam sit amet vehicula nulla. Nunc ac imperdiet diam, sit amet iaculis lectus. Aenean auctor nisi augue, sit amet fringilla augue tincidunt id. Integer blandit vitae nisi a ullamcorper. Aliquam ac massa eros. Morbi ligula sapien, mollis vitae accumsan quis, sagittis nec ex. Aenean vel malesuada odio. Aliquam mattis elit vitae massa accumsan luctus. Cras vestibulum interdum dui id placerat.",
										rotate: 0,
										margin: 0,
										isTextBox: true,
										isUpperCase: false,
										fill: "",
										hyperlink: { url: "" },
										useForSommaire: false,
									},
									{
										type: "image",
										x: 851,
										y: 480,
										w: 100,
										h: 41.69,
										path: `${process.env.VUE_APP_MEDIABASE_UPLOAD_URL}logo-m-noir.png`,
										sizing: { type: "cover", w: 85, h: 36 },
									},
								],
							},
						];
						commit("setCurrentPresentation", record);

						resolve();
					}
				});
			});
		},
		resetCurrentSquelette(state) {
			state.currentSquelette = null;
		},
		getFullSquelette({ commit, dispatch, state }, payload) {
			state.current_squelette = null;
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					let skel = state.squelettes.find((el) => el.id === payload);
					if (skel) {
						skel.previews_elements = [];
						if (!skel.fields.composition_new) {
							skel.fields.composition_new = [];
						} else {
							let parsing = JSON.parse(skel.fields.composition_new);
							parsing.forEach((item) => {
								let modele = state.modeles.find((m) => m.id === item);
								if (modele) {
									skel.previews_elements.push({
										id: item,
										true_url: modele.fields.preview[0].url,
										url: modele.fields.preview_url,
									});
								}
							});
						}
						// if (!skel.fields.composition) {
						// 	skel.fields.composition = [];
						// } else {
						// 	skel.fields.composition.forEach((item) => {
						// 		let modele = state.modeles.find((m) => m.id === item);
						// 		if (modele) {
						// 			skel.previews_elements.push({
						// 				id: item,
						// 				url: modele.fields.preview_url,
						// 			});
						// 		}
						// 	});
						// }
						if (!skel.fields.groupe) skel.fields.groupe = [];
						if (!skel.fields.geographie) skel.fields.geographie = [];
						if (!skel.fields.qualite) skel.fields.qualite = [];
						if (!skel.fields.formation) skel.fields.formation = [];
						if (!skel.fields.digitalisation) skel.fields.digitalisation = [];
						if (!skel.fields.prestations) skel.fields.prestations = [];
						if (!skel.fields.filieres) skel.fields.filieres = [];
						if (!skel.fields.materiel) skel.fields.materiel = [];
						if (!skel.fields.toolbox) skel.fields.toolbox = [];
						if (!skel.fields.procedures) skel.fields.procedures = [];
						commit("setSquelette", JSON.parse(JSON.stringify(skel)));
						resolve();
					} else {
						router.push({ name: "Squelettes" });
					}
				}, 4000);
			});
		},
		changePresentationName({ state }, payload) {
			state.current_presentation.fields.nom = payload.target.innerHTML;
		},
		savePresentation({ state, commit }, payload) {
			if (!payload || payload.mode != "silent") {
				commit("showLoadingApp");
			}
			return new Promise((resolve, reject) => {
				var slideFile = new Blob([JSON.stringify(state.current_presentation.fields.slides)], { type: "application/json" });
				let fileName = `${state.current_presentation.fields.nom}-date-${moment().locale("fr").format("dddd DD MMMM YYYY [a] HH[h]mm[et]ss[s]")}`;
				fileName = fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
				fileName = fileName.replace(/[^A-Z0-9]/gi, "_");
				let jsonFile = new File([slideFile], `${fileName}.json`);
				this.dispatch("uploadJson", jsonFile)
					.then((res) => {
						if (!payload || payload.mode != "silent") {
							let temp = [];

							base("presentations").find(state.current_presentation.id, function (err, record) {
								if (err) {
									console.error(err);
									return;
								}
								if (record.fields.versions) {
									state.current_presentation.fields.versions = record.fields.versions;
								}
								if (state.current_presentation.fields.versions) {
									temp = [...state.current_presentation.fields.versions];
								}

								let final_versions = [{ url: `${process.env.VUE_APP_MAUFFREY_API_URL}${res.data}` }];
								temp.forEach((el) => {
									final_versions.push({ url: el.url });
								});
								if (final_versions.length > 10) {
									final_versions.pop();
								}
								base("presentations").update(
									[
										{
											id: state.current_presentation.id,
											fields: {
												fichier_config: [
													{
														url: `${process.env.VUE_APP_MAUFFREY_API_URL}${res.data}`,
													},
												],
												nom_client: state.current_presentation.fields.nom_client,
												nom: state.current_presentation.fields.nom.replaceAll("/", "-"),
												versions: final_versions,
												app_version: app.version,
												langue: state.current_presentation.fields.langue,
												filiale: state.current_presentation.fields.filiale || [],
												barre_laterale: state.current_presentation.fields.barre_laterale || "",
											},
										},
									],
									function (err, records) {
										commit("showLoadingApp");
										if (err) {
											reject(err);
										}
										resolve();
										if (res.data != false) {
											setTimeout(() => {
												window.location.reload();
											}, 100);
										} else {
											Notify({
												type: "error",
												message: "Erreur",
												color: "#e30613",
												background: "#fff3f3",
												duration: 4000,
											});
										}
									}
								);
							});
						} else {
							base("presentations").update(
								[
									{
										id: state.current_presentation.id,
										fields: {
											fichier_config: [
												{
													url: `${process.env.VUE_APP_MAUFFREY_API_URL}${res.data}`,
												},
											],
											nom_client: state.current_presentation.fields.nom_client,
											nom: state.current_presentation.fields.nom.replaceAll("/", "-"),
											app_version: app.version,
											langue: state.current_presentation.fields.langue,
										},
									},
								],
								function (err, records) {
									if (err) {
										reject(err);
									}
									resolve();
									if (res.data != false) {
										Notify({
											type: "success",
											message: "Sauvegarde automatique effectuée",
											color: "#38cb89",
											background: "#e5f7ef",
											duration: 4000,
										});
									} else {
										Notify({
											type: "error",
											message: "Erreur",
											color: "#e30613",
											background: "#fff3f3",
											duration: 4000,
										});
									}
								}
							);
						}
					})
					.catch((err) => {
						if (!payload || payload.mode != "silent") {
							commit("showLoadingApp");
						}
						reject(err);
					});
			});
		},
		constructSommaire({ getters }) {
			return new Promise((resolve, reject) => {
				let presentation = getters.currentPresentation;
				let sommaireContent = [];
				presentation.slides.forEach((slide, slide_index) => {
					slide.composants.forEach((comp) => {
						if (comp.type === "text" && comp.useForSommaire) {
							sommaireContent.push({
								text: comp.content.replace("\n", ""),
								link: slide_index + 1,
							});
						}
					});
				});

				resolve(sommaireContent);
			});
		},
		defineMasters({ state }, payload) {
			return new Promise((resolve, reject) => {
				let pptx = payload.pptx;
				let presentation = payload.presentation;
				pptx.defineSlideMaster({
					title: "MASTER_SLIDE_WHITE",
					background: { fill: "FFFFFF" },
					margin: 0,
					objects: [
						{
							rect: {
								x: 0,
								y: 0,
								w: 0.625,
								h: 5.625,
								fill: {
									color: "e30613",
								},
							},
						},
						{
							text: {
								text: presentation.barre_laterale ? presentation.barre_laterale.toUpperCase() : "",
								options: {
									x: -2.5,
									y: 2.5,
									w: 5.625,
									h: 0.625,
									color: "FFFFFF",
									align: "center",
									valign: "middle",
									bold: false,
									fontFace: "Arial",
									fontSize: 8,
									italic: true,
									rotate: 270,
									isTextBox: true,
									margin: 20,
								},
							},
						},
						{
							text: {
								text: presentation.nom_client ? presentation.nom_client.toUpperCase() : "",
								options: {
									x: -2.5,
									y: 2.5,
									w: 5.625,
									h: 0.625,
									color: "FFFFFF",
									align: "right",
									valign: "middle",
									bold: true,
									fontSize: 8,
									fontFace: "Arial",
									italic: true,
									rotate: 270,
									isTextBox: true,
									margin: 20,
								},
							},
						},
					],
					slideNumber: { x: 0, y: 5.2, w: 0.625, color: "FFFFFF", align: "center", fontSize: 10, fontFace: "Arial" },
				});
				pptx.defineSlideMaster({
					title: "MASTER_SLIDE_CLEAN",
					background: { fill: "FFFFFF" },
					margin: 0,
					objects: [
						{
							rect: {
								x: 0,
								y: 0,
								w: 0.625,
								h: 5.625,
								fill: {
									color: "FFFFFF",
								},
							},
						},
						{
							text: {
								text: presentation.barre_laterale ? presentation.barre_laterale.toUpperCase() : "",
								options: {
									x: -2.5,
									y: 2.5,
									w: 5.625,
									h: 0.625,
									color: "000000",
									align: "center",
									valign: "middle",
									bold: false,
									fontFace: "Arial",
									fontSize: 8,
									italic: true,
									rotate: 270,
									isTextBox: true,
									margin: 20,
								},
							},
						},
						{
							text: {
								text: presentation.nom_client ? presentation.nom_client.toUpperCase() : "",
								options: {
									x: -2.5,
									y: 2.5,
									w: 5.625,
									h: 0.625,
									color: "000000",
									align: "right",
									valign: "middle",
									bold: true,
									fontSize: 8,
									fontFace: "Arial",
									italic: true,
									rotate: 270,
									isTextBox: true,
									margin: 20,
								},
							},
						},
					],
					slideNumber: { x: 0, y: 5.2, w: 0.625, color: "000000", align: "center", fontSize: 10, fontFace: "Arial" },
				});
				pptx.defineSlideMaster({
					title: "MASTER_SLIDE_BLACK",
					background: { fill: "FFFFFF" },
					margin: 0,
					objects: [
						{
							rect: {
								x: 0,
								y: 0,
								w: 0.625,
								h: 5.625,
								fill: {
									color: "000000",
								},
							},
						},
						{
							text: {
								text: presentation.barre_laterale ? presentation.barre_laterale.toUpperCase() : "",
								options: {
									x: -2.5,
									y: 2.5,
									w: 5.625,
									h: 0.625,
									color: "FFFFFF",
									align: "center",
									valign: "middle",
									bold: false,
									fontFace: "Arial",
									fontSize: 8,
									italic: true,
									rotate: 270,
									isTextBox: true,
									margin: 20,
								},
							},
						},
						{
							text: {
								text: presentation.nom_client ? presentation.nom_client.toUpperCase() : "",
								options: {
									x: -2.5,
									y: 2.5,
									w: 5.625,
									h: 0.625,
									color: "FFFFFF",
									align: "right",
									valign: "middle",
									bold: true,
									fontSize: 8,
									fontFace: "Arial",
									italic: true,
									rotate: 270,
									isTextBox: true,
									margin: 20,
								},
							},
						},
					],
					slideNumber: { x: 0, y: 5.2, w: 0.625, color: "FFFFFF", align: "center", fontSize: 10, fontFace: "Arial" },
				});
				state.modeles.forEach((modele) => {
					// je récupère les slides
				});
				resolve(pptx);
			});
		},
		exportPPT({ state, commit, dispatch, getters }) {
			state.generation_en_cours = true;
			dispatch("constructSommaire")
				.then((sommaire_final) => {
					let pptx = new pptxgen();
					let presentation = getters.currentPresentation;

					// Je récupère les globales
					let textes = state.globales.filter((el) => el.fields.type === "texte");
					let zones = state.zones;
					let filiale_choisie = presentation.filiale;
					let globales = [];
					if (filiale_choisie) {
						let zone = state.zones.find((el) => el.id === presentation.filiale[0]);
						let final = [];
						textes.forEach((el) => {
							if (el.fields.valeurs) {
								let valeurs = JSON.parse(el.fields.valeurs);
								let jeton_de_valeur = valeurs.filter((item) => item.zone === zone.fields.nom_zone);
								if (jeton_de_valeur) {
									if (jeton_de_valeur[0].valeur != "" || jeton_de_valeur[0].valeur != "X" || jeton_de_valeur[0].valeur != "➜") {
										el.fields.valeur_retournee = jeton_de_valeur[0].valeur;
									}
								}
							}
							globales.push(el);
						});
					} else {
						globales = textes;
					}

					pptx.layout = "LAYOUT_16x9";
					pptx.subject = state.current_presentation.fields.nom;
					pptx.author = state.user.fields.nom;
					pptx.company = "Groupe Mauffrey";
					pptx.theme = { headFontFace: "Arial" };
					pptx.theme = { bodyFontFace: "Arial" };

					// MASTER 1
					dispatch("defineMasters", { pptx, presentation }).then((res) => {
						pptx = res;
						presentation.slides.forEach((slide) => {
							let genSlide;
							let masterName = "MASTER_SLIDE_WHITE";
							if (!slide.barre_color) {
								slide.barre_color = "#e30613";
							}
							let barre_color = slide.barre_color.replace("#", "");
							if (barre_color === "000000") {
								masterName = "MASTER_SLIDE_BLACK";
							}
							if (barre_color === "FFFFFF") {
								masterName = "MASTER_SLIDE_CLEAN";
							}
							genSlide = pptx.addSlide({ masterName: masterName });
							genSlide.color = slide.color;
							genSlide.background = { fill: slide.fill.replace("#", "") };
							if (slide.composants) {
								slide.composants.forEach((el) => {
									if (el.type === "text") {
										let content_parse = el.content;
										let is_sommaire_component = false;
										let puces = [];
										// Je vérifie si c'est un sommaire
										if (content_parse.includes("[SOMMAIRE]") || content_parse.includes("[sommaire]")) {
											sommaire_final.forEach((item) => {
												puces.push({
													text: `${item.text.replace(/(<([^>]+)>)/gi, "").toUpperCase()}`,
													options: {
														color: el.color.replace("#", ""),
														bold: el.bold,
														italic: el.italic,
														fontSize: el.fontSize,
														indentLevel: 0,
														breakLine: false,
														underline: {
															style: "none",
														},
														hyperlink: {
															slide: item.link,
														},
														bullet: {
															type: "number",
															indent: 16,
														},
													},
												});
												puces.push({
													text: ` (page ${item.link})`,
													options: {
														color: el.color.replace("#", ""),
														bold: false,
														italic: el.italic,
														fontSize: 10,
														margin: 0.1,
														bullet: false,
													},
												});
											});
											is_sommaire_component = true;
										}
										// Je vérifie si c'est le nom du client
										if (content_parse.includes("[nom_client]")) {
											content_parse = content_parse.replace(/\[nom_client\]/g, presentation.nom_client);
										}
										// Je vérifie les shorcodes
										globales.forEach((globale) => {
											if (content_parse.includes(`[${globale.fields.code_court}]`)) {
												let regex = new RegExp(`\\[${globale.fields.code_court}\\]`, 'g');
												content_parse = content_parse.replace(regex, globale.fields.valeur_retournee);
											}
										});
										let options = {
											x: el.x / 96,
											y: el.y / 96,
											w: el.w / 96,
											h: el.h / 96,
											lineSpacing: el.fontSize * el.lineSpacing,
											color: el.color.replace("#", ""),
											align: "left",
											valign: el.valign,
											inset: 0,
											lang: "fr-FR",
											autofit: false,
											original_linespacing: el.lineSpacing,
											italic: el.italic,
											rotate: el.rotate,
											isTextBox: el.isTextBox,
											margin: el.margin / 2,
											fontSize: el.fontSize,
											fontFace: "Arial",
											underline: {
												style: "none",
											},
										};
										if (el.fill) {
											options.fill = {
												color: el.fill.replace("#", ""),
											};
										}
										if (el.hyperlink != undefined && el.hyperlink.url != "") {
											options.hyperlink = {
												url: encodeXml(el.hyperlink.url),
											};
										}

										if (el.isUpperCase) {
											content_parse = content_parse.toUpperCase();
										}
										if (is_sommaire_component) {
											genSlide.addText(puces, {
												x: el.x / 96,
												y: el.y / 96,
												w: el.w / 96,
												h: el.h / 96,
												valign: el.valign,
												margin: el.margin,
												lineSpacing: el.fontSize * el.lineSpacing,
												autofit: true,
												lang: "fr-FR",
												breakLine: false,
												fontFace: "Arial",
											});
										} else {
											// let other_options = {};
											options.css = `h1 {font-size: 18pt;padding-bottom: 5px;}
										h2 {font-size: 13pt;padding-bottom: 5px;}
										h3 {font-size: 11pt;padding-bottom: 5px;}
										em {font-style: italic;}
										strong {font-weight: bold;}
										u {text-decoration: underline;}
										blockquote {color: #e30613;font-size: 8;font-style: italic;font-weight: bold;text-align: center;}
										.ql-align-center {text-align: center;}
										.ql-align-right {text-align: right;}
										a {color: inherit;}`;
											content_parse = content_parse.replaceAll("&#xFEFF", "");
											content_parse = content_parse.replaceAll('<span class="ql-cursor"></span>', "");
											content_parse = content_parse.replaceAll("<p><br></p>", "<br>");
											content_parse = content_parse.replaceAll('<p class="ql-align-center"><br></p>', "<br>");
											content_parse = content_parse.replaceAll('<p class="ql-align-right"><br></p>', "<br>");
											const items = html2pptxgenjs.htmlToPptxText(content_parse, options);
											genSlide.addText(items, {
												x: options.x,
												y: options.y,
												w: options.w,
												h: options.h,
												lineSpacing: options.lineSpacing,
												valign: options.valign,
												fontSize: options.fontSize,
												fontFace: options.fontFace,
												lang: "fr-FR",
												isTextBox: options.isTextBox,
												autofit: false,
												color: options.original_color,
											});
										}
									} else if (el.type === "liste") {
										let options = {
											x: el.x / 96,
											y: el.y / 96,
											w: el.w / 96,
											h: el.h / 96,
											lineSpacing: el.fontSize * el.lineSpacing,
											valign: el.valign,
											margin: el.margin / 2,
											fontFace: "Arial",
										};
										if (el.fill) {
											options.fill = {
												color: el.fill.replace("#", ""),
											};
										}
										let puces = [];
										el.puces.forEach((item) => {
											puces.push({
												text: "> ",
												options: {
													color: "e30613",
													bold: true,
													bullet: false,
													breakLine: false,
													fontSize: el.fontSize,
													indentLevel: 0,
												},
											});
											puces.push({
												text: `${item.text} \n`,
												options: {
													//align: el.align,
													color: el.color.replace("#", ""),
													breakline: true,
													bold: el.bold,
													italic: el.italic,
													fontSize: el.fontSize,
													indentLevel: 1,
												},
											});
										});
										genSlide.addText(puces, options);
									} else if (el.type === "shape") {
										let forme;
										let options = {};
										if (!el.line.color) {
											el.line.color = "#FFFFFF";
										}
										if (!el.line.width) {
											el.line.width = 0;
										}
										if (el.line.width === 0) {
											options = {
												x: el.x / 96,
												y: el.y / 96,
												w: el.w / 96,
												h: el.h / 96,
												rectRadius: el.rectRadius / 96,
												rotate: el.rotate,
												fill: {
													color: el.fill.color.replace("#", ""),
													transparency: el.fill.transparency,
												},
											};
										} else {
											options = {
												x: el.x / 96,
												y: el.y / 96,
												w: el.w / 96,
												h: el.h / 96,
												rectRadius: el.rectRadius / 96,
												rotate: el.rotate,
												line: {
													color: el.line.color.replace("#", ""),
													dashType: el.line.dashType,
													width: parseInt(el.line.width),
												},
												fill: {
													color: el.fill.color.replace("#", ""),
													transparency: el.fill.transparency,
												},
											};
										}
										if (options.rectRadius === 0) {
											forme = pptx.shapes.RECTANGLE;
										} else {
											forme = pptx.shapes.ROUNDED_RECTANGLE;
										}
										if (el.shadow && el.shadow.type != "none") {
											options.shadow = {
												angle: el.shadow.angle,
												blur: el.shadow.blur,
												color: el.shadow.color,
												offset: el.shadow.offset,
												opacity: el.shadow.opacity,
												type: el.shadow.type,
											};
											options.shadow.color = options.shadow.color.replace("#", "");
										}
										genSlide.addShape(forme, options);
									} else if (el.type === "image") {
										let options = {};

										if (el.sizing.type === "crop") {
											// On previent les gros bugs
											options = {
												x: el.x / 96,
												y: el.y / 96,
												w: (el.w * (el.sizing.zoom / 100)) / 96,
												h: (el.h * (el.sizing.zoom / 100)) / 96,
												path: el.path,
												rounding: el.rounding,
												transparency: el.transparency || 0,
												sizing: {
													type: el.sizing.type,
													w: el.sizing.w / 96,
													h: el.sizing.h / 96,
													x: (el.sizing.decalage_x / 96) * -1,
													y: (el.sizing.decalage_y / 96) * -1,
												},
											};
										} else {
											options = {
												x: el.x / 96,
												y: el.y / 96,
												w: el.w / 96,
												h: el.h / 96,
												path: el.path,
												rounding: el.rounding,
												transparency: el.transparency || 0,
												sizing: {
													type: el.sizing.type,
													w: el.sizing.w / 96,
													h: el.sizing.h / 96,
												},
											};
										}
										if (el.hyperlink != undefined && el.hyperlink.url != "") {
											options.hyperlink = { url: encodeXml(el.hyperlink.url) };
										}
										genSlide.addImage(options);
									} else if (el.type === "tableau") {
										let rownumber = el.rows;
										let colnumber = el.cols;
										let temp_bold = false;
										let temp_fill = "FBFCFC";
										let options = {
											x: el.x / 96,
											y: el.y / 96,
											w: el.w / 96,
											h: el.h / 96,
											valign: el.valign,
											fontSize: el.fontSize,
											align: el.align,
											fill: "FBFCFC",
											bold: el.bold,
											italic: el.italic,
											margin: 6,
											border: { pt: 4, color: el.borderColor?.replace("#", "") || 'FFFFFF'},
										};
										if(el.content) {
											let duplicate_table = JSON.parse(JSON.stringify(el.content));
											duplicate_table.forEach((row, rindex) => {
												row.forEach((cell, cindex) => {
													if (
														(rindex === 0 && el.first_row_heading) ||
														(rindex === el.rows - 1 && el.last_row_heading) ||
														(cindex === 0 && el.first_colonne_heading) ||
														(cindex === el.cols - 1 && el.last_colonne_heading)
													) {
														temp_bold = true;
													} else {
														temp_bold = false;
													}
													if (cell.text === "" || cell.text === undefined || cell.text === null) {
														temp_fill = "FFFFFF";
													} else {
														globales.forEach((globale) => {
															let regex = new RegExp(`\\[${globale.fields.code_court}\\]`, 'g');
															cell.text = cell.text.replace(regex, globale.fields.valeur_retournee);
														});
														temp_fill = "FBFCFC";
													}
													cell.options = {
														fill: { color: temp_fill },
														bold: temp_bold,
													};
												});
											});
											genSlide.addTable(duplicate_table, options);
										}
										
									} else if (el.type === "graph") {
										let options = {
											x: el.x / 96,
											y: el.y / 96,
											w: el.w / 96,
											h: el.h / 96,
											chartColors: el.chartColors,
											showValue: false,
											showPercent: true,
											dataBorder: { pt: "1", color: "FFFFFF" },
											showLegend: el.showLegend,
											dataLabelColor: "FFFFFF",
											dataLabelFontSize: 12,
											dataLabelFontFace: "Arial",
											legendFontFace: "Arial",
											holeSize: 50,
										};
										if (el.type_chart === "PIE") {
											genSlide.addChart(pptx.charts.DOUGHNUT, el.data, options);
										} else if (el.type_chart === "AREA") {
											genSlide.addChart(pptx.charts.AREA, el.data, options);
										} else if (el.type_chart === "BAR") {
											genSlide.addChart(pptx.charts.BAR, el.data, options);
										} else if (el.type_chart === "LINE") {
											genSlide.addChart(pptx.charts.LINE, el.data, options);
										}
									} else if (el.type === "online") {
										let options = {
											x: el.x / 96,
											y: el.y / 96,
											w: el.w / 96,
											h: el.h / 96,
											link: el.link,
											type: el.type,
										};
										genSlide.addMedia(options);
									}
								});
							}
						});

						var zip = new JSZip();
						var zip_modele = zip.folder("Modeles");

						fetch("https://mediabase.lezardscreation.fr/wp-content/uploads/theme_mauffrey_powerpoint.potx")
							.then((res) => {
								if (!res.ok) {
									throw new Error(`erreur HTTP! statut: ${res.status}`);
								}
								return res.arrayBuffer();
							})
							.then((ab) => {
								zip_modele.file("Modele.potx", ab, { binary: true });
								pptx.write({ compression: true, outputType: "base64" }).then((data) => {
									zip.file(`${presentation.nom}.pptx`, data, { base64: true });
									zip.generateAsync({ type: "blob" }).then(function (blob) {
										saveAs(blob, `${presentation.nom}.zip`);
										state.generation_en_cours = false;
										Notify({
											message: `Votre draft a correctement été exporté et enregistré sur votre ordinateur.`,
											background: "#f4f8ff",
											color: "#377dff",
											duration: 4000,
										});
									});
								});
							})
							.catch((err) => {
								state.generation_en_cours = false;
								Notify({
									message: `Une erreur est survenue : ${err}`,
									duration: 4000,
								});
							});

						// pptx.writeFile({ fileName: `${presentation.nom}.pptx`, compression: true }).then((filename) => {
						// 	state.generation_en_cours = false;
						// 	Notify({
						// 		message: `${filename} a correctement été exportée et enregistrée sur votre ordinateur.`,
						// 		background: "#f4f8ff",
						// 		color: "#377dff",
						// 		duration: 4000,
						// 	});
						// });
					});
				})
				.catch((err) => {
					console.log(err);
					state.generation_en_cours = false;
					Notify({
						message: `Une erreur est survenue pendant la génération du sommaire`,
						background: "#fff3f3",
						color: "#e30613",
						duration: 4000,
					});
				});
		},
		modifyStatut({ state }, payload) {
			let statut, id;
			if (payload) {
				statut = payload.statut;
				id = payload.id;
			} else {
				statut = state.current_presentation.fields.statut;
				id = state.current_presentation.id;
			}
			return new Promise((resolve, reject) => {
				base("presentations").update(
					[
						{
							id: id,
							fields: {
								statut: statut,
							},
						},
					],
					function (err, records) {
						if (err) {
							reject(err);
							Dialog.alert({
								title: "Une erreur est survenue",
								message: err.message,
							});
						}
						resolve();
						Notify({
							type: "success",
							message: "Le statut a bien été modifié. Merci.",
							color: "#38cb89",
							background: "#e5f7ef",
							duration: 4000,
						});
					}
				);
			});
		},
		updateSharingPeople({ state }, payload) {
			return new Promise((resolve, reject) => {
				state.current_presentation.fields.partagee_avec = JSON.stringify(payload);
				let access_tab = [];
				base("presentations").update(
					[
						{
							id: state.current_presentation.id,
							fields: {
								partagee_avec: state.current_presentation.fields.partagee_avec,
							},
						},
					],
					function (err, records) {
						if (err) {
							reject(err);
						}
						let temp_partage = [];
						payload.forEach((el) => {
							temp_partage.push(el.text);
						});
						state.users.forEach((el) => {
							if (temp_partage.includes(el.fields.email)) {
								access_tab.push(el.id);
							}
						});
						base("presentations").update(
							[
								{
									id: state.current_presentation.id,
									fields: {
										can_access: access_tab,
									},
								},
							],
							function (err, records) {
								if (err) {
									reject(err);
								}
								resolve();
								Notify({
									type: "success",
									message: "Votre présentation est maintenant partagée avec toutes les personnes renseignées. Merci.",
									color: "#38cb89",
									background: "#e5f7ef",
									duration: 4000,
								});
							}
						);
					}
				);
			});
		},
		showShare({ state }) {
			state.show_overlay_share = !state.show_overlay_share;
		},
		showComponentSubMenu({ state }, payload) {
			payload.preventDefault();
			state.show_composant_context.state = true;
			state.show_composant_context.x = payload.clientX - 40;
			state.show_composant_context.y = payload.clientY - 40;
		},
		showSlideSubMenu({ state }, payload) {
			payload.preventDefault();
			state.show_slide_context.state = true;
			state.show_slide_context.x = payload.clientX - 40;
			state.show_slide_context.y = payload.clientY - 40;
		},
		hideComposantSubMenu({ state }) {
			state.show_composant_context.state = false;
		},
		hideSlideSubMenu({ state }) {
			state.show_slide_context.state = false;
		},
		cloneComposant({ state, commit }) {
			let new_composant = Object.assign({}, state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index]);
			new_composant.y += 50;
			new_composant = JSON.stringify(new_composant);
			commit("addComposant", JSON.parse(new_composant));
		},
		copyComposant({ state, commit }) {
			let new_composant = Object.assign({}, state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index]);
			new_composant = JSON.stringify(new_composant);
			commit("addPressPapier", JSON.parse(new_composant));
		},
		createDraft({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				base("presentations").create(
					[
						{
							fields: {
								nom: payload.nom.replaceAll("/", "-"),
								auteur: [state.user.id],
								statut: "brouillon",
								nom_client: payload.client,
								app_version: app.version,
								langue: payload.langue,
								can_access: payload.collab,
							},
						},
					],
					(err, records) => {
						if (err) {
							reject(err);
						}
						commit("addPresentation", records[0]);
						router.push({ name: "Présentation", params: { id: records[0].id } });
						resolve();
					}
				);
			});
		},
		createNewPresentation({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				if (!payload) {
					payload = "";
				}
				base("presentations").create(
					[
						{
							fields: {
								nom: `Ma présentation du ${moment().format("DD/MM/YYYY à hh:mm")}`,
								auteur: [state.user.id],
								statut: "brouillon",
								nom_client: "Mon client",
								app_version: app.version,
								fichier_config: [
									{
										url: payload,
									},
								],
							},
						},
					],
					(err, records) => {
						if (err) {
							reject(err);
						}
						let record = records[0];
						commit("showCreate");
						commit("addPresentation", record);
						resolve(record.id);
					}
				);
			});
		},
		saveModeleFromEditor({ state, commit }) {
			state.savemodeleeditor = true;
			return new Promise((resolve, reject) => {
				this.dispatch("getFullSlideModelePreview").then(() => {
					let preview = { preview: state.slideModelePreview };
					let slideFile = new Blob([JSON.stringify(state.current_presentation.fields.slides)], { type: "application/json" });
					let previewFile = new Blob([JSON.stringify(preview)], { type: "application/json" });
					let jsonFile = new File([slideFile], `${state.current_presentation.id}-${Date.now()}.json`);
					let jsonFile2 = new File([previewFile], `preview-${slugify(state.current_presentation.fields.nom)}-${Date.now()}.json`);
					this.dispatch("uploadJson", jsonFile).then((res) => {
						this.dispatch("uploadJson", jsonFile2)
							.then((res2) => {
								let fields = {};
								fields.nom = state.current_presentation.fields.nom;
								fields.app_version = app.version;
								fields.prive = state.current_presentation.fields.prive;
								fields.tags = state.current_presentation.fields.tags;
								fields.filiale = state.current_presentation.fields.filiale || [];
								fields.fichier_de_config = [
									{
										url: `${process.env.VUE_APP_MAUFFREY_API_URL}${res.data}`,
									},
								];
								fields.cree_par = [state.user.id];
								fields.type = state.current_presentation.fields.type;
								fields.langue = state.current_presentation.fields.langue;
								fields.preview = [
									{
										url: `${process.env.VUE_APP_MAUFFREY_API_URL}${res2.data}`,
									},
								];
								if (state.current_presentation.fields.procedures.length > 0) {
									if (state.current_presentation.fields.procedures[0] != null) {
										fields.procedures = state.current_presentation.fields.procedures;
									} else {
										fields.procedures = [];
									}
								}
								if (state.current_presentation.fields.toolbox.length > 0) {
									if (state.current_presentation.fields.toolbox[0] != null) {
										fields.toolbox = state.current_presentation.fields.toolbox;
									} else {
										fields.toolbox = [];
									}
								}
								if (state.current_presentation.fields.materiel.length > 0) {
									if (state.current_presentation.fields.materiel[0] != null) {
										fields.materiel = state.current_presentation.fields.materiel;
									} else {
										fields.materiel = [];
									}
								}
								if (state.current_presentation.fields.digitalisation.length > 0) {
									if (state.current_presentation.fields.digitalisation[0] != null) {
										fields.digitalisation = state.current_presentation.fields.digitalisation;
									} else {
										fields.digitalisation = [];
									}
								}
								if (state.current_presentation.fields.formation.length > 0) {
									if (state.current_presentation.fields.formation[0] != null) {
										fields.formation = state.current_presentation.fields.formation;
									} else {
										fields.formation = [];
									}
								}
								if (state.current_presentation.fields.qualite.length > 0) {
									if (state.current_presentation.fields.qualite[0] != null) {
										fields.qualite = state.current_presentation.fields.qualite;
									} else {
										fields.qualite = [];
									}
								}
								if (state.current_presentation.fields.geographie.length > 0) {
									if (state.current_presentation.fields.geographie[0] != null) {
										fields.geographie = state.current_presentation.fields.geographie;
									} else {
										fields.geographie = [];
									}
								}
								if (state.current_presentation.fields.groupe.length > 0) {
									if (state.current_presentation.fields.groupe[0] != null) {
										fields.groupe = state.current_presentation.fields.groupe;
									} else {
										fields.groupe = [];
									}
								}
								if (state.current_presentation.fields.filieres.length > 0) {
									if (state.current_presentation.fields.filieres[0] != null) {
										fields.filieres = state.current_presentation.fields.filieres;
									} else {
										fields.filieres = [];
									}
								}
								if (state.current_presentation.fields.prestations.length > 0) {
									if (state.current_presentation.fields.prestations[0] != null) {
										fields.prestations = state.current_presentation.fields.prestations;
									} else {
										fields.prestations = [];
									}
								}
								base("modeles").update(
									[
										{
											id: state.current_presentation.id,
											fields: fields,
										},
									],
									function (err, records) {
										if (err) {
											reject(err);
										}
										resolve(records);
										state.savemodeleeditor = false;
										Notify({
											type: "success",
											message: "Félicitations! Votre modèle a bien été modifié. Rechargez l'application pour l'utiliser.",
											color: "#38cb89",
											background: "#e5f7ef",
											duration: 4000,
										});
										setTimeout(() => {
											window.location.reload();
										}, 10);
									}
								);
							})
							.catch((err) => {
								state.savemodeleeditor = false;
								reject(err);
							});
					});
				});
			});
		},
		saveModeleToAirtable({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				this.dispatch("getFullSlideModelePreview", payload).then(() => {
					let preview = { preview: state.slideModelePreview };
					let range = payload.fin - payload.debut + 1;
					let slideFile;
					if (payload.perimetre === "une_partie" && payload.type === "groupe_slides" && state.current_presentation.fields.slides.length > range) {
						let copy = state.current_presentation.fields.slides.slice(payload.debut - 1, payload.fin);
						slideFile = new Blob([JSON.stringify(copy)], { type: "application/json" });
					} else {
						slideFile = new Blob([JSON.stringify(state.current_presentation.fields.slides)], { type: "application/json" });
					}
					let previewFile = new Blob([JSON.stringify(preview)], { type: "application/json" });
					let jsonFile = new File([slideFile], `${state.current_presentation.id}-${Date.now()}.json`);
					let jsonFile2 = new File([previewFile], `preview-${payload.nom}-${Date.now()}.json`);
					this.dispatch("uploadJson", jsonFile).then((res) => {
						this.dispatch("uploadJson", jsonFile2)
							.then((res2) => {
								let fields = {};
								fields.nom = payload.nom;
								fields.app_version = app.version;
								fields.tags = payload.tags;
								fields.fichier_de_config = [
									{
										url: `${process.env.VUE_APP_MAUFFREY_API_URL}${res.data}`,
									},
								];
								fields.cree_par = [state.user.id];
								fields.type = payload.type;
								fields.langue = payload.langue;
								fields.preview = [
									{
										url: `${process.env.VUE_APP_MAUFFREY_API_URL}${res2.data}`,
									},
								];
								if (payload.procedures[0] != "") {
									fields.procedures = payload.procedures;
								}
								if (payload.toolbox[0] != "") {
									fields.toolbox = payload.toolbox;
								}
								if (payload.materiel[0] != "") {
									fields.materiel = payload.materiel;
								}
								if (payload.digitalisation[0] != "") {
									fields.digitalisation = payload.digitalisation;
								}
								if (payload.formation[0] != "") {
									fields.formation = payload.formation;
								}
								if (payload.qualite[0] != "") {
									fields.qualite = payload.qualite;
								}
								if (payload.geographie[0] != "") {
									fields.geographie = payload.geographie;
								}
								if (payload.groupe[0] != "") {
									fields.groupe = payload.groupe;
								}
								if (payload.filieres[0] != "") {
									fields.filieres = payload.filieres;
								}
								if (payload.prestations[0] != "") {
									fields.prestations = payload.prestations;
								}
								if (payload.replace && payload.replace_id) {
									base("modeles").update(
										[
											{
												id: payload.replace_id,
												fields: fields,
											},
										],
										function (err, records) {
											if (err) {
												reject(err);
											}
											resolve(records);
											Notify({
												type: "success",
												message: "Félicitations! Votre modèle a bien été modifié. Rechargez l'application pour l'utiliser.",
												color: "#38cb89",
												background: "#e5f7ef",
												duration: 4000,
											});
											setTimeout(() => {
												window.location.reload();
											}, 500);
										}
									);
								} else {
									base("modeles").create(
										[
											{
												fields: fields,
											},
										],
										(err, records) => {
											if (err) {
												reject(err);
											}
											resolve(records);
											records.forEach((record) => {
												record.fields.preview_url = state.slideModelePreview;
												commit("addModele", record);
											});
										}
									);
								}
							})
							.catch((err) => {
								reject(err);
							});
					});
				});
			});
		},
		createEmptyModele({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				base("modeles").create(
					[
						{
							fields: {
								nom: payload.new_modele_name,
								type: payload.new_modele_type,
								langue: payload.new_modele_langue,
								tags: JSON.stringify([]),
								cree_par: [state.user.id],
								app_version: app.version,
								prive: payload.prive,
								useCounter: 0,
							},
						},
					],
					(err, records) => {
						if (err) {
							reject(err);
						}
						records.forEach((record) => {
							commit("addModele", record);
							router.push({ name: "Modèle", params: { id: record.id } });
						});
					}
				);
			});
		},
		createEmptySquelette({ state, commit, dispatch }, payload) {
			return new Promise((resolve, reject) => {
				base("squelettes").create(
					[
						{
							fields: {
								nom: payload.new_modele_name,
								tags: payload.tags,
								author: [state.user.id],
								status: state.user.fields.role.includes("administrateur") ? "brouillon" : "prive",
								app_version: app.version,
								langue: payload.langue,
							},
						},
					],
					(err, records) => {
						if (err) {
							reject(err);
						}
						records.forEach((record) => {
							commit("addSquelette", record);
							router.push({ name: "Squelette", params: { id: record.id } });
						});
					}
				);
			});
		},
		duplicateSlide({ state, commit }, payload) {
			let slide_selected = document.querySelector(".slide.selected");
			let composants_to_duplicate = JSON.stringify(state.current_presentation.fields.slides[state.current_diapositive].composants);
			let new_slide = {
				fill: "#FFFFFF",
				color: "#000000",
				path: null,
				name: `Copie de ${state.current_presentation.fields.slides[state.current_diapositive].name}`,
				composants: JSON.parse(composants_to_duplicate),
				order: parseInt(slide_selected.dataset.order) + 1,
				preview_image: null,
			};
			for (var i = parseInt(slide_selected.dataset.slide); i < state.current_presentation.fields.slides.length; i++) {
				state.current_presentation.fields.slides[i].order += 1;
			}
			commit("addSlide", new_slide);
		},
		exportSlideToModele({ commit, dispatch }) {
			commit("showSlideCreate");
			dispatch("getSlideModelePreview");
		},
		getSlideModelePreview({ state }) {
			return new Promise((resolve, reject) => {
				let slide_selected = document.querySelector(".slide.selected");
				slide_selected.classList.add("previewmodebis");
				htmlToImage
					.toJpeg(slide_selected, {
						quality: 0.2,
					})
					.then((data) => {
						state.slideModelePreview = data;
						slide_selected.classList.remove("previewmodebis");
						resolve();
					});
			});
		},
		reallyCreateModele({ state, commit }, payload) {
			commit("showLoadingApp");
			return new Promise((resolve, reject) => {
				let preview = { preview: state.slideModelePreview };
				let slideFile = new Blob([JSON.stringify(state.current_presentation.fields.slides[state.current_diapositive])], { type: "application/json" });
				let previewFile = new Blob([JSON.stringify(preview)], { type: "application/json" });
				let jsonFile = new File([slideFile], `${payload.nom}-${Date.now()}.json`);
				let jsonFile2 = new File([previewFile], `preview-${payload.nom}-${Date.now()}.json`);
				this.dispatch("uploadJson", jsonFile)
					.then((res) => {
						this.dispatch("uploadJson", jsonFile2)
							.then((res2) => {
								let fields = {};
								fields.nom = payload.nom;
								fields.tags = payload.tags;
								fields.app_version = app.version;
								fields.fichier_de_config = [
									{
										url: `${process.env.VUE_APP_MAUFFREY_API_URL}${res.data}`,
									},
								];
								fields.cree_par = [state.user.id];
								fields.type = payload.type;
								fields.preview = [
									{
										url: `${process.env.VUE_APP_MAUFFREY_API_URL}${res2.data}`,
									},
								];
								if (payload.procedures[0] != "") {
									fields.procedures = payload.procedures;
								}
								if (payload.toolbox[0] != "") {
									fields.toolbox = payload.toolbox;
								}
								if (payload.materiel[0] != "") {
									fields.materiel = payload.materiel;
								}
								if (payload.digitalisation[0] != "") {
									fields.digitalisation = payload.digitalisation;
								}
								if (payload.formation[0] != "") {
									fields.formation = payload.formation;
								}
								if (payload.qualite[0] != "") {
									fields.qualite = payload.qualite;
								}
								if (payload.geographie[0] != "") {
									fields.geographie = payload.geographie;
								}
								if (payload.groupe[0] != "") {
									fields.groupe = payload.groupe;
								}
								if (payload.filieres[0] != "") {
									fields.filieres = payload.filieres;
								}
								if (payload.prestations[0] != "") {
									fields.prestations = payload.prestations;
								}
								if (payload.replace && payload.replace_id) {
									base("modeles").update(
										[
											{
												id: payload.replace_id,
												fields: fields,
											},
										],
										function (err, records) {
											records[0].fields.preview_url = state.slideModelePreview;
											if (err) {
												Notify({
													type: "error",
													message: "Erreur",
													color: "#38cb89",
													background: "#e5f7ef",
													duration: 4000,
												});
												return;
											}
											resolve();
											Notify({
												type: "success",
												message: "Félicitations! Votre modèle a bien été modifié. Rechargez l'application pour l'utiliser.",
												color: "#38cb89",
												background: "#e5f7ef",
												duration: 4000,
											});
											commit("showLoadingApp");
											commit("showSlideCreate");
										}
									);
								} else {
									base("modeles").create(
										[
											{
												fields: fields,
											},
										],
										(err, records) => {
											records[0].fields.preview_url = state.slideModelePreview;
											commit("addModele", records[0]);
											if (err) {
												Notify({
													type: "error",
													message: "Erreur",
													color: "#38cb89",
													background: "#e5f7ef",
													duration: 4000,
												});
												return;
											}
											resolve();
											Notify({
												type: "success",
												message: "Félicitations! Votre modèle a bien été enregistré. Il est déjà disponible dans votre bibliothèque",
												color: "#38cb89",
												background: "#e5f7ef",
												duration: 4000,
											});
											commit("showLoadingApp");
											commit("showSlideCreate");
										}
									);
								}
							})
							.catch((err2) => {
								commit("showLoadingApp");
								Notify({
									type: "error",
									message: "Erreur",
									color: "#38cb89",
									background: "#e5f7ef",
									duration: 4000,
								});
								reject(err2);
							});
					})
					.catch((err) => {
						commit("showLoadingApp");
						Notify({
							type: "error",
							message: "Erreur",
							color: "#38cb89",
							background: "#e5f7ef",
							duration: 4000,
						});
						reject(err);
					});
			});
		},
		positionForNewSlide: (state) => {
			return state.positionForNewSlide;
		},
		updateSlideBG({ state, dispatch }, payload) {
			this.dispatch("addToHistorique").then(() => {
				dispatch("uploadImage", {
					file: payload.file,
				})
					.then((res) => {
						state.current_presentation.fields.slides[state.current_diapositive].path = res.data;
					})
					.catch((err) => {
						alert(err);
						return;
					});
			});
		},
		removeSlideBg({ state }) {
			this.dispatch("addToHistorique").then(() => {
				state.current_presentation.fields.slides[state.current_diapositive].path = null;
			});
		},
		getFullSlideModelePreview({ state }, payload) {
			return new Promise((resolve, reject) => {
				let slides = document.querySelectorAll(".slide");
				let slide_selected = slides[0];
				if (payload) {
					if (payload.perimetre === "une_partie" && payload.type === "groupe_slides" && payload.debut) {
						slide_selected = slides[payload.debut - 1];
					}
				}
				slide_selected.classList.add("previewmode");
				let older_margin = slide_selected.style.margin;
				let older_padding = slide_selected.style.padding;
				slide_selected.style.margin = 0;
				slide_selected.style.padding = 0;
				html2canvas(slide_selected, {
					imageTimeout: 30000,
					width: 960,
					height: 540,
					scrollX: 0,
					scale: 1,
					scrollY: -window.scrollY,
					allowTaint: false,
					useCORS: true,
					logging: false,
				}).then((canvas) => {
					state.slideModelePreview = canvas.toDataURL("image/jpeg");
					slide_selected.style.margin = older_margin;
					slide_selected.style.padding = older_padding;
					slide_selected.classList.remove("previewmode");
					resolve();
				}).catch(err => {
					alert(err);
				})
			});
		},
		useAsDynamicImage({ commit, state }, payload) {
			return new Promise((resolve, reject) => {
				if (state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index]) {
					commit("changeImageDynamic", {
						dynamic_code: payload.dynamic_code,
						is_dynamic: payload.is_dynamic,
					});
					resolve();
				} else {
					reject("Aucun composant actif");
				}
			});
		},
		cleanMultipleEdition({ commit, state }, payload) {
			if (payload) {
				base("presentations").update(
					[
						{
							id: payload,
							fields: {
								en_cours_de_modif: false,
								modifie_par: [],
							},
						},
					],
					function (err, records) {
						//commit("refreshPresentation");
					}
				);
			}
		},
		blockMultipleEdition({ commit, state }) {
			if (state.current_presentation.fields.can_access && state.current_presentation.fields.can_access.length > 0) {
				base("presentations").update(
					[
						{
							id: state.current_presentation.id,
							fields: {
								en_cours_de_modif: true,
								modifie_par: [state.user.id],
							},
						},
					],
					function (err, records) {
						return;
					}
				);
			}
		},
		getSessionId({ state }) {
			return new Promise((resolve, reject) => {
				let maintenant = new moment();
				let duration = moment.duration(maintenant.diff(state.lastlogin));
				if (state.session_id.length === 0 || airtable_a === "wrong" || airtable_p === "wrong" || duration.as("hours") >= 6) {
					axios({
						url: `${process.env.VUE_APP_MEDIABASE_UPLOAD_API}login`,
						method: "POST",
						params: {
							username: process.env.VUE_APP_WP_DEPOT_LOGIN,
							password: process.env.VUE_APP_WP_DEPOT_PASSWORD,
						},
					})
						.then((res2) => {
							state.session_id = res2.data.result[0].session_id;
							state.lastlogin = new moment();
							if (airtable_a === "wrong" && airtable_p === "wrong") {
								const data = new FormData();
								data.append("service", "getstuff");
								data.append("SESSION_ID", state.session_id);
								resolve();
								// axios.post(`${process.env.VUE_APP_MEDIABASE_UPLOAD_API}custom_service/`, data)
								// 	.then((res3) => {
								// 		let aaaa = res3.data.result.a;
								// 		let bbbb = res3.data.result.b;
								// 		let bytesa = CryptoJS.AES.decrypt(aaaa, process.env.VUE_APP_SECRET);
								// 		let bytesb = CryptoJS.AES.decrypt(bbbb, process.env.VUE_APP_SECRET);
								// 		airtable_a = bytesa.toString(CryptoJS.enc.Utf8);
								// 		airtable_p = bytesb.toString(CryptoJS.enc.Utf8);
								// 		base = new Airtable({ apiKey: "patn4sDJaeq85aXie.d2b205090ef7ba3bd7c639b01c5944b1cbf3fc5bf12f913baa35288725c49df7" }).base("appes8o9Z4BEjtDHA");
								// 		setTimeout(() => {
								// 			resolve();
								// 		}, 1000);
								// 	})
								// 	.catch((err) => {
								// 		//throw new Error("Erreur récupération API KEY");
								// 		reject(err);
								// 	});
							} else {
								resolve();
							}
						})
						.catch((err) => {
							//throw new Error("Erreur de connexion");
							reject(err);
						});
				} else {
					resolve();
				}
			});
		},
		openStaticGallery({ commit, dispatch, state }) {
			return new Promise((resolve, reject) => {
				dispatch("getSessionId").then(() => {
					state.medias_galerie = [];
					const data = new FormData();
					data.append("service", "getAllImages");
					data.append("SESSION_ID", state.session_id);
					axios.post(`${process.env.VUE_APP_MEDIABASE_UPLOAD_API}custom_service/`, data)
						.then((res) => {
							commit("showChooseImage");
							res.data.result.forEach((el) => {
								state.medias_galerie.push(el);
							});
							resolve();
						})
						.catch((err) => {
							reject(err);
						});
				});
			});
		},
		updateCropPosition({ state, dispatch }, payload) {
			return new Promise((resolve, reject) => {
				dispatch("addToHistorique").then(() => {
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.zoom = payload.zoom;
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.decalage_x = payload.decalage_x;
					state.current_presentation.fields.slides[payload.slide].composants[payload.composant].sizing.decalage_y = payload.decalage_y;
					resolve();
				});
			});
		},
		switchPresentationVersion({ commit, state }, payload) {
			return new Promise((resolve, reject) => {
				commit("showLoadingApp");
				// Je récupére la présentation actuelle
				let actual_config = state.current_presentation.fields.fichier_config[0];
				if (!actual_config) {
					reject("Impossible car aucune save actuelle n'existe");
					commit("showLoadingApp");
				}
				// Je récupère la présentation souhaitée
				let previous_config = state.current_presentation.fields.versions[payload.index].url;
				if (!previous_config) {
					reject("Impossible car aucune versions n'existe");
				}
				// J'insére la présentation souhaitée dans le bon field
				state.current_presentation.fields.versions.splice(payload.index, 1);
				state.current_presentation.fields.versions.push(actual_config);

				let tableau_revisite = [];
				state.current_presentation.fields.versions.forEach((el) => {
					tableau_revisite.push({
						url: el.url,
					});
				});
				// Je remet la présentation actuelle dans les versions (à la fin)
				// J'enregistre le tout
				base("presentations").update(
					[
						{
							id: state.current_presentation.id,
							fields: {
								fichier_config: [
									{
										url: previous_config,
									},
								],
								versions: tableau_revisite,
							},
						},
					],
					function (err, records) {
						commit("showLoadingApp");
						if (err) {
							Notify({
								type: "error",
								message: "Erreur",
								color: "#e30613",
								background: "#fff3f3",
								duration: 5000,
							});
							reject(err);
						}
						Notify({
							type: "success",
							message: "Version restaurée. La page va être rechargée dans quelques secondes.",
							color: "#38cb89",
							background: "#e5f7ef",
							duration: 5000,
						});
						setTimeout(() => {
							window.location.reload();
						}, 5000);
						resolve();
					}
				);
				// Je recharge la page
			});
		},
		deletePresentation({ state }, payload) {
			return new Promise((resolve, reject) => {
				Dialog.confirm({
					title: "Action irréversible",
					message: "Voulez-vous vraiment supprimer cette présentation ? Toute suppression est définitive, votre présentation ne pourra pas être récupérée.",
					confirmButtonText: "Oui",
					cancelButtonText: "Annuler",
				})
					.then(() => {
						let found = state.presentations.findIndex((el) => el.id === payload);
						state.presentations.splice(found, 1);
						base("presentations").destroy([payload], (err, deletedRecords) => {
							if (err) {
								Notify({
									type: "error",
									message: "La suppression de la présentation a échoué.",
									color: "#e30613",
									background: "#fff3f3",
									duration: 4000,
								});
								reject(err);
								return;
							}
							Notify({
								type: "success",
								message: "La présentation a bien été supprimée.",
								color: "#38cb89",
								background: "#e5f7ef",
								duration: 4000,
							});
							resolve();
						});
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		deselectItem({ state }) {
			return new Promise((resolve, reject) => {
				state.current_composant.index = null;
				state.current_composant.type = "";
				state.current_tab = "presentation";
				resolve();
			});
		},
		addToComposition({ state }, payload) {
			console.log(payload);
			state.current_squelette.previews_elements.push({
				id: payload.id,
				true_url: payload.true_url,
				url: payload.fields.preview_url,
			});
		},
		saveSquelette({ state }) {
			return new Promise((resolve, reject) => {
				state.current_squelette.fields.composition_new = [];
				state.current_squelette.previews_elements.forEach((el) => {
					state.current_squelette.fields.composition_new.push(el.id);
				});
				//state.current_squelette.fields.preview = [{ url: state.current_squelette.previews_elements[0].true_url }];

				base("squelettes").update(
					[
						{
							id: state.current_squelette.id,
							fields: {
								tags: state.current_squelette.fields.tags,
								composition_new: JSON.stringify(state.current_squelette.fields.composition_new),
								status: state.current_squelette.fields.status,
								digitalisation: state.current_squelette.fields.digitalisation,
								filieres: state.current_squelette.fields.filieres,
								formation: state.current_squelette.fields.formation,
								geographie: state.current_squelette.fields.geographie,
								groupe: state.current_squelette.fields.groupe,
								materiel: state.current_squelette.fields.materiel,
								nom: state.current_squelette.fields.nom,
								prestations: state.current_squelette.fields.prestations,
								procedures: state.current_squelette.fields.procedures,
								qualite: state.current_squelette.fields.qualite,
								toolbox: state.current_squelette.fields.toolbox,
								app_version: app.version,
								langue: state.current_squelette.fields.langue,
								preview: state.current_squelette.previews_elements[0].true_url
									? [{ url: state.current_squelette.previews_elements[0].true_url }]
									: [],
							},
						},
					],
					function (err, records) {
						if (err) {
							console.log(err);
							Notify({
								type: "error",
								message: "Erreur lors de la sauvegarde de votre modèle",
								color: "#e30613",
								background: "#fff3f3",
								duration: 4000,
							});
							reject(err);
						}
						Notify({
							type: "success",
							message: "Le squelette a bien été enregistré.",
							color: "#38cb89",
							background: "#e5f7ef",
							duration: 4000,
						});
						let skel = state.squelettes.findIndex((el) => el.id === records[0].id);
						if (skel) {
							state.squelettes[skel] = records[0];
						}
						resolve(records);
					}
				);
			});
		},
		duplicateModele({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				Dialog.confirm({
					title: "Dupliquer un modèle",
					message: "Voulez-vous vraiment dupliquer ce modèle ?",
					confirmButtonText: "Oui",
					cancelButtonText: "Annuler",
				})
					.then(() => {
						let found = state.modeles.find((el) => el.id === payload);
						console.log(found);
						if (found) {
							base("modeles").create(
								[
									{
										fields: {
											nom: found.fields.nom + " - Copie",
											filieres: found.fields.filieres,
											prestations: found.fields.prestations,
											fichier_de_config: [{ url: found.fields.fichier_de_config[0].url }],
											preview: [{ url: found.fields.preview[0].url }],
											type: found.fields.type,
											cree_par: [state.user.id],
											procedures: found.fields.procedures,
											toolbox: found.fields.toolbox,
											materiel: found.fields.materiel,
											digitalisation: found.fields.digitalisation,
											formation: found.fields.formation,
											qualite: found.fields.qualite,
											geographie: found.fields.geographie,
											groupe: found.fields.groupe,
										},
									},
								],
								function (err, records) {
									if (err) {
										console.error(err);
										return;
									}
									records.forEach(function (record) {
										if (record.fields.preview) {
											fetch(record.fields.preview[0].url)
												.then((res) => res.json())
												.then((json) => {
													record.fields.preview_url = json.preview;
													commit("addModele", record);
													resolve();
												})
												.catch((err) => {
													record.fields.preview_url = "";
													commit("addModele", record);
													resolve();
												});
										} else {
											commit("addModele", record);
											resolve();
										}
									});
								}
							);
						}
					})
					.catch(() => {
						reject();
					});
			});
		},
		duplicateSquelette({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				Dialog.confirm({
					title: "Dupliquer un squelette",
					message: "Voulez-vous vraiment dupliquer ce squelette ?",
					confirmButtonText: "Oui",
					cancelButtonText: "Annuler",
				})
					.then(() => {
						let found = state.squelettes.find((el) => el.id === payload);
						if (found) {
							base("squelettes").create(
								[
									{
										fields: {
											nom: found.fields.nom + " - Copie",
											filieres: found.fields.filieres,
											prestations: found.fields.prestations,
											composition_new: found.fields.composition_new,
											type: found.fields.type,
											tags: found.fields.tags,
											app_version: app.version,
											status: "brouillon",
											author: [state.user.id],
											procedures: found.fields.procedures,
											toolbox: found.fields.toolbox,
											materiel: found.fields.materiel,
											digitalisation: found.fields.digitalisation,
											formation: found.fields.formation,
											qualite: found.fields.qualite,
											geographie: found.fields.geographie,
											groupe: found.fields.groupe,
										},
									},
								],
								function (err, records) {
									if (err) {
										console.error(err);
										return;
									}
									records.forEach(function (record) {
										commit("addSquelette", record);
										resolve();
									});
								}
							);
						}
					})
					.catch(() => {
						reject();
					});
			});
		},
		getNewGlobales({ state, commit, dispatch }, payload) {
			return new Promise((resolve, reject) => {
				dispatch("getSessionId").then(() => {
					const data = new FormData();
					data.append("service", "getGlobales");
					data.append("SESSION_ID", state.session_id);
					axios.post(`${process.env.VUE_APP_MEDIABASE_UPLOAD_API}custom_service/`, data)
						.then((res) => {
							state.globales = [];
							state.zones = [];
							if (res.data.result.globales) {
								state.globales = res.data.result.globales;
							}
							if (res.data.result.zones) {
								res.data.result.zones.forEach((el) => {
									el.fields.configuration = JSON.parse(el.fields.configuration);
								});
								state.zones = res.data.result.zones;
							}
							resolve(res.data.result);
						})
						.catch((err) => {
							console.log(err);
						});
				});
			});
		},
		getNewClassement({ state, commit, dispatch }, payload) {
			return new Promise((resolve, reject) => {
				dispatch("getSessionId").then(() => {
					state.notices.classement = true;
					const data = new FormData();
					data.append("service", "getClassement");
					data.append("SESSION_ID", state.session_id);
					axios.post(`${process.env.VUE_APP_MEDIABASE_UPLOAD_API}custom_service/`, data)
						.then((res) => {
							state.prestations = res.data.result.prestations;
							state.digitalisation = res.data.result.digitalisation;
							state.procedures = res.data.result.procedures;
							state.toolbox = res.data.result.toolbox;
							state.materiel = res.data.result.materiel;
							state.formation = res.data.result.formation;
							state.qualite = res.data.result.qualite;
							state.geographie = res.data.result.geographie;
							state.groupe_mauffrey = res.data.result.groupe_mauffrey;
							state.filieres = res.data.result.filieres;
							state.notices.classement = false;
							resolve(res.data.result);
						})
						.catch((err) => {
							state.notices.classement = false;
							console.log(err);
						});
				});
			});
		},
		getNewModeles({ state, commit, dispatch }, payload) {
			if (state.retrieve_modele_encours) return;
			state.retrieve_modele_encours = true;
			state.notices.modeles = true;
			dispatch("getSessionId").then(() => {
				
				return new Promise((resolve, reject) => {
					dispatch("getSessionId").then(() => {
						const data = new FormData();
						data.append("service", "getNewModSkel");
						data.append("SESSION_ID", state.session_id);
						axios.post(`${process.env.VUE_APP_MEDIABASE_UPLOAD_API}custom_service/`, data)
							.then((res) => {
								state.modeles = res.data.result.modeles;
								state.squelettes = res.data.result.squelettes;
								state.notices.modeles = false;
								state.retrieve_modele_encours = false;
								state.squelettes_ready = true;
								dispatch("getModeleSkelPreviews");
								resolve(res.data.result);
							})
							.catch((err) => {
								state.notices.modeles = false;
								state.retrieve_modele_encours = false;
								console.log(err);
							});
					});
				});
			});
		},
		getModeleSkelPreviews({ state }) {
			state.modeles?.forEach((el) => {
				if (el.fields.preview) {
					fetch(el.fields.preview[0].url)
						.then((res) => res.json())
						.then((json) => {
							el.fields.preview_url = json.preview;
						})
						.catch((err) => {
							el.fields.preview_url = "";
						});
				}
			});
			state.squelettes?.forEach((el) => {
				if (el.fields.preview) {
					fetch(el.fields.preview[0].url)
						.then((res) => res.json())
						.then((json) => {
							el.fields.preview_url = json.preview;
						})
						.catch((err) => {
							el.fields.preview_url = "";
						});
				}
			});
		},
		createNewCodeFromExcel({ state }, payload) {
			return new Promise((resolve, reject) => {
				if (!payload) {
					reject();
				}
				let existance = state.globales.filter((el) => el.fields.code_court === payload.code_court);
				if (existance.length < 1) {
					base("globales").create(
						{
							code_court: payload.code_court,
							type: payload.type,
							valeur_retournee: payload.type === "image" ? "" : payload.valeur_retournee,
							description: payload.description,
							valeurs: JSON.stringify(payload.regions),
							image: payload.type === "image" ? payload.valeur_retournee : "",
						},
						function (err, record) {
							if (err) {
								console.error(err);
								reject();
							}
							state.globales.push(record);
							resolve(record.id);
						}
					);
				} else {
					base("globales").update(
						[
							{
								id: existance[0].id,
								fields: {
									code_court: payload.code_court,
									type: payload.type,
									valeur_retournee: payload.type === "image" ? "" : payload.valeur_retournee,
									description: payload.description,
									valeurs: JSON.stringify(payload.regions),
									image: payload.type === "image" ? payload.valeur_retournee : "",
								},
							},
						],
						function (err, records) {
							if (err) {
								console.error(err);
								reject();
							}
							let index_of_globale = state.globales.findIndex((el) => el.id === existance[0].id);
							state.globales[index_of_globale] = records[0];
							resolve();
						}
					);
				}
			});
		},
		createNewZoneFromExcel({ state }, payload) {
			return new Promise((resolve, reject) => {
				if (!payload) {
					reject();
				}
				let existance = state.zones.filter((el) => el.fields.nom_zone === payload.nom_zone);
				if (existance.length < 1) {
					base("zones").create(
						{
							nom_zone: payload.nom_zone,
							configuration: JSON.stringify({}),
						},

						function (err, record) {
							if (err) {
								console.error(err);
								reject();
							}

							record.fields.configuration = JSON.parse(record.fields.configuration);
							state.zones.push(record);

							resolve();
						}
					);
				} else {
					resolve();
				}
			});
		},
		createNewZone({ state }) {
			let new_zone = prompt("Veuillez donner un nom à votre nouvelle filiale :");
			if (new_zone) {
				base("zones").create(
					[
						{
							fields: {
								nom_zone: new_zone,
								configuration: JSON.stringify({}),
							},
						},
					],
					function (err, records) {
						if (err) {
							console.error(err);
							Notify({
								type: "error",
								message: "Une erreur est survenue lors de l'ajout de votre zone",
								color: "#e30613",
								background: "#fff3f3",
								duration: 4000,
							});
							return;
						}
						Notify({
							type: "success",
							message: "La zone a bien été ajoutée",
							color: "#38cb89",
							background: "#e5f7ef",
							duration: 4000,
						});

						records.forEach(function (record) {
							record.fields.configuration = JSON.parse(record.fields.configuration);
							state.zones.push(record);
						});
					}
				);
			}
		},
		createNewTextGlobale({ state }) {
			let new_zone = prompt("Veuillez donner un nom à votre nouvelle donnée globale :");
			if (new_zone) {
				base("globales").create(
					[
						{
							fields: {
								code_court: slugify(new_zone),
								type: "texte",
								valeur_retournee: "",
								description: "",
							},
						},
					],
					function (err, records) {
						if (err) {
							console.error(err);
							Notify({
								type: "error",
								message: "Une erreur est survenue lors de l'ajout de votre donnée globale",
								color: "#e30613",
								background: "#fff3f3",
								duration: 4000,
							});
							return;
						}
						Notify({
							type: "success",
							message: "La globale a bien été ajoutée",
							color: "#38cb89",
							background: "#e5f7ef",
							duration: 4000,
						});

						records.forEach(function (record) {
							state.globales.push(record);
						});
					}
				);
			}
		},
		createNewTextImage({ state }) {
			let new_zone = prompt("Veuillez donner un nom à votre nouvelle donnée globale :");
			if (new_zone) {
				base("globales").create(
					[
						{
							fields: {
								code_court: slugify(new_zone),
								type: "image",
								image: "",
								description: "",
							},
						},
					],
					function (err, records) {
						if (err) {
							console.error(err);
							Notify({
								type: "error",
								message: "Une erreur est survenue lors de l'ajout de votre donnée globale",
								color: "#e30613",
								background: "#fff3f3",
								duration: 4000,
							});
							return;
						}
						Notify({
							type: "success",
							message: "La globale a bien été ajoutée",
							color: "#38cb89",
							background: "#e5f7ef",
							duration: 4000,
						});

						records.forEach(function (record) {
							state.globales.push(record);
						});
					}
				);
			}
		},
		incrementUseCounter({ state }, modele_id) {
			let modeleIndex = state.modeles.findIndex((el) => el.id === modele_id);
			base("modeles").find(modele_id, function (err, record) {
				if (err) {
					console.error(err);
					return;
				}
				let count = record.fields.useCounter;
				count++;
				state.modeles[modeleIndex].fields.useCounter = count;
				base("modeles").update(
					[
						{
							id: modele_id,
							fields: {
								useCounter: count,
							},
						},
					],
					function (err, records) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
			});
		},
		incrementUseCounterSquelette({ state }, squelette_id) {
			let squeletteIndex = state.squelettes.findIndex((el) => el.id === squelette_id);
			base("squelettes").find(squelette_id, function (err, record) {
				if (err) {
					console.error(err);
					return;
				}
				let count = record.fields.useCounter;
				count++;
				state.squelettes[squeletteIndex].fields.useCounter = count;
				base("squelettes").update(
					[
						{
							id: squelette_id,
							fields: {
								useCounter: count,
							},
						},
					],
					function (err, records) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
			});
		},
		sendNewMessage({ state, dispatch }, payload) {
			return new Promise((resolve, reject) => {
				dispatch("getSessionId").then(() => {
					const data = new FormData();
					data.append("service", "sendEmail");
					data.append("SESSION_ID", state.session_id);
					data.append("destinaires", payload.destinataires);
					data.append("message", payload.message.replaceAll("'", "&lsquo;"));
					data.append("objet", payload.objet.replaceAll("'", "&lsquo;"));
					axios.post(`${process.env.VUE_APP_MEDIABASE_UPLOAD_API}custom_service/`, data)
						.then((res) => {
							console.log(res.data);
							resolve(res.data.result);
						})
						.catch((err) => {
							reject(err);
							console.log(err);
						});
				});
			});
		},
		makeSquelettePublic({state}, payload) {
			if(confirm("Une fois le squelette rendu public, vous ne pourrez plus le modifier ni le supprimer ? Etes-vous sûr.e de vouloir continuer ?")) {
				return new Promise((resolve, reject) => {
					base("squelettes").update(
					[
						{
							id: state.current_squelette.id,
							fields: {
								proposer_comme_public: true
							},
						},
					]),
					function (err, records) {
						if (err) {
							console.log(err);
							Notify({
								type: "error",
								message: "Une erreur est survenue",
								color: "#e30613",
								background: "#fff3f3",
								duration: 4000,
							});
							reject(err);
						}
						Notify({
							type: "success",
							message: "Le squelette va être étudié par les administrateurs.",
							color: "#38cb89",
							background: "#e5f7ef",
							duration: 4000,
						});
						let skel = state.squelettes.findIndex((el) => el.id === records[0].id);
						if (skel) {
							state.squelettes[skel] = records[0];
						}
						resolve(records);
					}
				})
			}
		},
		async translate({ state }, payload) {
			const apiKey = "AIzaSyDvjZhXFWa0Aks41YplSOBPF3Gb5wiiB4Q";
			const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;
			const regex = /\[(.*?)\]/g;
			let placeholders = [];
			const textWithPlaceholders = payload.content.replace(regex, (match, p1, index) => {
				const placeholder = `[[aaa_${index}]]`;
				placeholders.push({ placeholder, original: match });
				return placeholder;
			});
			const data = {
				q: textWithPlaceholders,
				target: payload.langue,
				format: 'html',
			};
			try {
				const response = await axios.post(url, data);
				let translatedText = response.data.data.translations[0].translatedText;
				translatedText = translatedText.replace(/<p>\s+/g, "<p>").replace(/\s+<\/p>/g, "</p>");
				placeholders.forEach(({ placeholder, original }) => {
					translatedText = translatedText.replace(placeholder, original);
					translatedText = translatedText.trim();
				});
				console.log(translatedText);
				return translatedText;
			} catch (error) {
				console.error("Erreur lors de la traduction : ", error);
			}
		},
		translateModele({ state, dispatch }, payload) {
			return new Promise((resolve, reject) => {
			  	if (!state.current_presentation) {
			    		return reject("Aucune présentation en cours.");
			  	}
			  	if (!payload) {
			    		return reject("Aucune langue fournie.");
			  	}
			  	let langue;
				if (payload === "français") {
					langue = "fr";
				} else if (payload === "english") {
					langue = "en";
				} else if (payload === "espanol") {
					langue = "es";
				} else {
					return reject("Langue non prise en charge.");
				}

			  	const translationPromises = [];
			  	state.current_presentation.fields.slides.forEach(slide => {
			    		slide.composants.forEach(composant => {
						if (composant.content) {
							const promise = dispatch("translate", { content: composant.content, langue: langue })
							.then(trad => {
								console.log(trad);
								composant.content = trad;
							});
							translationPromises.push(promise);
						}
						if (composant.puces) {
							composant.puces.forEach(puce => {
								if (puce.text) {
									const promise = dispatch("translate", { content: puce.text, langue: langue })
										.then(trad => {
										puce.text = trad;
									});
									translationPromises.push(promise);
								}
							});
						}
					});
				});
			Promise.all(translationPromises)
				.then(() => resolve("Traduction terminée avec succès."))
				.catch(error => reject("Erreur lors de la traduction : " + error));
			});
		}
	},
	getters: {
		active_filtre_box_ajout: (state) => {
			return state.active_filtre_box_ajout;
		},
		images_delagalerie: (state) => {
			return state.medias_galerie;
		},
		trying_to_connect: (state) => {
			return state.trying_to_connect;
		},
		loginFailMessage: (state) => {
			return state.loginFailMessage;
		},
		showOverlayGallery: (state) => {
			return state.show_overlay_gallery;
		},
		current_tab: (state) => {
			return state.current_tab;
		},
		globales: (state) => {
			state.globales?.sort((a, b) => {
				if (a.fields.code_court < b.fields.code_court) {
					return -1;
				}
				if (a.fields.code_court > b.fields.code_court) {
					return 1;
				}
				return 0;
			});
			return state.globales;
		},
		globales_texte: (state) => {
			let globales_textes = state.globales.filter((el) => el.fields.type === "texte");
			return globales_textes;
		},
		globales_image: (state) => {
			let globales_images = state.globales.filter((el) => el.fields.type === "image");
			return globales_images;
		},
		categories: (state) => {
			state.prestations.sort((a, b) => {
				if (a.fields.nom < b.fields.nom) {
					return -1;
				}
				if (a.fields.nom > b.fields.nom) {
					return 1;
				}
				return 0;
			});
			state.filieres.sort((a, b) => {
				if (a.fields.nom < b.fields.nom) {
					return -1;
				}
				if (a.fields.nom > b.fields.nom) {
					return 1;
				}
				return 0;
			});
			state.groupe_mauffrey.sort((a, b) => {
				if (a.fields.nom < b.fields.nom) {
					return -1;
				}
				if (a.fields.nom > b.fields.nom) {
					return 1;
				}
				return 0;
			});
			state.geographie.sort((a, b) => {
				if (a.fields.nom < b.fields.nom) {
					return -1;
				}
				if (a.fields.nom > b.fields.nom) {
					return 1;
				}
				return 0;
			});
			state.qualite.sort((a, b) => {
				if (a.fields.nom < b.fields.nom) {
					return -1;
				}
				if (a.fields.nom > b.fields.nom) {
					return 1;
				}
				return 0;
			});
			state.formation.sort((a, b) => {
				if (a.fields.nom < b.fields.nom) {
					return -1;
				}
				if (a.fields.nom > b.fields.nom) {
					return 1;
				}
				return 0;
			});
			state.digitalisation.sort((a, b) => {
				if (a.fields.nom < b.fields.nom) {
					return -1;
				}
				if (a.fields.nom > b.fields.nom) {
					return 1;
				}
				return 0;
			});
			state.materiel.sort((a, b) => {
				if (a.fields.nom < b.fields.nom) {
					return -1;
				}
				if (a.fields.nom > b.fields.nom) {
					return 1;
				}
				return 0;
			});
			state.toolbox.sort((a, b) => {
				if (a.fields.nom < b.fields.nom) {
					return -1;
				}
				if (a.fields.nom > b.fields.nom) {
					return 1;
				}
				return 0;
			});
			state.procedures.sort((a, b) => {
				if (a.fields.nom < b.fields.nom) {
					return -1;
				}
				if (a.fields.nom > b.fields.nom) {
					return 1;
				}
				return 0;
			});
			let categories = {
				groupe_mauffrey: state.groupe_mauffrey,
				geographie: state.geographie,
				qualite: state.qualite,
				formation: state.formation,
				digitalisation: state.digitalisation,
				metiers: state.prestations,
				filieres: state.filieres,
				materiel: state.materiel,
				toolbox: state.toolbox,
				procedures: state.procedures,
			};
			return categories;
		},
		prestations: (state) => {
			state.prestations.sort((a, b) => {
				if (a.fields.nom < b.fields.nom) {
					return -1;
				}
				if (a.fields.nom > b.fields.nom) {
					return 1;
				}
				return 0;
			});
			return state.prestations;
		},
		filieres: (state) => {
			state.filieres.sort((a, b) => {
				if (a.fields.nom < b.fields.nom) {
					return -1;
				}
				if (a.fields.nom > b.fields.nom) {
					return 1;
				}
				return 0;
			});
			return state.filieres;
		},
		procedures: (state) => {
			state.procedures.sort((a, b) => {
				if (a.fields.nom < b.fields.nom) {
					return -1;
				}
				if (a.fields.nom > b.fields.nom) {
					return 1;
				}
				return 0;
			});
			return state.procedures;
		},
		generationEnCours: (state) => {
			return state.generation_en_cours;
		},
		presentations: (state) => {
			state.presentations.forEach((el) => {
				el.fields.date_modifie_formatee = new moment(el.fields.modifiee_le).locale("fr").format("dddd DD MMMM YYYY à HH:mm");
				if (el.fields.statut === "brouillon") {
					el.fields.statut_formate = "Brouillon";
				}
				if (el.fields.statut === "pret") {
					el.fields.statut_formate = "Prêt à relire";
				}
				if (el.fields.statut === "envoye") {
					el.fields.statut_formate = "Envoyée au client";
				}
				if (el.fields.statut === "archive") {
					el.fields.statut_formate = "Archivée";
				}
				if (el.fields.can_access) {
					el.fields.intervenants = el.fields.can_access.length;
				} else {
					el.fields.intervenants = 0;
				}
			});
			state.presentations.sort(function (a, b) {
				var keyA = new Date(a.fields.modifiee_le);
				var keyB = new Date(b.fields.modifiee_le);
				// Compare the 2 dates
				if (keyA < keyB) return 1;
				if (keyA > keyB) return -1;
				return 0;
			});
			return state.presentations;
		},
		presentations_archives: (state) => {
			state.presentations_archives.forEach((el) => {
				el.fields.date_modifie_formatee = new moment(el.fields.modifiee_le).locale("fr").format("dddd DD MMMM YYYY à HH:mm");
				if (el.fields.statut === "brouillon") {
					el.fields.statut_formate = "Brouillon";
				}
				if (el.fields.statut === "pret") {
					el.fields.statut_formate = "Prêt à relire";
				}
				if (el.fields.statut === "envoye") {
					el.fields.statut_formate = "Envoyée au client";
				}
				if (el.fields.statut === "archive") {
					el.fields.statut_formate = "Archivée";
				}
				if (el.fields.partagee_avec) {
					el.fields.partagee_avec = JSON.parse(el.fields.partagee_avec);
				} else {
					el.fields.partagee_avec = "";
				}
			});
			return state.presentations_archives;
		},
		currentPresentation: (state) => {
			if (state.current_presentation) {
				let presentation = state.current_presentation.fields;
				presentation.date_modifie_formatee = new moment(presentation.modifiee_le).locale("fr").format("dddd DD MMMM YYYY à HH:mm");
				if (presentation.statut === "brouillon") {
					presentation.statut_formate = "Brouillon";
				}
				if (presentation.statut === "pret") {
					presentation.statut_formate = "Prêt à relire";
				}
				if (presentation.statut === "envoye") {
					presentation.statut_formate = "Envoyée au client";
				}
				if (presentation.statut === "archive") {
					presentation.statut_formate = "Archivée";
				}
				return presentation;
			}
		},
		sharedWith: (state) => {
			if (state.current_presentation.fields.partagee_avec) {
				return JSON.parse(state.current_presentation.fields.partagee_avec);
			}
		},
		currentDiapositive: (state) => {
			return state.current_diapositive;
		},
		currentComposant: (state) => {
			return state.current_composant;
		},
		completeCurrentComposant: (state) => {
			return state.current_presentation.fields.slides[state.current_diapositive].composants[state.current_composant.index];
		},
		currentUser: (state) => {
			return state.user;
		},
		presentationReady: (state) => {
			return state.presentation_ready;
		},
		showOverlayShare: (state) => {
			return state.show_overlay_share;
		},
		showOverlayCreate: (state) => {
			return state.show_overlay_create;
		},
		showOverlaySaveModele: (state) => {
			return state.show_overlay_save;
		},
		showOverlaySlideCreate: (state) => {
			return state.show_overlay_slide_create;
		},
		composantContext: (state) => {
			return state.show_composant_context;
		},
		slideContext: (state) => {
			return state.show_slide_context;
		},
		modeles: (state) => {
			let tmp = [];
			state.modeles?.forEach((el, index) => {
				el.classes = "";
				el.classes += `all-langues ${el.fields.langue} `;
				el.tags = el.fields.nom + " ";
				if (el.fields.type === "presentation") {
					el.classes += "prez";
				} else if (el.fields.type === "slide_vierge") {
					el.classes += "slidevierge";
				} else if (el.fields.type === "slide") {
					el.classes += "slideslide";
				} else if (el.fields.type === "groupe_slides") {
					el.classes += "slidesgroupe";
				}

				if (el.fields.filieres) {
					el.classes += " all-filieres ";
					el.classes += ` ${el.fields.filieres.join(" ")}`;
					el.tags += el.fields.nom_filiere[0];
				}
				if (el.fields.prestations) {
					el.classes += " all-prestations ";
					el.classes += ` ${el.fields.prestations.join(" ")}`;
					el.tags += el.fields.nom_prestation[0];
				}
				if (el.fields.procedures) {
					el.classes += " all-procedures ";
					el.classes += ` ${el.fields.procedures.join(" ")}`;
					el.tags += el.fields.nom_procedure[0];
				}
				if (el.fields.toolbox) {
					el.classes += " all-toolbox ";
					el.classes += ` ${el.fields.toolbox.join(" ")}`;
					el.tags += el.fields.nom_toolbox[0];
				}
				if (el.fields.materiel) {
					el.classes += " all-materiel ";
					el.classes += ` ${el.fields.materiel.join(" ")}`;
					el.tags += el.fields.nom_materiel[0];
				}
				if (el.fields.digitalisation) {
					el.classes += " all-digitalisation ";
					el.classes += ` ${el.fields.digitalisation.join(" ")}`;
					el.tags += el.fields.nom_digitalisation[0];
				}
				if (el.fields.formation) {
					el.classes += " all-formation ";
					el.classes += ` ${el.fields.formation.join(" ")}`;
					el.tags += el.fields.nom_formation[0];
				}
				if (el.fields.qualite) {
					el.classes += " all-qualite ";
					el.classes += ` ${el.fields.qualite.join(" ")}`;
					el.tags += el.fields.nom_qualite[0];
				}
				if (el.fields.geographie) {
					el.classes += " all-geographie ";
					el.classes += ` ${el.fields.geographie.join(" ")}`;
					el.tags += el.fields.nom_geographie[0];
				}
				if (el.fields.groupe) {
					el.classes += " all-groupe_mauffrey ";
					el.classes += ` ${el.fields.groupe.join(" ")}`;
					el.tags += el.fields.nom_groupe[0];
				}
				if (el.fields.prive) {
					el.classes += " private ";
				}
				if (el.fields.nom != "00 - Premier modele") {
					// if (el.fields.prive) {
					// 	if (state.user.fields.role.includes("administrateur")) {
					// 		tmp.push(el);
					// 	}
					// } else {
					// 	tmp.push(el);
					// }
					tmp.push(el);
				}
			});

			return tmp.sort((a, b) => (a.fields.nom > b.fields.nom ? 1 : b.fields.nom > a.fields.nom ? -1 : 0));
		},
		squelettes: (state) => {
			let tmp = [];
			state.squelettes?.forEach((el, index) => {
				el.classes = "";
				el.tags = el.fields.nom + " ";
				if (el.fields.filieres) {
					el.classes += ` ${el.fields.filieres.join(" ")}`;
					el.tags += el.fields.filieres[0];
				}
				if (el.fields.prestations) {
					el.classes += ` ${el.fields.prestations.join(" ")}`;
					el.tags += el.fields.prestations[0];
				}
				if (el.fields.procedures) {
					el.classes += ` ${el.fields.procedures.join(" ")}`;
					el.tags += el.fields.procedures[0];
				}
				if (el.fields.toolbox) {
					el.classes += ` ${el.fields.toolbox.join(" ")}`;
					el.tags += el.fields.toolbox[0];
				}
				if (el.fields.materiel) {
					el.classes += ` ${el.fields.materiel.join(" ")}`;
					el.tags += el.fields.materiel[0];
				}
				if (el.fields.digitalisation) {
					el.classes += ` ${el.fields.digitalisation.join(" ")}`;
					el.tags += el.fields.digitalisation[0];
				}
				if (el.fields.formation) {
					el.classes += ` ${el.fields.formation.join(" ")}`;
					el.tags += el.fields.formation[0];
				}
				if (el.fields.qualite) {
					el.classes += ` ${el.fields.qualite.join(" ")}`;
					el.tags += el.fields.qualite[0];
				}
				if (el.fields.geographie) {
					el.classes += ` ${el.fields.geographie.join(" ")}`;
					el.tags += el.fields.geographie[0];
				}
				if (el.fields.groupe) {
					el.classes += ` ${el.fields.groupe.join(" ")}`;
					el.tags += el.fields.groupe[0];
				}
				tmp.push(el);
			});
			return tmp.sort((a, b) => (a.fields.nom > b.fields.nom ? 1 : b.fields.nom > a.fields.nom ? -1 : 0));
		},
		squelettes_publies: (state) => {
			let tmp = [];
			state.squelettes?.forEach((el, index) => {
				el.classes = "";
				el.tags = el.fields.nom + " ";
				if (el.fields.filieres) {
					el.classes += ` ${el.fields.filieres.join(" ")}`;
					el.tags += el.fields.filieres[0];
				}
				if (el.fields.prestations) {
					el.classes += ` ${el.fields.prestations.join(" ")}`;
					el.tags += el.fields.prestations[0];
				}
				if (el.fields.procedures) {
					el.classes += ` ${el.fields.procedures.join(" ")}`;
					el.tags += el.fields.procedures[0];
				}
				if (el.fields.toolbox) {
					el.classes += ` ${el.fields.toolbox.join(" ")}`;
					el.tags += el.fields.toolbox[0];
				}
				if (el.fields.materiel) {
					el.classes += ` ${el.fields.materiel.join(" ")}`;
					el.tags += el.fields.materiel[0];
				}
				if (el.fields.digitalisation) {
					el.classes += ` ${el.fields.digitalisation.join(" ")}`;
					el.tags += el.fields.digitalisation[0];
				}
				if (el.fields.formation) {
					el.classes += ` ${el.fields.formation.join(" ")}`;
					el.tags += el.fields.formation[0];
				}
				if (el.fields.qualite) {
					el.classes += ` ${el.fields.qualite.join(" ")}`;
					el.tags += el.fields.qualite[0];
				}
				if (el.fields.geographie) {
					el.classes += ` ${el.fields.geographie.join(" ")}`;
					el.tags += el.fields.geographie[0];
				}
				if (el.fields.groupe) {
					el.classes += ` ${el.fields.groupe.join(" ")}`;
					el.tags += el.fields.groupe[0];
				}
				if (el.fields.status === "visible" || (el.fields.status === "prive" && el.fields.author[0] === state.user.id)) {
					tmp.push(el);
				}
			});
			return tmp.sort((a, b) => (a.fields.nom > b.fields.nom ? 1 : b.fields.nom > a.fields.nom ? -1 : 0));
		},
		currentSquelette: (state) => {
			return state.current_squelette;
		},
		modelesPresentations: (state) => {
			let res = state.modeles?.filter((el) => {
				return el.fields.type === "presentation";
			});
			// res.forEach((el) => {
			// 	if (el.fields.filieres) {
			// 		el.fields.filieres.forEach((a) => {
			// 			classes += a + " ";
			// 		});
			// 	}
			// 	if (el.fields.prestations) {
			// 		el.fields.prestations.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.groupe_mauffrey) {
			// 		el.fields.groupe_mauffrey.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.geographie) {
			// 		el.fields.geographie.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.qualite) {
			// 		el.fields.qualite.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.formation) {
			// 		el.fields.formation.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.digitalisation) {
			// 		el.fields.digitalisation.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.materiel) {
			// 		el.fields.materiel.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.toolbox) {
			// 		el.fields.toolbox.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.procedures) {
			// 		el.fields.procedures.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.tags) {
			// 		let tags = JSON.parse(el.fields.tags);
			// 		tags.forEach((c) => {
			// 			classes += c.text + " ";
			// 		});
			// 	}
			// 	el.classes = classes;
			// });
			return res;
		},
		modelesSlidesVierges: (state) => {
			let classes = "";
			let res = state.modeles.filter((el) => {
				return el.fields.type === "slide_vierge";
			});
			// res.forEach((el) => {
			// 	if (el.fields.filieres) {
			// 		el.fields.filieres.forEach((a) => {
			// 			classes += a + " ";
			// 		});
			// 	}
			// 	if (el.fields.prestations) {
			// 		el.fields.prestations.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.groupe_mauffrey) {
			// 		el.fields.groupe_mauffrey.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.geographie) {
			// 		el.fields.geographie.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.qualite) {
			// 		el.fields.qualite.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.formation) {
			// 		el.fields.formation.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.digitalisation) {
			// 		el.fields.digitalisation.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.materiel) {
			// 		el.fields.materiel.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.toolbox) {
			// 		el.fields.toolbox.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.procedures) {
			// 		el.fields.procedures.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.tags) {
			// 		let tags = JSON.parse(el.fields.tags);
			// 		tags.forEach((c) => {
			// 			classes += c.text + " ";
			// 		});
			// 	}
			// 	el.classes = classes;
			// });
			return res;
		},
		modelesSlides: (state) => {
			let classes = "";
			let res = state.modeles.filter((el) => {
				return el.fields.type === "slide";
			});
			// res.forEach((el, index) => {
			// 	if (el.fields.filieres) {
			// 		el.fields.filieres.forEach((a) => {
			// 			classes += a + " ";
			// 		});
			// 	}
			// 	if (el.fields.prestations) {
			// 		el.fields.prestations.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.groupe) {
			// 		el.fields.groupe.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.geographie) {
			// 		el.fields.geographie.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.qualite) {
			// 		el.fields.qualite.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.formation) {
			// 		el.fields.formation.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.digitalisation) {
			// 		el.fields.digitalisation.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.materiel) {
			// 		el.fields.materiel.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.toolbox) {
			// 		el.fields.toolbox.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.procedures) {
			// 		el.fields.procedures.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.tags) {
			// 		let tags = JSON.parse(el.fields.tags);
			// 		tags.forEach((c) => {
			// 			classes += c.text + " ";
			// 		});
			// 	}
			// 	el.classes = classes;
			// 	console.log(index, el.fields.nom, el.classes, el.fields);
			// });
			return res;
		},
		modelesGroupesSlides: (state) => {
			let classes = "";
			let res = state.modeles.filter((el) => {
				return el.fields.type === "groupe_slides";
			});
			// res.forEach((el) => {
			// 	if (el.fields.filieres) {
			// 		el.fields.filieres.forEach((a) => {
			// 			classes += a + " ";
			// 		});
			// 	}
			// 	if (el.fields.prestations) {
			// 		el.fields.prestations.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.groupe_mauffrey) {
			// 		el.fields.groupe_mauffrey.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.geographie) {
			// 		el.fields.geographie.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.qualite) {
			// 		el.fields.qualite.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.formation) {
			// 		el.fields.formation.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.digitalisation) {
			// 		el.fields.digitalisation.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.materiel) {
			// 		el.fields.materiel.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.toolbox) {
			// 		el.fields.toolbox.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.procedures) {
			// 		el.fields.procedures.forEach((b) => {
			// 			classes += b + " ";
			// 		});
			// 	}
			// 	if (el.fields.tags) {
			// 		let tags = JSON.parse(el.fields.tags);
			// 		tags.forEach((c) => {
			// 			classes += c.text + " ";
			// 		});
			// 	}
			// 	el.classes = classes;
			// });
			return res;
		},
		appLoading: (state) => {
			return state.app_loading;
		},
		appLoadingText: (state) => {
			return state.appLoadingText;
		},
		slideModelePreview: (state) => {
			return state.slideModelePreview;
		},
		showOverlayChooseSlide: (state) => {
			return state.show_overlay_choose_slide;
		},
		showOverlayChooseImage: (state) => {
			return state.show_overlay_choose_image;
		},
		connected: (state) => {
			return state.connected;
		},
		users: (state) => {
			state.users.forEach((el) => {
				if (el.fields.derniere_connexion) {
					el.fields.derniere_connexion_formatee = new moment(el.fields.derniere_connexion).locale("fr").format("dddd DD MMMM YYYY à HH:mm");
				}
			});
			return state.users;
		},
		pressePapier: (state) => {
			return state.presse_papier;
		},
		resetCurrentSquelette: (state) => {
			state.current_squelette = null;
		},
		success: (state) => {
			return state.success;
		},
		notices: (state) => {
			return state.notices;
		},
		havenotices: (state) => {
			if (
				state.notices.prestations ||
				state.notices.filieres ||
				state.notices.procedures ||
				state.notices.toolbox ||
				state.notices.materiel ||
				state.notices.qualite ||
				state.notices.digitalisation ||
				state.notices.formation ||
				state.notices.groupes ||
				state.notices.globales ||
				state.notices.squelettes ||
				state.notices.modeles
			) {
				return true;
			} else {
				return false;
			}
		},
		zones: (state) => {
			return state.zones;
		},
	},
	modules: {},
	plugins: [vuexLocal.plugin],
});
