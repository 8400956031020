<template>
	<div id="createzone" v-if="showOverlayCreate">
		<div class="box">
			<div class="closeOverlay" @click="showCreate()"><i class="fal fa-times"></i></div>
			<h3>Création d'une nouvelle présentation</h3>
			<div class="thebarre">
				<h4>Recherche par mot clé</h4>
				<div class="search">
					<input type="search" v-model="search_modele" placeholder="Mots clés" @keyup="filterModeles()" />
					<button type="submit"><i class="fal fa-search"></i></button>
				</div>
				<div class="radios">
					<legend>Groupe Mauffrey</legend>
					<select v-model="selected_groupe" @change="filterModeles()">
						<option value="all-groupe_mauffrey">Tous</option>
						<option v-for="item in categories.groupe_mauffrey" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Géographie</legend>
					<select v-model="selected_geographie" @change="filterModeles()">
						<option value="all-geographie">Tous</option>
						<option v-for="item in categories.geographie" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Environnement / Sécurité</legend>
					<select v-model="selected_qualite" @change="filterModeles()">
						<option value="all-qualite">Tous</option>
						<option v-for="item in categories.qualite" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Formation</legend>
					<select v-model="selected_formation" @change="filterModeles()">
						<option value="all-formation">Tous</option>
						<option v-for="item in categories.formation" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Digitalisation</legend>
					<select v-model="selected_digitalisation" @change="filterModeles()">
						<option value="all-digitalisation">Tous</option>
						<option v-for="item in categories.digitalisation" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Métiers</legend>
					<select v-model="selected_prestation" @change="filterModeles()">
						<option value="all-prestations">Tous</option>
						<option v-for="item in categories.metiers" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Filières</legend>
					<select v-model="selected_filiere" @change="filterModeles()">
						<option value="all-filieres">Tous</option>
						<option v-for="item in categories.filieres" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Matériel</legend>
					<select v-model="selected_materiel" @change="filterModeles()">
						<option value="all-materiel">Tous</option>
						<option v-for="item in categories.materiel" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Toolbox</legend>
					<select v-model="selected_toolbox" @change="filterModeles()">
						<option value="all-toolbox">Tous</option>
						<option v-for="item in categories.toolbox" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Procédures</legend>
					<select v-model="selected_procedures" @change="filterModeles()">
						<option value="all-procedures">Tous</option>
						<option v-for="item in categories.procedures" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>
			</div>
			<div class="gallery">
				<div
					class="
						gallery-item
						all-prestations all-filieres all-types all-geographie all-procedures all-toolbox all-materiel all-digitalisation all-formation all-qualite
						all-groupe_mauffrey
					"
					data-id="0"
					@click="setSelected($event)"
				>
					<div class="image">
						<img src="https://mediabase.lezardscreation.fr/wp-content/uploads/image-vierge-1.png" />
					</div>
					<div class="infos">
						<label>Présentation vide</label>
						<div class="name">Vous partez d'une page blanche</div>
					</div>
				</div>
				<div
					v-for="modele in modeles"
					:key="modele.id"
					:data-modele-file="modele.fields.fichier_de_config[0].url"
					:class="`gallery-item all-prestations all-filieres all-types all-geographie all-procedures all-toolbox all-materiel all-digitalisation all-formation all-qualite
			all-groupe_mauffrey ${modele.classes}`"
					@click="setSelected($event)"
				>
					<div class="image">
						<img :src="modele.fields.preview_url" v-if="modele.fields.preview_url" />
						<img src="https://mediabase.lezardscreation.fr/wp-content/uploads/image-vierge-1.png" v-else />
					</div>
					<div class="infos">
						<label>{{ modele.fields.nom }}</label>
						<div class="name" v-if="modele.fields.nom_cree_par">Créé par {{ modele.fields.nom_cree_par[0] }}</div>
					</div>
				</div>
			</div>
			<div class="buttons">
				<button class="create" @click="createPresentation()" v-if="modele_selected">
					<span v-if="!is_creating">Commencer</span>
					<span v-else><i class="fal fa-spinner fa-spin"></i></span>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
import { Dialog } from "vant";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
	name: "createZone",
	components: {},
	data() {
		return {
			search_modele: "",
			selected_filiere: "all-filieres",
			selected_prestation: "all-prestations",
			selected_geographie: "all-geographie",
			selected_procedures: "all-procedures",
			selected_toolbox: "all-toolbox",
			selected_materiel: "all-materiel",
			selected_digitalisation: "all-digitalisation",
			selected_formation: "all-formation",
			selected_qualite: "all-qualite",
			selected_groupe: "all-groupe_mauffrey",
			selected_type: "all-types",
			modele_selected: false,
			modele_content: "",
			is_creating: false,
		};
	},
	computed: {
		...mapGetters({
			prestations: "prestations",
			filieres: "filieres",
			modeles: "modelesPresentations",
			showOverlayCreate: "showOverlayCreate",
			categories: "categories",
		}),
	},
	methods: {
		...mapMutations({
			showCreate: "showCreate",
		}),
		...mapActions({
			getPresentations: "getPresentations",
			createNewPresentation: "createNewPresentation",
		}),
		createPresentation() {
			this.is_creating = true;
			let selected = document.querySelector(".gallery-item.selected");
			if (selected.dataset.modeleFile) {
				this.createNewPresentation(selected.dataset.modeleFile)
					.then((res) => {
						this.is_creating = false;
						this.$router.push({ name: "Présentation", params: { id: res } });
					})
					.catch((err) => {
						this.is_creating = false;
						return;
					});
			} else {
				this.createNewPresentation()
					.then((res) => {
						this.is_creating = false;
						this.$router.push({ name: "Présentation", params: { id: res } });
					})
					.catch((err) => {
						this.is_creating = false;
						return;
					});
			}
		},
		setSelected(event) {
			let gallery_items = document.querySelectorAll(".gallery-item");
			gallery_items.forEach((el) => {
				el.classList.remove("selected");
				this.modele_selected = false;
			});
			event.currentTarget.classList.add("selected");
			this.modele_selected = true;
		},
		filterModeles() {
			let gallery_items = document.querySelectorAll(".gallery-item");
			if (this.search_modele.length > 3) {
				gallery_items.forEach((el) => {
					let mots_cles = el.querySelector(".infos label").innerHTML;
					if (
						el.classList.contains(this.selected_filiere) &&
						el.classList.contains(this.selected_prestation) &&
						el.classList.contains(this.selected_prestation) &&
						el.classList.contains(this.selected_geographie) &&
						el.classList.contains(this.selected_procedures) &&
						el.classList.contains(this.selected_toolbox) &&
						el.classList.contains(this.selected_materiel) &&
						el.classList.contains(this.selected_digitalisation) &&
						el.classList.contains(this.selected_formation) &&
						el.classList.contains(this.selected_qualite) &&
						el.classList.contains(this.selected_groupe) &&
						mots_cles
							.toString()
							.toLowerCase()
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "")
							.replace(/'/g, "")
							.replace("’", "")
							.includes(
								this.search_modele
									.toString()
									.toLowerCase()
									.normalize("NFD")
									.replace(/[\u0300-\u036f]/g, "")
									.replace(/'/g, "")
									.replace("’", "")
							)
					) {
						el.classList.remove("hidden");
					} else {
						el.classList.add("hidden");
					}
				});
			} else {
				gallery_items.forEach((el) => {
					if (
						el.classList.contains(this.selected_filiere) &&
						el.classList.contains(this.selected_prestation) &&
						el.classList.contains(this.selected_geographie) &&
						el.classList.contains(this.selected_procedures) &&
						el.classList.contains(this.selected_toolbox) &&
						el.classList.contains(this.selected_materiel) &&
						el.classList.contains(this.selected_digitalisation) &&
						el.classList.contains(this.selected_formation) &&
						el.classList.contains(this.selected_qualite) &&
						el.classList.contains(this.selected_groupe)
					) {
						el.classList.remove("hidden");
					} else {
						el.classList.add("hidden");
					}
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
#createzone {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba($gris, 0.97);
	backdrop-filter: blur(20px) saturate(20);
	z-index: 88;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	border-radius: 0;
	.box {
		padding: 80px 30px 30px;
		background: white;
		box-shadow: 2px 3px 20px rgba($black, 0.1);
		font-size: 12px;
		width: calc(100vw - 200px);
		animation: appearFromRight 0.5s ease backwards;
		animation-delay: 0.2s;
		color: rgba(black, 0.7);
		position: relative;
		height: 100%;
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		gap: 30px;
		.closeOverlay {
			position: absolute;
			width: 50px;
			height: 50px;
			top: 0;
			right: 0;
			background: white;
			border-radius: 0 0 0 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 20px;
			cursor: pointer;
			transition: all 0.3s ease;
			box-shadow: inset 2px -2px 4px rgba(black, 0.05);
			background: rgba($rouge, 0.1);
			color: $rouge;
			&:hover {
				box-shadow: inset 2px -2px 10px rgba($rouge, 0.2);
				background: $rouge;
				color: white;
			}
		}
		p {
			font-size: 13px;
			line-height: 20px;
			font-weight: 300;
			max-width: 60%;
			strong {
				font-weight: 500;
			}
		}
		h3 {
			font-weight: 600;
			margin-bottom: 16px;
			font-size: 20px;
			flex: 0 0 100%;
			position: absolute;
			top: 30px;
		}
		.thebarre {
			margin: 0;
			display: flex;
			flex-direction: column;
			gap: 10px;
			padding: 30px;
			flex: 0 0 280px;
			height: 100%;
			background: rgba(white, 0.001);
			backdrop-filter: blur(10px);
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			h4 {
				font-weight: 600;
				margin-bottom: 10px;
				font-size: 14px;
				top: 30px;
			}
			.radios {
				display: flex;
				flex-direction: column;
				margin-bottom: 12px;
				position: relative;
				padding: 16px 10px 4px;
				border: solid 1px darken($bleuclair, 6%);
				border-radius: 6px;
				legend {
					position: absolute;
					top: 0;
					left: 10px;
					font-weight: 500;
					color: $bleu;
					font-size: 12px;
					background: $bleuclair;
					padding: 3px 10px;
					transform: translateY(-50%);
					border: solid 1px $bleuclair;
					border-radius: 6px;
				}
				.radio {
					margin-bottom: 6px;
					display: flex;
					gap: 10px;
					align-items: center;
					font-size: 12px;
					font-weight: 500;
					cursor: pointer;
					color: lighten(black, 20%);
					transition: all 0.3s ease;
					&:hover {
						color: black;
					}
					input[type="radio"]:checked {
						background: $rouge;
					}
				}
			}
			select {
				width: auto;
				background: white;
				height: 28px;
				border: none;
				padding: 0 10px;
				font-size: 12px;
				font-weight: normal;
				transition: all 0.3s ease;
				outline: none;
			}
			.search {
				width: 100%;
				background: white;
				height: 40px;
				padding: 0 40px 0 0;
				border: none;
				border-bottom: solid 1px black;
				margin-bottom: 30px;
				font-size: 13px;
				appearance: none;
				font-weight: 500;
				transition: all 0.3s ease;
				outline: none;
				display: flex;
				align-items: center;
				input {
					flex: 1;
					appearance: none;
					border: none;
					height: 40px;
					background: transparent;
					padding: 0;
					font-size: 12px;
				}
				button {
					background: none;
					color: rgba(black, 0.5);
					padding: 0 0 0 10px;
					margin: 0;
					flex: 0 0 auto;
					font-size: 13px;
				}
			}
		}
		.gallery {
			display: flex;
			gap: 20px;
			flex: 1;

			background: $gris;
			padding: 30px;
			border-radius: 20px;
			overflow-y: auto;
			margin-bottom: 10px;
			height: 100%;
			background-image: url("../assets/img/fond-alveole.png");
			background-position: top left;
			background-repeat: repeat;
			flex-wrap: wrap;
			align-items: flex-start;
			align-content: flex-start;
			.gallery-item {
				padding: 5px;
				background: white;
				border-radius: 10px;
				box-shadow: 1px 1px 2px rgba(black, 0.05);
				display: flex;
				flex-direction: column;
				text-align: center;
				font-weight: 500;
				justify-content: flex-start;
				align-items: flex-start;
				transition: all 0.2s ease;
				position: relative;
				overflow: hidden;
				flex: 0 0 calc(25% - 20px);
				@media screen and (max-width: 1600px) {
					flex: 0 0 calc(33.333333% - 20px);
				}
				.infos {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					height: 50px;
					.hidden_fields {
						display: none;
					}
					label {
						font-size: 12px;
						font-weight: 500;
						text-align: center;
						padding: 0px 0px 2px;
						display: block;
					}
					input[type="text"] {
						font-size: 12px;
						font-weight: 500;
						text-align: center;
						padding: 0px 0px 2px;
						display: block;
						border: none;
						background: $gris;
						padding: 6px 10px;
						margin-bottom: 10px;
						border-radius: 20px;
						width: 100%;
						box-shadow: inset 1px 1px 3px rgba(black, 0.05);
						&:focus {
							background: $rougeclair;
						}
					}
					.name {
						font-size: 10px;
						font-style: italic;
						color: rgba(black, 0.5);
					}
				}
				.tools {
					position: absolute;
					bottom: 0;
					right: 0;
					width: 40px;
					height: 40px;
					border-radius: 50% 0 0 0;
					background: $rougeclair;
					color: $rouge;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					box-shadow: inset 1px 1px 3px rgba($rouge, 0.1);
					cursor: pointer;
					transition: all 0.3s ease;
					i {
						font-size: 16px;
					}
					&:hover {
						background: $rouge;
						color: white;
					}
				}
				.image {
					width: 100%;
					display: block;
					position: relative;
					img {
						position: absolute;
						width: 100%;
						height: 100%;
						object-fit: cover;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
					&:before {
						display: block;
						content: "";
						padding-bottom: 56.25%;
					}
				}
				&:hover {
					transform: translateY(10px);
					filter: drop-shadow(4px 4px 16px rgba(black, 0.1));
				}
				&.selected {
					border: solid 2px $rouge;
				}
				&.hidden {
					display: none;
				}
			}
			&:after {
				content: "";
				height: 10px;
				width: 100%;
				display: block;
				grid-column: span 3;
			}
		}
		.buttons {
			display: flex;
			justify-content: flex-end;
			position: absolute;
			bottom: 20px;
			right: 30px;
			button {
				background: $rouge;
				display: flex;
				align-items: center;
				i {
					margin-left: 10px;
					transform: translateY(2px);
				}
				&:hover {
					animation: hand 1s ease-in-out alternate-reverse infinite;
					background: darken($rouge, 5%);
				}
			}
		}
	}
}
</style>
