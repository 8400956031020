<template>
	<div id="createSlideZone" v-if="showOverlaySlideCreate">
		<div class="box">
			<div class="closeOverlay" @click="showSlideCreate()"><i class="fal fa-times"></i></div>
			<h3>Enregistrer un nouveau modèle de diapositive</h3>
			<p>Remplissez les informations ci-dessous afin d'enregistrer cette diapositive comme modèle. Elle sera ensuite réutilisable par tous les autres utilisateurs.</p>
			<div class="formulaire">
				<div class="apercu"><img :src="slideModelePreview" v-if="slideModelePreview.length" /></div>
				<div class="droite">
					<div class="form-group name">
						<label>Nom</label>
						<input
							@focus="is_focus = true"
							type="text"
							minlength="3"
							maxlength="40"
							v-model="modele_name"
							placeholder="Nommez votre modèle"
							@keyup="verifyName"
						/>
						<ul
							v-if="currentUser.fields.role === 'super_administrateur' || currentUser.fields.role === 'administrateur'"
							class="suggestions"
							v-show="modele_name.length >= 3 && is_focus"
						>
							<h4>Modèles existants</h4>
							<li @click="setModeleName" v-for="(myOption, index) in myOptions" :key="`suggestion${index}`" class="suggestion">{{ myOption }}</li>
						</ul>
						<div class="retour_direct" v-if="retour">{{ retour }}</div>
					</div>
					<div class="form-group">
						<label>Type de modèle</label>
						<select v-model="modele_type">
							<option value="slide">Diapositive prête à l'emploi</option>
							<option value="slide_vierge">Diapositive vierge (idem masque de diapositive)</option>
						</select>
					</div>
					<div class="form-group half">
						<label>Groupe Mauffrey</label>
						<select v-model="modele_groupe">
							<option value="">Choisissez...</option>
							<option v-for="item in categories.groupe_mauffrey" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</select>
					</div>
					<div class="form-group half">
						<label>Géographie</label>
						<select v-model="modele_geographie">
							<option value="">Choisissez...</option>
							<option v-for="item in categories.geographie" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</select>
					</div>
					<div class="form-group half">
						<label>Qualité</label>
						<select v-model="modele_qualite">
							<option value="">Choisissez...</option>
							<option v-for="item in categories.qualite" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</select>
					</div>
					<div class="form-group half">
						<label>Formation</label>
						<select v-model="modele_formation">
							<option value="">Choisissez...</option>
							<option v-for="item in categories.formation" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</select>
					</div>
					<div class="form-group half">
						<label>Digitalisation</label>
						<select v-model="modele_digitalisation">
							<option value="">Choisissez...</option>
							<option v-for="item in categories.digitalisation" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</select>
					</div>
					<div class="form-group half">
						<label>Métiers</label>
						<select v-model="modele_prestations">
							<option value="">Choisissez...</option>
							<option v-for="item in categories.metiers" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</select>
					</div>
					<div class="form-group half">
						<label>Filière</label>
						<select v-model="modele_filieres">
							<option value="">Choisissez...</option>
							<option v-for="item in categories.filieres" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</select>
					</div>
					<div class="form-group half">
						<label>Matériel</label>
						<select v-model="modele_materiel">
							<option value="">Choisissez...</option>
							<option v-for="item in categories.materiel" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</select>
					</div>
					<div class="form-group half">
						<label>Toolbox</label>
						<select v-model="modele_toolbox">
							<option value="">Choisissez...</option>
							<option v-for="item in categories.toolbox" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</select>
					</div>
					<div class="form-group half">
						<label>Procédures</label>
						<select v-model="modele_procedures">
							<option value="">Choisissez...</option>
							<option v-for="item in categories.procedures" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</select>
					</div>
					<div class="form-group full">
						<label>Tags</label>
						<vue-tags-input placeholder="Ajouter un tag" v-model="modele_tag" :tags="modele_tags" @tags-changed="(newTags) => (modele_tags = newTags)" />
					</div>
					<div class="save" v-if="ready_save_modele">
						<button v-if="!replace" @click="reallyCreateModele()">Sauvegarder en tant que modèle</button>
						<button v-if="replace" @click="reallyCreateModele()">Remplacer le modèle</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Dialog } from "vant";
import VueTagsInput from "@johmun/vue-tags-input";
import { mapGetters, mapMutations } from "vuex";

export default {
	name: "createSlideZone",
	components: { VueTagsInput },
	data() {
		return {
			modele_groupe: "",
			modele_geographie: "",
			modele_qualite: "",
			modele_formation: "",
			modele_prestations: "",
			modele_digitalisation: "",
			modele_filieres: "",
			modele_materiel: "",
			modele_toolbox: "",
			modele_procedures: "",
			modele_tag: "",
			modele_tags: [],
			modele_name: "",
			modele_type: "slide",
			retour: null,
			replace: false,
			replace_id: null,
			is_focus: false,
		};
	},
	methods: {
		...mapMutations({
			showSlideCreate: "showSlideCreate",
		}),
		verifyName(e) {
			this.retour = "";
			this.replace = false;
			this.replace_id = null;
			this.modele_groupe = "";
			this.modele_geographie = "";
			this.modele_qualite = "";
			this.modele_formation = "";
			this.modele_toolbox = "";
			this.modele_prestations = "";
			this.modele_digitalisation = "";
			this.modele_filieres = "";
			this.modele_materiel = "";
			this.modele_procedures = "";
			this.modele_tags = [];
			this.modele_type = "slide";
			this.$store.state.modeles.forEach((el) => {
				if (el.fields.nom.toUpperCase().trim() == this.modele_name.toUpperCase().trim()) {
					this.retour = "Le nom existe. Le modèle sera remplacé.";
					this.replace = true;
					this.replace_id = el.id;
					if (el.fields.groupe) {
						this.modele_groupe = el.fields.groupe[0];
					}
					if (el.fields.geographie) {
						this.modele_geographie = el.fields.geographie[0];
					}
					if (el.fields.qualite) {
						this.modele_qualite = el.fields.qualite[0];
					}
					if (el.fields.formation) {
						this.modele_formation = el.fields.formation[0];
					}
					if (el.fields.prestations) {
						this.modele_prestations = el.fields.prestations[0];
					}
					if (el.fields.digitalisation) {
						this.modele_digitalisation = el.fields.digitalisation[0];
					}
					if (el.fields.filieres) {
						this.modele_filieres = el.fields.filieres[0];
					}
					if (el.fields.materiel) {
						this.modele_materiel = el.fields.materiel[0];
					}
					if (el.fields.procedures) {
						this.modele_procedures = el.fields.procedures[0];
					}
					if (el.fields.toolbox) {
						this.modele_toolbox = el.fields.toolbox[0];
					}
					if (el.fields.tags) {
						this.modele_tags = JSON.parse(el.fields.tags);
					}
					if (el.fields.type) {
						this.modele_type = el.fields.type;
					}
				}
			});
		},
		reallyCreateModele() {
			this.$store
				.dispatch("reallyCreateModele", {
					nom: this.modele_name,
					tags: JSON.stringify(this.modele_tags),
					type: this.modele_type,
					filieres: [this.modele_filieres],
					prestations: [this.modele_prestations],
					procedures: [this.modele_procedures],
					toolbox: [this.modele_toolbox],
					materiel: [this.modele_materiel],
					digitalisation: [this.modele_digitalisation],
					formation: [this.modele_formation],
					qualite: [this.modele_qualite],
					geographie: [this.modele_geographie],
					groupe: [this.modele_groupe],
					replace: this.replace,
					replace_id: this.replace_id,
				})
				.then(() => {
					this.modele_tags = [];
					this.modele_name = "";
					this.modele_type = "slide";
					this.modele_filieres = "";
					this.modele_prestations = "";
					this.modele_groupe = "";
					this.modele_materiel = "";
					this.modele_procedures = "";
					this.modele_toolbox = "";
					this.modele_digitalisation = "";
					this.modele_formation = "";
					this.modele_qualite = "";
					this.modele_geographie = "";
					this.retour = null;
					this.replace = false;
					this.replace_id = null;
				});
		},
		setModeleName(e) {
			this.modele_name = e.currentTarget.innerHTML;
			this.is_focus = false;
			this.verifyName();
		},
	},
	computed: {
		ready_save_modele() {
			return this.modele_name.length >= 3;
		},
		myOptions() {
			let temp = [];
			this.$store.state.modeles.forEach((item) => {
				if (item.fields.nom.trim().toUpperCase().includes(this.modele_name.trim().toUpperCase())) {
					temp.push(item.fields.nom);
				}
			});
			return temp;
		},
		...mapGetters({
			showOverlaySlideCreate: "showOverlaySlideCreate",
			prestations: "prestations",
			filieres: "filieres",
			slideModelePreview: "slideModelePreview",
			categories: "categories",
			currentUser: "currentUser",
		}),
	},
};
</script>

<style lang="scss">
#createSlideZone {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba($gris, 0.97);
	backdrop-filter: blur(20px) saturate(20);
	z-index: 88;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	border-radius: 0px;
	.box {
		padding: 50px;
		background: white;
		border-radius: 0px;
		box-shadow: 2px 3px 20px rgba($black, 0.1);
		font-size: 12px;
		max-width: 960px;
		animation: appearFromTop 0.5s ease backwards;
		animation-delay: 0.2s;
		color: rgba(black, 0.7);
		position: relative;
		height: 100vh;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		.closeOverlay {
			position: absolute;
			width: 50px;
			height: 50px;
			top: 0;
			right: 0;
			background: white;
			border-radius: 0 0 0 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 20px;
			cursor: pointer;
			transition: all 0.3s ease;
			box-shadow: inset 2px -2px 4px rgba(black, 0.05);
			background: rgba($rouge, 0.1);
			color: $rouge;
			&:hover {
				box-shadow: inset 2px -2px 10px rgba($rouge, 0.2);
				background: $rouge;
				color: white;
			}
		}
		p {
			font-size: 13px;
			line-height: 20px;
			font-weight: 300;
			max-width: 60%;
			strong {
				font-weight: 500;
			}
		}
		h3 {
			font-weight: 500;
			margin-bottom: 20px;
		}
		.thebarre {
			margin: 30px 0 10px 0;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 10px;
			background: $gris;
			padding: 10px;
			border-radius: 20px;
			select {
				width: 100%;
				background: white;
				height: 40px;
				border: none;
				border-radius: 20px;
				padding: 0 10px;
				font-size: 13px;
				appearance: none;
				font-weight: 500;
				transition: all 0.3s ease;
				outline: none;
				box-shadow: inset 1px 1px 4px rgba(black, 0.1);
			}
			.search {
				flex: 0 0 33.3333%;
				width: 100%;
				background: white;
				height: 40px;
				border: none;
				border-radius: 20px;
				padding: 0 10px;
				font-size: 13px;
				appearance: none;
				font-weight: 500;
				transition: all 0.3s ease;
				outline: none;
				display: flex;
				align-items: center;
				box-shadow: inset 1px 1px 4px rgba(black, 0.1);
				input {
					flex: 1;
					appearance: none;
					border: none;
					height: 40px;
					background: transparent;
					padding: 0 0 0 10px;
					font-size: 12px;
				}
				button {
					background: none;
					color: rgba(black, 0.5);
					padding: 0 0 0 10px;
					margin: 0;
					flex: 0 0 auto;
					font-size: 13px;
				}
			}
		}
		.gallery {
			display: grid;
			gap: 20px;
			grid-template-columns: repeat(3, 1fr);
			background: $gris;
			padding: 30px;
			border-radius: 20px;
			.gallery-item {
				padding: 20px;
				background: white;
				border-radius: 10px;
				box-shadow: 1px 1px 2px rgba(black, 0.05);
				display: flex;
				flex-direction: column;
				text-align: center;
				font-weight: 500;
				justify-content: center;
				align-items: center;
				transition: all 0.2s ease;
				border: solid 2px transparent;
				.infos {
					padding: 10px 10px 0;
					.hidden_fields {
						display: none;
					}
					label {
						font-size: 12px;
						font-weight: 500;
						text-align: center;
						padding: 0px 0px 2px;
						display: block;
					}
					.name {
						font-size: 10px;
						font-style: italic;
						color: rgba(black, 0.5);
					}
				}
				.image {
					width: 100%;
					display: block;
					position: relative;
					img {
						position: absolute;
						width: 100%;
						height: 100%;
						object-fit: cover;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
					&:before {
						display: block;
						content: "";
						padding-bottom: 56.25%;
					}
				}
				&:hover {
					animation: hand alternate-reverse infinite 1s ease-in-out;
					filter: drop-shadow(4px 4px 16px rgba(black, 0.1));
				}
				&.selected {
					border: solid 2px $bleu;
				}
				&.hidden {
					display: none;
				}
				&:first-child.hidden {
					display: flex;
				}
			}
		}
		.buttons {
			display: flex;
			justify-content: flex-end;
			padding: 20px 0 0;
			button {
				background: $bleu;
				display: flex;
				align-items: center;
				i {
					margin-left: 10px;
					transform: translateY(2px);
				}
			}
		}
		.formulaire {
			display: flex;
			margin: 30px auto;
			align-items: flex-start;
			.apercu {
				flex: 1;
				position: relative;
				margin-right: 30px;
				img {
					width: 100%;
					height: auto;
					box-shadow: 4px 4px 6px rgba(black, 0.1);
					border-radius: 8px;
				}
			}
			.droite {
				flex: 2;
				display: grid;
				gap: 10px;
				.save {
					display: flex;
					justify-content: flex-end;
					padding: 20px 0 0;
					button {
						background: $rouge;
						&:hover {
							background: darken($rouge, 5%);
							animation: hand 1s ease-in-out alternate-reverse infinite;
						}
					}
				}
				.form-group {
					background: $gris;
					padding: 16px;
					border-radius: 20px;
					display: flex;
					align-items: center;
					grid-column: span 2;
					position: relative;
					&.name {
						ul.suggestions {
							position: absolute;
							top: 40px;
							left: 40px;
							z-index: 10;
							background: rgba($gris, 0.9);
							backdrop-filter: blur(6px);
							padding: 20px;
							box-shadow: 4px 4px 12px rgba($black, 0.1);
							max-height: 200px;
							overflow-x: hidden;
							overflow-y: auto;
							-webkit-overflow-scrolling: touch;
							list-style: none;
							margin: 0;
							padding: 0;
							h4 {
								font-size: 9px;
								text-transform: uppercase;
								padding: 16px 16px 8px;
								font-weight: 500;
								color: $vert;
							}
							.suggestion {
								padding: 12px 16px;
								font-size: 13px;
								font-weight: 500;
								cursor: pointer;
								&:hover {
									background: $vertclair;
									color: $vert;
								}
							}
						}
					}
					.retour_direct {
						position: absolute;
						bottom: 5px;
						left: 60px;
						color: $vert;
						font-size: 9px;
					}
					.select2222 {
						width: 100%;
						flex: 1;
					}
					label {
						font-weight: 500;
						font-size: 9px;
						text-transform: uppercase;
						display: block;
						color: $rouge;
						text-align: right;
						flex: 0 0 auto;
						padding-right: 10px;
						border-right: solid 1px rgba(black, 0.1);
						margin-right: 10px;
					}
					input[type="text"],
					select {
						height: 100%;
						background: transparent;
						border: none;
						outline: none;
						width: 100%;
						font-size: 13px;
						color: black;
						font-weight: 500;
						padding: 0;
					}
					.myselect2 {
						width: 100%;
					}
					.select2-container {
						min-width: 100%;
					}
					.vue-tags-input {
						border-radius: 0;
						margin: 0;
						.ti-input {
							padding: 0;
						}
					}
				}
				.vue-tags-input {
					border-radius: 20px;
					background: $gris;
					border: none;
					margin: 20px 0 0;
					transition: all 0.3s ease;
					&:hover {
						box-shadow: inset 2px 2px 4px rgba(black, 0.03);
					}
					.ti-input {
						border: none;
						padding: 16px;
						.ti-tag {
							border-radius: 3px;
							background: $vertclair;
							color: $vert;
							font-weight: 500;
							font-size: 12px;
							padding: 8px;
						}
						.ti-new-tag-input-wrapper {
							input {
								background: transparent;
							}
						}
					}
				}
			}
		}
	}
}
</style>
