<template>
	<div id="app">
		<Navigation v-if="$store.state.connected && window >= 1200" />
		<main v-show="window >= 1200">
			<transition name="van-fade"><router-view /></transition>
		</main>
		<div id="responsive-blocking" v-show="window < 1200">
			<img src="./assets/logo.png" />
			<i class="fal fa-sad-tear"></i>
			<p><em>Désolé</em>la Ruche n'est pas utilisable dans votre résolution d'écran.</p>
		</div>
		<van-overlay id="main_overlay" :show="appLoading" @click="appLoading = false">
			<div class="close" @click="appLoading = false"><i class="fal fa-times"></i></div>
			<div class="wrapper">
				<div class="loading"><i class="fal fa-spin fa-spinner"></i></div>
				<div class="texte" v-html="appLoadingText"></div>
			</div>
		</van-overlay>
		<div class="notices" v-if="havenotices">
			<div class="notice" v-if="notices.prestations">
				<i class="fal fa-spin fa-spinner-third"></i>
				Chargement des rubriques prestations
			</div>
			<div class="notice" v-if="notices.filieres">
				<i class="fal fa-spin fa-spinner-third"></i>
				Chargement des rubriques filières
			</div>
			<div class="notice" v-if="notices.procedures">
				<i class="fal fa-spin fa-spinner-third"></i>
				Chargement des rubriques procédures
			</div>
			<div class="notice" v-if="notices.toolbox">
				<i class="fal fa-spin fa-spinner-third"></i>
				Chargement des rubriques Toolbox
			</div>
			<div class="notice" v-if="notices.digitalisation">
				<i class="fal fa-spin fa-spinner-third"></i>
				Chargement des rubriques Digitalisation
			</div>
			<div class="notice" v-if="notices.formation">
				<i class="fal fa-spin fa-spinner-third"></i>
				Chargement des rubriques Formation
			</div>
			<div class="notice" v-if="notices.materiel">
				<i class="fal fa-spin fa-spinner-third"></i>
				Chargement des rubriques Matériel
			</div>
			<div class="notice" v-if="notices.qualite">
				<i class="fal fa-spin fa-spinner-third"></i>
				Chargement des rubriques Qualité
			</div>
			<div class="notice" v-if="notices.groupes">
				<i class="fal fa-spin fa-spinner-third"></i>
				Chargement des rubriques Groupes
			</div>
			<div class="notice" v-if="notices.globales">
				<i class="fal fa-spin fa-spinner-third"></i>
				Chargement des données globales
			</div>
			<div class="notice" v-if="notices.squelettes">
				<i class="fal fa-spin fa-spinner-third"></i>
				Chargement des Squelettes
			</div>
			<div class="notice" v-if="notices.modeles">
				<i class="fal fa-spin fa-spinner-third"></i>
				Chargement des Modèles et des squelettes
			</div>
			<div class="notice" v-if="notices.geographie">
				<i class="fal fa-spin fa-spinner-third"></i>
				Chargement des rubriques Géographie
			</div>
			<div class="notice" v-if="notices.classement">
				<i class="fal fa-spin fa-spinner-third"></i>
				Chargement des catégories
			</div>
		</div>
	</div>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
	name: "App",
	components: { Navigation },
	data() {
		return {
			window: window.innerWidth,
		};
	},
	computed: {
		...mapGetters({
			user: "currentUser",
			connected: "connected",
			appLoading: "appLoading",
			appLoadingText: "appLoadingText",
			notices: "notices",
			havenotices: "havenotices",
		}),
	},
	methods: {
		...mapMutations({
			showAppLoading: "showAppLoading",
			hideAppLoading: "hideAppLoading",
		}),
		...mapActions({
			getNewGlobales: "getNewGlobales",
			getPrestations: "getPrestations",
			getNewModeles: "getNewModeles",
			getFilieres: "getFilieres",
			getProcedures: "getProcedures",
			getToolbox: "getToolbox",
			getMateriel: "getMateriel",
			getDigitalisation: "getDigitalisation",
			getFormation: "getFormation",
			getQualite: "getQualite",
			getGeographie: "getGeographie",
			getGroupe: "getGroupe",
			getGlobales: "getGlobales",
			getModeles: "getModeles",
			getSquelettes: "getSquelettes",
			getClassement: "getNewClassement",
		}),
	},
	mounted() {
		this.showAppLoading("Chargement des catégories...");
		if (this.connected && this.user.id) {
			Promise.all([this.getNewModeles(), this.getClassement(), this.getNewGlobales()]).then((values) => {
				this.hideAppLoading();
			});
		} else {
			this.hideAppLoading();
		}
		window.addEventListener(
			"resize",
			(e) => {
				this.window = window.innerWidth;
			},
			{ passive: true }
		);
	},
};
</script>
<style lang="scss">
* {
	box-sizing: border-box;
	text-decoration: none;
	font-weight: normal;
	margin: 0;
	padding: 0;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
	&::-webkit-scrollbar {
		//display: none;
	}

	u {
		text-decoration: underline;
	}
}
body {
	font-family: "Arial", sans-serif;
	margin: 0;
	padding: 0;
	font-size: 14px;
	line-height: 1;
	background: white;
	.notices {
		display: flex;
		position: fixed;
		bottom: 10px;
		right: 10px;
		padding: 40px;
		background: white;
		border: solid 1px rgba(black, 0.05);
		box-shadow: 0px 20px 20px rgba(black, 0.2);
		border-radius: 15px;
		flex-direction: column;
		gap: 5px;
		z-index: 999;
		.notice {
			display: flex;
			align-items: center;
			gap: 10px;
			font-size: 14px;
			i,
			svg {
				color: $rouge;
			}
			font-weight: 500;
		}
	}
	p {
		line-height: 1.4;
		font-weight: 300;
	}
	h1 {
		font-size: 40px;
		font-weight: 900;
	}
	h2 {
		font-size: 30px;
		font-weight: bold;
	}
	h3 {
		font-size: 24px;
		font-weight: bold;
	}
	h4 {
		font-size: 20px;
		font-weight: bold;
	}
	h5 {
		font-size: 16px;
		font-weight: 500;
	}
	ul {
		list-style-position: inside;
		margin: 20px 0 40px 0;
	}
	button:not(.van-button),
	.button {
		background: $rouge;
		color: white;
		font-weight: 500;
		padding: 10px 20px;
		display: flex;
		align-items: center;
		border: none;
		font-size: 15px;
		cursor: pointer;
		transition: all 0.3s ease;
		border-radius: 30px;
		outline-color: rgba(#e30613, 0.4);
		outline-width: 10px;
		&.flat {
			background: transparent;
			color: black;
		}
		i {
			font-size: 1.4em;
			vertical-align: middle;
			margin-right: 10px;
		}
		&:hover {
			transform: translateY(10%);
			background: darken($rouge, 5%);
			&.flat {
				background: transparent;
				color: $rouge;
			}
		}
	}
	#app {
		padding-left: 80px;
		main {
			background: $gris;
		}
		#main_overlay {
			z-index: 9999;
			background: white;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 12px;
			.close {
				position: absolute;
				top: 30px;
				right: 30px;
				z-index: 99;
				cursor: pointer;
				font-size: 20px;
				&:hover {
					color: $rouge;
				}
			}
			div.texte {
				font-weight: 500;
				margin: 20px;
				line-height: 18px;
			}
			img {
				width: 240px;
			}
		}
	}
	#responsive-blocking {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		p {
			max-width: 280px;
			text-align: center;
			margin: 20px auto;
			font-size: 14px;
			line-height: 20px;
			font-weight: 500;
			em {
				display: block;
				color: $rouge;
				font-size: 16px;
				font-weight: 500;
				font-style: normal;
			}
		}

		i {
			font-size: 30px;
		}
		img {
			width: 180px;
			height: auto;
		}
	}
}
</style>
