<template>
	<section id="dashboard">
		<Header />
		<Introduction v-if="$store.state.user.fields.role != 'collaborateur'" />
		<div v-else class="accueil-collaborateur">
			<strong>Merci pour votre connexion à la RUCHE. </strong>
			<br />Vous n'avez pas encore été autorisé.e à utiliser la plateforme. <br />Revenez ultérieurement afin de vérifier votre éligibilité.
		</div>
	</section>
</template>

<script>
import Introduction from "../components/dashboard/introduction";
import Header from "../components/Header";
export default {
	name: "Dashboard",
	components: { Introduction, Header },
};
</script>

<style lang="scss" scoped>
#dashboard {
	background: white;
	width: 100%;
	height: 100vh;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	background-image: url("../assets/img/fondruche3-bis.jpg");
	background-position: bottom center;
	background-size: cover;
	.accueil-collaborateur {
		padding: 100px 30px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 300;
		text-align: center;
		strong {
			font-size: 20px;
			line-height: 36px;
			font-weight: 500;
		}
	}
}
</style>
