<template>
	<div id="topbar">
		<div class="pagename">
			{{ $route.name }}
			<portal-target name="teleportmachin"></portal-target>
			<div class="createModele" @click="new_modele = true" v-if="$route.path === '/modeles'">Nouveau modèle</div>
			<div class="createModele" @click="new_squelette = true" v-if="$route.path === '/squelettes'">Nouveau squelette</div>
		</div>
		<div class="titles">
			Bonjour &#128515; , <strong>{{ currentUser.fields.nom }} !</strong>
			<!-- <div id="refresh" @click="refresh()"><i class="fal fa-sync"></i></div> -->
			<div id="logout" @click="logout()"><i class="fal fa-power-off"></i></div>
		</div>
		<Transition name="create">
			<div v-if="new_modele" id="create_modele">
				<div class="close" @click="new_modele = false"><i class="fa-solid fa-times"></i></div>
				<div class="steps">
					<div :class="`step ${step1 ? 'active' : ''}`">
						<div class="top">
							Etape 1
							<span>Nommez votre modèle</span>
						</div>
						<div class="next">
							<button @click="step2 = true">Suivant</button>
						</div>
					</div>
					<div :class="`step ${step2 ? 'active' : ''}`">
						<div class="top">
							Etape 2
							<span>Catégorisez le</span>
						</div>
					</div>
				</div>
				<div class="step-content" v-if="step1 && !step2">
					<p>Choisissez un nom clair, qui sera facilement identifiable et trouvable lors de la création d'un draft ou d'un squelette.</p>
					<input type="text" minlength="3" maxlength="30" v-model="modele.new_modele_name" placeholder="Mon super modèle" />
				</div>
				<div class="step-content" v-if="step2">
					<p>
						Choisissez un type de modèle : <br />› <strong>Mise en forme</strong> concerne les modèles qui aident seulement à la mise en forme. <br />›
						<strong>Prêt à l'emploi</strong> concerne les modèles dont le contenu peut être utilisé tel quel.
					</p>
					<select v-model="modele.new_modele_type">
						<option value="slide_vierge">Mise en forme</option>
						<option value="slide">Prêt à l'emploi</option>
					</select>
					<br />
					
					<div class="form-group" style="margin-top: 20px">
						<p><strong>Langue</strong></p>
						<select v-model="modele.new_modele_langue">
							<option value="français">Français</option>
							<option value="english">English</option>
							<option value="espanol">Espanol</option>
						</select>
					</div>
					
					<div class="form-group" style="display: flex; align-items: center; gap: 10px; margin-top: 20px">
						<p>
							<strong>Rendre privé</strong><br />Cochez pour rendre privé. Le modèle ne pourra pas être utilisé par les utilisateurs standards dans les
							drafts. Ils vous permettra par contre de construire vos squelettes.
						</p>
						<van-switch id="private" v-model="modele.prive" size="20px" active-color="#e30613" />
					</div>
				</div>
				<div class="navigation">
					<button @click="step2 = true" v-if="!step2 && modele.new_modele_name.length >= 3">Etape suivante <i class="fa-light fa-angle-right"></i></button>
					<button class="nu" @click="step2 = false" v-if="step2"><i class="fa-light fa-angle-left"></i> Etape précédente</button>
					<button @click="createEmptyModele(modele)" v-if="step2">Créer le modèle <i class="fa-regular fa-save"></i></button>
				</div>
			</div>
		</Transition>
		<Transition name="create">
			<div v-if="new_squelette" id="create_modele">
				<div class="close" @click="new_squelette = false"><i class="fa-solid fa-times"></i></div>
				<div class="steps">
					<div :class="`step ${step1 ? 'active' : ''}`">
						<div class="top">
							Etape 1
							<span>Nommez votre squelette</span>
						</div>
						<div class="next">
							<button @click="step2 = true">Suivant</button>
						</div>
					</div>
					<div :class="`step ${step2 ? 'active' : ''}`">
						<div class="top">
							Etape 2
							<span>Ajoutez quelques tags</span>
						</div>
					</div>
				</div>
				<div class="step-content" v-if="step1 && !step2">
					<p>Choisissez un nom facilement identifiable lors de la création d'un draft.</p>
					<input type="text" minlength="3" maxlength="30" v-model="squelette.new_modele_name" placeholder="Mon super squelette" />
				</div>
				<div class="step-content" v-if="step2">
					<p>Ajoutez quelques mots clés, séparés par une virgule, afin de retrouver plus facilement votre squelette</p>
					<input type="text" minlength="3" maxlength="100" v-model="squelette.tags" placeholder="Mot clé 1, mot clé 2, ..." />
					<br /><br />
					<p>Dans quelle langue sera votre squelette</p>
					<select v-model="squelette.langue">
						<option value="français">Français</option>
						<option value="english">English</option>
						<option value="espanol">Espanol</option>
					</select>
				</div>
				<div class="navigation">
					<button @click="step2 = true" v-if="!step2 && squelette.new_modele_name.length >= 3">Etape suivante <i class="fa-light fa-angle-right"></i></button>
					<button class="nu" @click="step2 = false" v-if="step2"><i class="fa-light fa-angle-left"></i> Etape précédente</button>
					<button @click="createEmptySquelette(squelette)" v-if="step2">Créer le squelette <i class="fa-solid fa-skull"></i></button>
				</div>
			</div>
		</Transition>
	</div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
export default {
	name: "Header",
	components: {},
	data() {
		return {
			new_modele: false,
			new_squelette: false,
			new_draft: false,
			step1: true,
			step2: false,
			squelette: {
				new_modele_name: "",
				langue: "français",
				tags: "",
			},
			modele: {
				new_modele_name: "",
				new_modele_type: "slide",
				new_modele_langue: "français",
				prive: false,
			},
		};
	},
	computed: {
		...mapGetters({
			currentUser: "currentUser",
		}),
	},
	methods: {
		createModele() {},
		refresh() {
			this.getClassement();
			this.getNewGlobales();
			this.getNewModeles();
			this.getPresentations();
		},
		...mapActions({
			getNewGlobales: "getNewGlobales",
			getClassement: "getNewClassement",
			getNewModeles: "getNewModeles",
			getModeles: "getModeles",
			getPresentations: "getPresentations",
			getPrestations: "getPrestations",
			getFilieres: "getFilieres",
			getProcedures: "getProcedures",
			getToolbox: "getToolbox",
			getMateriel: "getMateriel",
			getDigitalisation: "getDigitalisation",
			getFormation: "getFormation",
			getQualite: "getQualite",
			getGeographie: "getGeographie",
			getGroupe: "getGroupe",
			getTags: "getTags",
			createEmptyModele: "createEmptyModele",
			createEmptySquelette: "createEmptySquelette",
		}),
		...mapMutations({
			logout: "logout",
		}),
	},
};
</script>
<style lang="scss">
.create-enter-active,
.create-leave-active {
	opacity: 1;
	transform: translateX(-50%) translateY(0);
}
.create-enter-from,
.create-leave-to {
	opacity: 0;
	transform: translateX(-50%) translateY(-30%);
}
#topbar {
	background: white;
	padding: 20px 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.pagename {
		font-size: 24px;
		font-weight: 500;
		display: flex;
		align-items: flex-end;
		.createModele {
			font-size: 12px;
			background: $rouge;
			color: white;
			padding: 0.4em 0.8em;
			border-radius: 4px;
			margin-left: 20px;
			cursor: pointer;
			transition: all 0.3s ease;
			&:hover {
				transform: translateY(3px);
				background: darken($rouge, 5%);
				box-shadow: 1px 1px 6px rgba(black, 0.1);
			}
		}
	}
	.titles {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 16px;
		line-height: 16px;
		font-weight: normal;
		strong {
			font-weight: 600;
			padding-left: 6px;
		}
		#logout,
		#refresh {
			width: 40px;
			margin-left: 10px;
			height: 40px;
			background: $gris;
			color: black;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			border-radius: 50%;
			cursor: pointer;
			transition: all 0.3s ease;
			&:hover {
				transform: translateY(3px);
				background: $rouge;
				color: white;
			}
		}
	}
}
#create_modele {
	position: fixed;
	top: 80px;
	left: 50%;
	padding: 40px 40px 0 40px;
	background: #fafafa;
	border: solid 2px white;
	box-shadow: 4px 4px 100px rgba(black, 0.3);
	z-index: 9999;
	border-radius: 8px;
	width: 800px;
	max-width: 100%;
	transform: translateX(-50%);
	transition: all 0.5s ease;
	gap: 10px;
	.close {
		position: absolute;
		top: -2px;
		right: -2px;
		width: 40px;
		height: 40px;
		border-radius: 0 0 0 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $rougeclair;
		z-index: 99;
		cursor: pointer;
		color: $rouge;
		&:hover {
			background: $rouge;
			color: white;
		}
	}
	.steps {
		display: flex;
		width: 100%;
		gap: 20px;
		.step {
			flex: 1;
			.top {
				display: flex;
				flex-direction: column;
				margin-bottom: 30px;
				padding-top: 10px;
				border-top: solid 3px rgba(black, 0.1);
				font-size: 12px;
				color: slategray;
				text-transform: uppercase;
				font-weight: 600;
				letter-spacing: 0.3px;
				span {
					color: slategray;
					font-size: 14px;
					font-weight: 500;
					line-height: 20px;
					text-transform: none;
				}
			}
			.next {
				display: none;
			}
			&.active {
				.top {
					border-color: $rouge;
					color: $rouge;
					span {
						color: black;
					}
				}
			}
		}
	}
	.navigation {
		padding: 20px 0 20px;
		border-top: solid 1px rgba(black, 0.04);
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 20px;
		button {
			padding: 10px 20px;
			height: auto;
			display: flex;
			border-radius: 4px;
			font-size: 13px;
			align-items: center;
			gap: 10px;
			i,
			svg {
				font-size: 13px;
			}
			&.nu {
				background: transparent;
				color: $black;
				gap: 5px;
			}
		}
	}
	.step-content {
		padding: 20px 0;
		p {
			font-size: 13px;
			line-height: 20px;
			margin: 0 0 10px 0;
			max-width: 70%;
			color: slategray;
		}
		input[type="text"],
		select {
			width: 100%;
			height: 40px;
			background: rgba(black, 0.04);
			border: none;
			padding: 0 20px;
			font-size: 13px;
			border-radius: 4px;
		}
	}
}
</style>
