import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store.js";
import Vant from "vant";
import "vant/lib/index.css";
import PortalVue from "portal-vue";

Vue.use(PortalVue);
Vue.config.productionTip = false;
Vue.use(Vant);
Vue.use(require("vue-moment"));

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
