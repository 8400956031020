<template>
	<nav id="mainnav">
		<div class="top">
			<div class="logo">
				<img loading="lazy" src="../assets/logo.png" />
			</div>
		</div>
		<div class="menus">
			<router-link to="/">
				<i class="fal fa-home"></i>
				<span>Tableau de bord</span>
			</router-link>
			<router-link to="/modeles" v-if="currentUser.fields.role === 'super_administrateur' || currentUser.fields.role === 'administrateur'">
				<i class="fal fa-window"></i>
				<span>Gérer les modèles</span>
			</router-link>
			<!-- <router-link to="/squelettes" v-if="currentUser.fields.role === 'super_administrateur' || currentUser.fields.role === 'administrateur'">
				<i class="fal fa-skeleton"></i>
				<span> Gérer les squelettes</span>
			</router-link> -->
			<router-link to="/squelettes">
				<i class="fal fa-skeleton"></i>
				<span> Gérer les squelettes</span>
			</router-link>
			<router-link to="/globales" v-if="currentUser.fields.role === 'super_administrateur' || currentUser.fields.role === 'administrateur'">
				<i class="far fa-brackets-square"></i>
				<span> Gérer les données globales</span>
			</router-link>

			<router-link to="/utilisateurs" v-if="currentUser.fields.role === 'super_administrateur'">
				<i class="fal fa-users-cog"></i>
				<span> Gérer les utilisateurs</span>
			</router-link>
			<router-link to="/categories" v-if="currentUser.fields.role === 'super_administrateur' || currentUser.fields.role === 'administrateur'">
				<i class="fal fa-folder-tree"></i>
				<span> Gérer les catégories</span>
			</router-link>
			<router-link to="/tutoriels" v-if="$store.state.user.fields.role != 'collaborateur'">
				<i class="fal fa-graduation-cap"></i>
				<span>Tous les tutoriels</span>
			</router-link>
			<router-link to="/messagerie" v-if="$store.state.user.fields.role != 'collaborateur'">
				<i class="fal fa-envelope"></i>
				<span>Messagerie</span>
			</router-link>
		</div>
		<createZone />
		<createSlideZone />
	</nav>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import createZone from "../components/createzone";
import createSlideZone from "../components/createSlideZone";
export default {
	name: "Navigation",
	components: { createZone, createSlideZone },
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			currentUser: "currentUser",
		}),
	},
	methods: {
		...mapMutations({
			showCreate: "showCreate",
		}),
		showCreateWithRedirect() {
			if (this.$route.name != "Dashboard") {
				this.$router.push({ name: "Dashboard" });
			}
			this.showCreate();
		},
	},
};
</script>

<style lang="scss">
nav#mainnav {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 80px;
	z-index: 99;
	flex: 0 0 auto;
	height: 100vh;
	overflow-x: visible;
	background: white;
	background-size: cover;
	color: white;
	border-right: solid 1px $gris;
	box-shadow: 2px 2px 10px rgba(black, 0.08);
	.top {
		position: relative;
		padding: 10px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.logo {
			img {
				width: 60px;
				height: auto;
			}
		}
	}
	.menus {
		position: relative;
		padding: 0px;
		margin-top: 30px;
		h6 {
			text-transform: uppercase;
			margin: 50px 0 10px 0;
			font-weight: 500;
			letter-spacing: 0.05em;
			opacity: 0.7;
			&:first-of-type {
				margin-top: 30px;
			}
		}
		a {
			display: flex;
			flex-direction: column;
			color: black;
			font-weight: 500;
			font-size: 13px;
			padding: 0;
			justify-content: center;
			text-align: center;
			align-items: center;
			transition: all 0.3s ease;
			position: relative;
			border-top: solid 1px $gris;
			border-right: solid 1px $gris;
			i {
				color: black;
				font-size: 24px;
				width: 80px;
				height: 60px;
				display: flex;
				justify-content: center;
				align-items: center;
				background: white;
				position: relative;
				z-index: 8;
			}

			span {
				background: white;
				position: absolute;
				left: 80%;
				padding: 16px;
				border-radius: 6px;
				box-shadow: 4px 0px 4px rgba(black, 0.08);
				flex: 0 0 auto;
				font-weight: 500;
				z-index: 9;
				word-break: keep-all;
				white-space: nowrap;
				top: 50%;
				transform: translateX(-10%) translateY(-50%);
				opacity: 0;
				transition: all 0.3s ease-out;
				display: none;
			}
			&.router-link-exact-active {
				border-right: 0px;
				i {
					color: $rouge;
				}
				&:hover {
					cursor: not-allowed;
					span {
						opacity: 0;
					}
				}
			}
			&:hover {
				color: $rouge;
				i {
					color: $rouge;
				}
				span {
					opacity: 1;
					display: block;
					animation: appearFromLeftSpecial 0.3s ease-out;
					transform: translateX(0%) translateY(-50%);
				}
			}
		}
	}
}
</style>
