<template>
	<section id="dashboardintroduction" class="gauche">
		<div class="loading" v-if="!ready">
			<div>
				<i class="fal fa-spin fa-spinner"></i>
				Nous recherchons vos présentations. <br />Merci pour votre patience...
			</div>
		</div>

		<div class="presentations" v-if="ready">
			<div class="nb_presentations" v-if="ready">Vous avez {{ presentations.length }} drafts</div>
			<div class="firstline">
				<div class="nom">Titre</div>
				<div class="auteur">Auteur</div>
				<div class="membres">Membres</div>
				<div class="modif">Dernière modification</div>
				<div class="statut">Statut</div>
				<div class="actions">Actions</div>
			</div>
			<div :class="`presentation ${pres.fields.statut}`" v-for="pres in presentations" :key="pres.id">
				<div class="nom">
					<div class="modifnotif" v-if="pres.fields.en_cours_de_modif && pres.fields.modifie_par != currentUser.id">
						<i class="fas fa-lock"></i> En cours d'édition par {{ pres.fields.nom_modification[0] }}
					</div>
					<router-link :to="`presentation/${pres.id}`" v-if="!pres.fields.en_cours_de_modif || (pres.fields.modifie_par && pres.fields.modifie_par == currentUser.id)">{{ pres.fields.nom }}
					</router-link>
				</div>
				<div class="auteur">
					<span v-if="pres.fields.auteur_email[0] === currentUser.fields.email">Vous</span>
					<span v-else>{{ pres.fields.auteur_email[0] }}</span>
				</div>
				<div class="membres">
					<span v-if="pres.fields.intervenants == 1">Vous et {{ pres.fields.intervenants }} personne</span>
					<span v-if="pres.fields.intervenants > 1">Vous et {{ pres.fields.intervenants }} personnes</span>
					<span v-if="!pres.fields.intervenants && pres.fields.intervenants < 1">Aucun</span>
				</div>
				<div class="modif">
					{{ pres.fields.date_modifie_formatee }}
				</div>
				<div :class="`statut ${pres.fields.statut}`">{{ pres.fields.statut_formate }}</div>
				<div class="actions">
					<router-link
						:to="`presentation/${pres.id}`"
						v-if="!pres.fields.en_cours_de_modif || (pres.fields.modifie_par && pres.fields.modifie_par == currentUser.id)"
						><i class="fas fa-pen"></i
					></router-link>
					<button @click="deletePresentation(pres.id)"><i class="fas fa-trash"></i></button>
				</div>
			</div>
		</div>
		<!-- <button class="button create" @click="showCreateWithRedirect()">Créer un nouveau draft</button> -->
		<button class="button create" @click="new_draft = true">Créer un nouveau draft</button>
		<portal to="teleportmachin">
			<div class="createModele" @click="new_draft = true" v-if="$route.path === '/'">Nouveau draft</div>
		</portal>

		<Transition name="create">
			<div v-if="new_draft" id="create_draft">
				<div class="close" @click="new_draft = false"><i class="fa-solid fa-times"></i></div>
				<div class="steps">
					<div :class="`step ${step1 ? 'active' : ''}`">
						<div class="top">
							Etape 1
							<span>Nommez votre draft</span>
						</div>
						<div class="next">
							<button @click="step2 = true">Suivant</button>
						</div>
					</div>
					<div :class="`step ${step2 ? 'active' : ''}`">
						<div class="top">
							Etape 2
							<span>Nommez votre client</span>
						</div>
					</div>
					<!-- <div :class="`step ${step3 ? 'active' : ''}`">
						<div class="top">
							Etape 3
							<span>Collaborez</span>
						</div>
					</div> -->
				</div>
				<div class="step-content" v-if="step1 && !step2 && !step3">
					<p>Choisissez un nom facilement identifiable afin de le repérer en un coup d'oeil.</p>
					<input type="text" minlength="3" maxlength="30" v-model="draft.nom" placeholder="Le nom de mon draft" />
				</div>
				<div class="step-content" v-if="step2 && !step3">
					<p>A quel client le draft est-il destiné ?</p>
					<input type="text" minlength="3" maxlength="40" v-model="draft.client" placeholder="Thiriet SA" />

					<p>Quelle est la langue de votre présentation ?</p>
					<select v-model="draft.langue">
						<option value="français">Français</option>
						<option value="english">Anglais</option>
						<option value="espanol">Espagnol</option>
					</select>
				</div>
				<!-- <div class="step-content" v-if="step3">
					<p>Qui peut accéder à votre draft ?</p>
					<div class="collabs">
						<div :class="`user ${draft.collab.includes(user.id) ? 'checked' : ''}`" v-for="user in users" :key="user.id" @click="active_collab(user.id)">
							<div class="nom">{{ user.fields.nom }}</div>
							<div class="check">
								<i v-if="draft.collab.includes(user.id)" class="fa-regular fa-check"></i>
							</div>
						</div>
					</div>
				</div> -->
				<div class="navigation">
					<!-- Les boutons du step1 -->
					<button @click="step2 = true" v-if="!step2 && draft.nom.length >= 3">Etape suivante <i class="fa-light fa-angle-right"></i></button>

					<!-- Les boutons du step 3 -->
					<button class="nu" @click="step2 = false" v-if="step2"><i class="fa-light fa-angle-left"></i> Etape précédente</button>
					<button @click="createDraft(draft)" v-if="step2">Créer le draft</button>
				</div>
			</div>
		</Transition>
	</section>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { Notify } from "vant";
export default {
	name: "Introduction",
	components: {},
	data() {
		return {
			ready: false,
			autoRefresh: null,
			step1: true,
			step2: false,
			step3: false,
			new_draft: false,
			draft: {
				nom: "",
				client: "",
				langue: "français",
				collab: [],
			},
		};
	},
	computed: {
		...mapGetters({
			presentations: "presentations",
			currentUser: "currentUser",
			users: "users",
			showOverlayCreate: "showOverlayCreate",
		}),
	},
	methods: {
		active_collab(id) {
			if (this.draft.collab.includes(id)) {
				let index = this.draft.collab.findIndex((el) => el === id);
				if (index) this.draft.collab.splice(index, 1);
			} else {
				this.draft.collab.push(id);
			}
		},
		...mapMutations({
			showCreate: "showCreate",
		}),
		...mapActions({
			getPresentations: "getPresentations",
			deletePresentation: "deletePresentation",
			createDraft: "createDraft",
		}),
		showCreateWithRedirect() {
			if (this.$route.name != "Dashboard") {
				this.$router.push({ name: "Dashboard" });
			}
			this.showCreate();
		},
		openPrez(id, en_cours, qui) {
			if (en_cours && qui != this.currentUser.id) {
				Notify({
					type: "danger",
					message: "Impossible ! Cette présentation est déjà en cours de modification. Votre intervention risque d'effacer les autres modifications en train d'être apportées.",
					duration: 4000,
				});
			} else {
				this.$router.push({ name: "Présentation", params: { id } });
			}
		},
		refresh_presentation() {
			this.autoRefresh = setInterval(() => {
				let presentations = this.populateTableau();
			}, 30000);
		},
		populateTableau() {
			let myTable = document.getElementById("myTable");
			this.getPresentations()
				.then(() => {
					this.ready = true;
				})
				.catch((err) => {
					return;
				});
		},
	},
	mounted() {
		let myTable = document.getElementById("myTable");
		this.getPresentations()
			.then(() => (this.ready = true))
			.catch((err) => alert(err));
	},
	created() {
		//this.refresh_presentation();
	},
	beforeDestroy() {
		clearInterval(this.autoRefresh);
	},
};
</script>

<style lang="scss">
#create_draft {
	position: fixed;
	top: 80px;
	left: 50%;
	padding: 40px 40px 0 40px;
	background: #fafafa;
	border: solid 2px white;
	box-shadow: 4px 4px 100px rgba(black, 0.3);
	z-index: 9999;
	border-radius: 8px;
	width: 800px;
	max-width: 100%;
	transform: translateX(-50%);
	transition: all 0.5s ease;
	gap: 10px;
	.close {
		position: absolute;
		top: -2px;
		right: -2px;
		width: 40px;
		height: 40px;
		border-radius: 0 0 0 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $rougeclair;
		z-index: 99;
		cursor: pointer;
		color: $rouge;
		&:hover {
			background: $rouge;
			color: white;
		}
	}
	.steps {
		display: flex;
		width: 100%;
		gap: 20px;
		.step {
			flex: 1;
			.top {
				display: flex;
				flex-direction: column;
				margin-bottom: 30px;
				padding-top: 10px;
				border-top: solid 3px rgba(black, 0.1);
				font-size: 12px;
				color: slategray;
				text-transform: uppercase;
				font-weight: 600;
				letter-spacing: 0.3px;
				span {
					color: slategray;
					font-size: 14px;
					font-weight: 500;
					line-height: 20px;
					text-transform: none;
				}
			}
			.next {
				display: none;
			}
			&.active {
				.top {
					border-color: $rouge;
					color: $rouge;
					span {
						color: black;
					}
				}
			}
		}
	}
	.navigation {
		padding: 20px 0 20px;
		border-top: solid 1px rgba(black, 0.04);
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 20px;
		button {
			padding: 10px 20px;
			height: auto;
			display: flex;
			border-radius: 4px;
			font-size: 13px;
			align-items: center;
			gap: 10px;
			i,
			svg {
				font-size: 13px;
			}
			&.nu {
				background: transparent;
				color: $black;
				gap: 5px;
			}
		}
	}
	.step-content {
		padding: 20px 0;
		p {
			font-size: 13px;
			line-height: 20px;
			margin: 0 0 10px 0;
			max-width: 70%;
			color: slategray;
		}
		input[type="text"],
		select {
			width: 100%;
			height: 40px;
			background: rgba(black, 0.04);
			border: none;
			padding: 0 20px;
			font-size: 13px;
			border-radius: 4px;
		}
		.collabs {
			display: grid;
			gap: 10px;
			grid-template-columns: repeat(3, 1fr);
			.user {
				background: rgba(black, 0.02);
				padding: 10px 20px;
				border-radius: 4px;
				position: relative;
				font-size: 12px;
				font-weight: 500;
				border: solid 1px rgba(black, 0.1);
				cursor: pointer;
				.check {
					position: absolute;
					right: 10px;
					top: 50%;
					transform: translateY(-50%);
				}
				&:hover {
					background: $bleuclair;
				}
				&.checked {
					border-color: $vert;
					color: $vert;
					background: $vertclair;
					font-weight: bold;
					box-shadow: 2px 2px 4px rgba(black, 0.1);
				}
			}
		}
	}
}
#dashboardintroduction {
	background: white;
	padding: 30px;
	position: relative;
	overflow: hidden;
	.loading {
		padding: 40px;
		border-radius: 20px;
		background: $gris;
		img {
			max-width: 200px;
			height: auto;
		}
		div {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;
			font-weight: normal;
			font-size: 12px;
			line-height: 15px;
			i {
				color: $rouge;
				font-size: 20px;
				margin-bottom: 20px;
			}
		}
	}
	.tablezone {
		padding: 0px;
		border-radius: 20px;
		background: $gris;
		background-image: url("../../assets/img/fond-alveole.png");
		background-repeat: repeat;
		background-position: top left;
		padding: 20px 0;
		.dataTable-bottom {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			font-size: 11px;
			font-weight: 500;

			border-radius: 0 0 20px 20px;
			padding-bottom: 40px;

			.dataTable-info {
				padding: 0px 20px 15px 20px;
				opacity: 0.7;
				font-style: italic;
			}
			.dataTable-pagination {
				background: white;
				border-radius: 8px;
				box-shadow: 3px 3px 12px rgba(black, 0.05);
				ul {
					display: flex;
					margin: 0;
					padding: 0;
					list-style: none;
					li {
						border-left: solid 1px rgba(black, 0.05);
						transition: all 0.3s ease;
						a {
							color: black;
							padding: 12px 16px;
							display: block;
						}
						&.pager {
							font-size: 10px;
						}
						&.active {
							a {
								color: $rouge;
							}
						}
						&:hover {
							background: $gris;
						}
					}
				}
			}
		}
		.dataTable-top {
			display: flex;
			padding: 20px 40px;
			align-items: stretch;
			justify-content: space-between;
			font-size: 12px;
			font-weight: 500;
			select {
				padding: 10px;
				border: none;
				border-radius: 15px;
				background: white;
				box-shadow: 3px 3px 12px rgba(black, 0.05);
				margin: 0 6px;
				font-weight: 500;
				outline: none;
			}
			.dataTable-dropdown {
				border-radius: 8px;
				padding: 0 16px;
				display: flex;
				align-items: center;
			}
			.dataTable-search {
				border-radius: 8px;
				padding: 0;
				background: white;
				border-radius: solid 1px $gris2;
				border: solid 1px transparent;
				transition: all 0.3s ease;
				box-shadow: 3px 3px 12px rgba(black, 0.05);
				input {
					width: 100%;
					height: 43px;
					border: none;
					padding: 0 16px;
					border-radius: 15px;
					width: 280px;
					background: transparent;
					font-weight: 500;
					font-size: 13px;
				}
			}
		}
		table {
			width: 100%;
			background: white;
			border-radius: 8px;
			border: solid 1px $gris2;
			box-shadow: 3px 3px 12px rgba(black, 0.05);
			thead {
				tr {
					th {
						border-bottom: thin solid $gris2;
						height: 48px;
						padding: 0 16px;
						user-select: none;
						text-align: left;
						font-size: 11px;
						a {
							font-weight: 600;
							color: black !important;
						}
					}
				}
			}
			tbody {
				tr {
					padding: 0;
					margin: 0;
					border: none;
					td {
						border: none;
						border-bottom: thin solid $gris;
						height: 48px;
						font-size: 13px;
						padding: 0 16px;
						font-weight: 300;
						transition: all 0.3s ease;
						&.dataTables-empty {
							display: none;
						}

						&:nth-child(1) {
							font-weight: bold;
						}
						&:last-child {
							display: flex;
							gap: 5px;
							align-items: center;
							button {
								font-size: 11px;
								border-radius: 4px;
								background: white;
								color: black;
								padding: 10px;
								i,
								svg {
									font-size: 12px;
									margin: 0 3px 0 0;
								}
								&:hover {
									background: $rougeclair;
									color: $rouge;
								}
								&:first-child:hover {
									background: $vertclair;
									color: $vert;
								}
							}
						}
						&:nth-child(5) {
							span {
								background: $gris2;
								display: inline-block;
								padding: 6px 12px;
								font-weight: 500;
								font-size: 12px;
								border-radius: 20px;
								&.brouillon {
									background: #fdf4e0;
									color: $jaune;
								}
								&.pret {
									background: #f4f8ff;
									color: $bleu;
								}
								&.envoye {
									background: #e5f7ef;
									color: $vert;
								}
							}
						}
						.modifnotif {
							font-size: 11px;
							color: $rouge;
							background: rgba($rouge, 0.1);
							padding: 5px 10px;
							border-radius: 20px;
							margin-left: 5px;
							i {
								margin-right: 4px;
							}
						}
					}
					&:hover {
						td {
							background: $gris;
						}
					}
				}
			}
		}
		.dataTable-container {
			padding: 0 40px 10px;
			border-radius: 20px 20px 0 0;
		}
	}
	button.create {
		margin: 30px auto;
		font-size: 10px;
		letter-spacing: 1px;
		background: $bleu;
		text-transform: uppercase;
		border-radius: 4px;
		&:hover {
			box-shadow: 3px 3px 10px rgba($bleu, 0.6);
		}
	}

	.presentations {
		padding: 30px 0;
		border-radius: 20px;
		position: relative;
		.nb_presentations {
			position: absolute;
			top: 0;
			left: 0;
			padding: 6px 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $bleuclair;
			color: $bleu;
			font-weight: normal;
			font-size: 11px;
			letter-spacing: 0.4px;
			border-radius: 14px;
			transform: translateY(-50%);
		}
		.firstline {
			display: flex;
			flex-wrap: nowrap;
			font-size: 10px;
			letter-spacing: 0.3px;
			text-transform: uppercase;
			font-weight: bold;
			color: lighten(black, 60%);
			border-bottom: solid 3px $bleuclair;
			div {
				padding: 10px 20px;
				font-weight: 400;
				&:nth-child(1) {
					flex: 2.5;
				}
				&:nth-child(2) {
					flex: 1;
				}
				&:nth-child(3) {
					flex: 1;
				}
				&:nth-child(4) {
					flex: 1;
				}
				&:nth-child(5) {
					flex: 0 0 100px;
					padding-left: 0;
				}
				&:nth-child(6) {
					flex: 0 0 190px;
					text-align: right;
				}
			}
		}
		.presentation {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			background: white;
			border-radius: 5px;
			border-bottom: dashed 1px rgba(black, 0.1);
			transition: all 0.3s ease;
			&:hover {
				background: $bleuclair;
			}
			& > div {
				padding: 8px 20px;
				font-size: 11px;
				line-height: 11px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
			.nom {
				flex: 2.5;
				font-weight: 500;
				font-size: 13px;
				position: relative;
				display: flex;
				flex-direction: column-reverse;
				align-items: flex-start;
				color: black;
				a {
					color: black;
					&:hover {
						font-weight: 600;
					}
				}
				.modifnotif {
					color: $jaune;
					background: $jauneclair;
					font-size: 9px;
					padding: 4px;
					top: 23px;
					border: solid 1px rgba($jaune, 0.1);
					border-radius: 3px;
					margin-top: 5px;
				}
			}
			.auteur {
				flex: 1;
				font-weight: 300;
			}
			.membres {
				flex: 1;
			}
			.modif {
				flex: 1;
			}
			.statut {
				flex: 0 0 100px;
				background: $gris2;
				display: inline-block;
				padding: 6px 12px;
				font-weight: 500;
				font-size: 12px;
				border-radius: 5px;
				text-align: center;
				&.brouillon {
					background: #fdf4e0;
					color: $jaune;
				}
				&.pret {
					background: #f4f8ff;
					color: $bleu;
				}
				&.envoye {
					background: #e5f7ef;
					color: $vert;
				}
			}
			.actions {
				flex: 0 0 190px;
				display: flex;
				gap: 5px;
				align-items: center;
				justify-content: flex-end;
				button,
				a {
					font-size: 11px;
					border-radius: 4px;
					background: white;
					color: black;
					padding: 10px;
					display: block;
					background: $rougeclair;
					color: $rouge;
					i,
					svg {
						font-size: 12px;
						margin: 0 3px 0 0;
					}
					&:hover {
						background: $rouge;
						color: $rougeclair;
					}
					&:first-child {
						background: $vertclair;
						color: $vert;
						&:hover {
							background: $vert;
							color: $vertclair;
						}
					}
				}
			}
			&.archive {
				opacity: 0.2;
				filter: grayscale(100) blur(0.5px);
			}
		}
	}
}
</style>
