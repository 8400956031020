import Vue from "vue";
import VueRouter from "vue-router";
import Store from "./store";
import Dashboard from "./views/Dashboard.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Dashboard",
		component: Dashboard,
	},
	{
		path: "/connexion",
		name: "Connexion",
		component: () => import(/* webpackChunkName: "about" */ "./views/Connexion.vue"),
	},
	{
		path: "/tutoriels",
		name: "Tutoriels",
		component: () => import(/* webpackChunkName: "about" */ "./views/Tutoriels.vue"),
	},
	{
		path: "/presentation/:id",
		name: "Présentation",
		component: () => import(/* webpackChunkName: "about" */ "./views/Presentation.vue"),
	},
	{
		path: "/archives",
		name: "Archives",
		component: () => import(/* webpackChunkName: "about" */ "./views/Archives.vue"),
	},
	{
		path: "/utilisateurs",
		name: "Utilisateurs",
		component: () => import(/* webpackChunkName: "about" */ "./views/Utilisateurs.vue"),
	},
	{
		path: "/categories",
		name: "Catégories",
		component: () => import(/* webpackChunkName: "about" */ "./views/Categories.vue"),
	},
	{
		path: "/modeles",
		name: "Modèles",
		component: () => import(/* webpackChunkName: "about" */ "./views/Modeles.vue"),
	},
	{
		path: "/modele/:id",
		name: "Modèle",
		component: () => import(/* webpackChunkName: "about" */ "./views/Modele.vue"),
	},
	{
		path: "/squelettes",
		name: "Squelettes",
		component: () => import(/* webpackChunkName: "about" */ "./views/Squelettes.vue"),
	},
	{
		path: "/squelette/:id",
		name: "Squelette",
		component: () => import(/* webpackChunkName: "about" */ "./views/Squelette.vue"),
	},
	{
		path: "/globales",
		name: "Données globales",
		component: () => import(/* webpackChunkName: "about" */ "./views/globales.vue"),
	},
	{
		path: "/messagerie",
		name: "Messagerie",
		component: () => import(/* webpackChunkName: "about" */ "./views/Messagerie.vue"),
	},
	{
		path: "*",
		name: "Page introuvable",
		component: () => import(/* webpackChunkName: "about" */ "./views/404.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (!Store.state.connected) {
		if (to.name === "Connexion") {
			next();
		} else {
			next({ name: "Connexion" });
		}
	} else {
		if (to.name === "Utilisateurs") {
			// Accès autorisé au Super Admin seulement
			if (Store.state.user.fields.role != "super_administrateur") {
				next({ name: "Dashboard" });
			} else {
				next();
			}
		} else if (to.name === "Modèles" || to.name === "Catégories") /* || to.name === "Squelettes" || to.name === "Squelette") */ {
			// Accès autorisé au Super Admin et Administrateur seulement
			if (Store.state.user.fields.role === "super_administrateur" || Store.state.user.fields.role === "administrateur") {
				next();
			} else {
				next({ name: "Dashboard" });
			}
		} else if (to.name === "Connexion") {
			// Si connecté alors on va sur la dashboard plutot
			next({ name: "Dashboard" });
		} else {
			next();
		}
	}
});

export default router;
